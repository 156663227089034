import React, {useState} from "react";
import {useSelector} from "react-redux";
import {
  faClipboardList,
  faClipboardCheck,
  faStopwatch,
} from "@fortawesome/free-solid-svg-icons";
import DashboardWidget from "components/DashboardWidget";
import {getDashboardMetrics} from "services/statistics";
import IntroductionTrial from "components/IntroductionTrial";
import IntroductionBuy from "components/IntroductionBuy";
import {getValidLicences} from "services/customerService";
import OverageWarning from "components/OverageWarning";
import "./css/Dashboard.scss";
import {useCookies} from "react-cookie";
import {CRow} from "@coreui/react";
import PropTypes, {object} from "prop-types";

function Dashboard(props) {
  const [cookies, setCookie] = useCookies();
  const selectedCustomer = useSelector(state => state.customer.selectedCustomer);
  const selectedCustomers = useSelector(state => state.customer.selectedCustomers);

  if (cookies["show-trial-intro"] === undefined) {
    setCookie("show-trial-intro", true);
  }
  if (cookies["show-buy-intro"] === undefined) {
    setCookie("show-buy-intro", true);
  }

  const isCookieTrue = cookieName => cookies[cookieName] && cookies[cookieName] !== "false";

  const showBuyIntro = props.selfService === true && props.trial === false && isCookieTrue("show-buy-intro");
  const showTrialIntro = props.selfService === true && props.trial && isCookieTrue("show-trial-intro");

  const [loadingMetrics, setLoadingMetrics] = useState(false);
  const [metrics, setMetrics] = useState(null);
  const [loadedCustomer, setLoadedCustomer] = useState(null);
  const [validLicences, setValidLicences] = useState(null);

  if (selectedCustomer && selectedCustomer !== loadedCustomer && !loadingMetrics) {
    setValidLicences(getValidLicences(props.customer));
    setMetrics(null);
    setLoadingMetrics(true);
    getDashboardMetrics(selectedCustomers).then(({data}) => {
      setLoadedCustomer(selectedCustomer);
      setMetrics(data);
      setLoadingMetrics(false);
    });
  }

  function formatTime(seconds) {
    if (seconds === null) {
      return seconds;
    }
    let output = "";
    if (seconds > 3600) {
      output += Math.floor(seconds / 3600) + " hours, ";
    }
    if (seconds > 60) {
      output += Math.floor(seconds % 3600 / 60) + " minutes ";
    }
    return output + (seconds % 60) + " seconds";
  }

  const hideTrialIntro = () => {
    setCookie("show-trial-intro", false);
  };
  const hideBuyIntro = () => {
    setCookie("show-buy-intro", false);
  };

  return (
    <CRow className={loadingMetrics ? "dashboard-muted" : ""}>
      {validLicences ? <div><OverageWarning licences={validLicences}/></div> : null}
      {showBuyIntro || showTrialIntro ? <h1>Welcome to AdviceRobo</h1> : null}
      {showBuyIntro ? <div><IntroductionBuy close={hideBuyIntro}/></div> : null}
      {showTrialIntro ? <div><IntroductionTrial close={hideTrialIntro}/></div>
        : null
      }
      <DashboardWidget
        color="info" name="Sessions started today" value={metrics && metrics.sessionsStarted.today}
        icon={faClipboardList}/>
      <DashboardWidget
        color="info" name="Sessions started this month"
        value={metrics && metrics.sessionsStarted.thisMonth} icon={faClipboardList}/>
      <DashboardWidget
        color="info" name="Sessions started last month"
        value={metrics && metrics.sessionsStarted.lastMonth} icon={faClipboardList}/>
      <DashboardWidget
        color="success" name="Sessions completed today"
        value={metrics && metrics.sessionsCompleted.today} icon={faClipboardCheck}/>
      <DashboardWidget
        color="success" name="Sessions completed this month"
        value={metrics && metrics.sessionsCompleted.thisMonth} icon={faClipboardCheck}/>
      <DashboardWidget
        color="success" name="Sessions completed last month"
        value={metrics && metrics.sessionsCompleted.lastMonth} icon={faClipboardCheck}/>
      <DashboardWidget
        color="warning" name="Median duration today"
        value={formatTime(metrics && metrics.medianTime.today)}
        tooltipPrefix={"average duration: "}
        tooltip={formatTime(metrics && metrics.averageTime.today)}
        icon={faStopwatch}
      />
      <DashboardWidget
        color="warning" name="Median duration this month"
        value={formatTime(metrics && metrics.medianTime.thisMonth)}
        tooltipPrefix={"average duration: "}
        tooltip={formatTime(metrics && metrics.averageTime.thisMonth)}
        icon={faStopwatch}
      />
      <DashboardWidget
        color="warning" name="Median duration last month"
        value={formatTime(metrics && metrics.medianTime.lastMonth)}
        tooltipPrefix={"average duration: "}
        tooltip={formatTime(metrics && metrics.averageTime.lastMonth)}
        icon={faStopwatch}
      />
    </CRow>
  );
}

Dashboard.propTypes = {
  selfService: PropTypes.bool,
  trial: PropTypes.bool,
  customer: object,
};

export default Dashboard;
