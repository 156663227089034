import React, {useState} from "react";
import {CFormInput, CFormLabel} from "@coreui/react";
import {modifyKeyToLabel} from "services/general";
import PropTypes from "prop-types";

const NumberField = ({id, field, defaultValue, onFieldChanged}) => {
  const [value, setValue] = useState((defaultValue ? defaultValue : ""));
  const key = id + field;

  return (
    <>
      <CFormLabel htmlFor={key}>{modifyKeyToLabel(field)}</CFormLabel>
      <CFormInput
        type="text"
        name={field}
        value={value}
        onChange={(event) => {
          onFieldChanged(event.currentTarget.value, event.currentTarget.name);
          setValue(event.currentTarget.value);
        }}
        id={key}
      />
    </>
  );
};

NumberField.propTypes = {
  id: PropTypes.number,
  field: PropTypes.string,
  type: PropTypes.string,
  defaultValue: PropTypes.string,
  onFieldChanged: PropTypes.func,
};

export default NumberField;
