import axios from "axios";
import {API_SERVER} from "services/config";
import {getAuthorizationHeader, getJwtToken, setJwtToken} from "helpers/jwt";
import {redirectToLogin} from "services/general";

const $apiWithToken = axios.create({
  withCredentials: true,
  baseURL: API_SERVER,
  headers: {
    "Content-Type": "application/json"
  }
});

$apiWithToken.interceptors.request.use(async (config) => {
  if (getJwtToken().expires_at - 180 < Math.floor((new Date()).getTime() / 1000)) {
    try {
      const response = await axios.post(
        API_SERVER + "/api/token/refresh",
        {refresh_token: getJwtToken().refresh_token},
        {withCredentials: true}
      );
      setJwtToken(response.data);
    } catch (error) {
      redirectToLogin();
    }
  }
  config.headers = {...config.headers, ...getAuthorizationHeader()};

  return config;
});

$apiWithToken.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response.code === 401) {
      redirectToLogin();
    }
  }
);

const $api = axios.create({
  withCredentials: true,
  baseURL: API_SERVER,
  headers: {
    "Content-Type": "application/json"
  }
});

export {$api};
export default $apiWithToken;