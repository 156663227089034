import React, {useEffect, useState} from "react";
import {CCard, CCardBody} from "@coreui/react";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import "react-tabs/style/react-tabs.css";
import {isAdmin} from "services/userService";
import ApplicantService from "services/applicantService";
import PropTypes from "prop-types";
import {useSelector} from "react-redux";
import ApplicantScoreExpandedOB from "./Expanded/ApplicantScoreExpandedOB";
import ApplicantScoreExpandedPcsScore from "./Expanded/ApplicantScoreExpandedPcsScore";
import ApplicantScoreExpandedTime from "./Expanded/ApplicantScoreExpandedTime";
import ApplicantScoreExpandedDeviceMeta from "./Expanded/ApplicantScoreExpandedDeviceMeta";
import ApplicantScoreExpandedEmailRep from "./Expanded/ApplicantScoreExpandedEmailRep";
import ApplicantScoreExpandedEmailSoc from "./Expanded/ApplicantScoreExpandedEmailSoc";

const ApplicantScoreExpanded = ({data, info}) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [platformData, setPlatformData] = useState([]);
  const [emailReputation, setEmailReputation] = useState([]);
  const [emailSocial, setEmailSocial] = useState([]);
  const [openBanking, setOpenBanking] = useState([]);
  const [answerTimeData, setAnswerTimeData] = useState([]);

  const filter = useSelector(state => state.filter.filters);

  const handleTabSelect = (index) => {
    setActiveTabIndex(index);
  };

  useEffect(() => {
    ApplicantService.loadPlatformData(data.applicantExternalId, filter.customers).then((response) => {
      if ("device_meta_data" in response.data) {
        setPlatformData(response.data.device_meta_data);
      }

      if ("email_reputation" in response.data) {
        setEmailReputation(response.data.email_reputation);
      }

      if ("email_social" in response.data) {
        setEmailSocial(response.data.email_social);
      }
    });
    ApplicantService.loadAnswerTimeData(data.applicantExternalId).then(({data}) => {
      setAnswerTimeData(data);
    });

    if (isAdmin()) {
      ApplicantService.getOpenBankingByApplicant(data.applicantId).then(({data}) => {
        setOpenBanking(data);
      });
    }
  }, []);

  return (
    <CCard className="mb-3" style={{
      borderRadius: "0",
      borderBottom: "2px solid #20A8D8",
      borderLeft: "2px solid #20A8D8",
      borderRight: "2px solid #20A8D8",
    }}>
      <Tabs selectedIndex={activeTabIndex} onSelect={handleTabSelect}>
        <CCardBody>
          <TabList>
            <Tab>PCS Score</Tab>
            <Tab disabled={platformData.length === 0}>Digital Footprint</Tab>
            <Tab disabled={emailReputation.length === 0}>Email Reputation</Tab>
            {isAdmin() ? <Tab disabled={emailSocial.length === 0}>Email Social</Tab> : ""}
            {isAdmin() ? <Tab disabled={answerTimeData.length === 0}>Answers</Tab> : ""}
            {isAdmin() ? <Tab disabled={openBanking.length === 0}>Open banking</Tab> : ""}
          </TabList>
          <TabPanel>
            <ApplicantScoreExpandedPcsScore info={info} data={data}/>
          </TabPanel>
          <TabPanel>
            <ApplicantScoreExpandedDeviceMeta items={platformData}/>
          </TabPanel>
          <TabPanel>
            <ApplicantScoreExpandedEmailRep items={emailReputation}/>
          </TabPanel>
          {isAdmin() ? <TabPanel> <ApplicantScoreExpandedEmailSoc items={emailSocial}/> </TabPanel> : ""}
          {isAdmin() ?
            <TabPanel>
              <ApplicantScoreExpandedTime items={answerTimeData}/>
            </TabPanel> : ""
          }
          {isAdmin() ? <TabPanel><ApplicantScoreExpandedOB openBankingData={openBanking}/></TabPanel> : ""}
        </CCardBody>
      </Tabs>
    </CCard>
  );
};

ApplicantScoreExpanded.propTypes = {
  info: PropTypes.shape({
    dimensions: PropTypes.array,
  }),
  data: PropTypes.shape({
    applicantExternalId: PropTypes.string,
    score: PropTypes.number,
    applicantId: PropTypes.number,
    success: PropTypes.bool,
  }),
};

export default ApplicantScoreExpanded;
