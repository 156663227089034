import React, {useState} from "react";
import PropTypes from "prop-types";
import {getCurrentCustomer} from "services/customerService";
import {connect} from "react-redux";
import {getOrderedColors} from "services/colors";
import {saveColor} from "services/customizationService";
import Spinner from "components/Spinner";
import IframeComm from "components/IframeComm";
import ColorPickerRow from "components/ColorPickerRow";
import PleasePurchase from "components/PleasePurchase";
import {CCard, CCardBody, CCardHeader, CCol, CRow} from "@coreui/react";

function QuestionnaireColors(props) {
  const colors = getOrderedColors(
    props.customer !== undefined && props.customer.colors !== undefined ? props.customer.colors : []);

  // eslint-disable-next-line no-unused-vars
  const [iFrameRefs] = useState(Object.keys(colors).reduce((refs, group) => {
    refs[group] = React.createRef();
    return refs;
  }, {}));

  /** If the customer is not loaded yet, leave. */
  if (!props.customer) {
    return <Spinner/>;
  }

  if (props.customer.trial) {
    return <PleasePurchase/>;
  }

  const handleChange = async (category, value) => {
    let color = findColor(category);
    if (color.value !== value) { // something has changed
      color = (await saveColor(props.customer.id, {...color, value: value})).data;
      replaceColor(color);
      // Check if the colour should be mapped to other colour categories.
      if (categoryMapping[color.category]) {
        for (const otherCategory of categoryMapping[color.category]) {
          let otherColor = findColor(otherCategory);
          if (otherColor) {
            otherColor.value = value;
            otherColor = (await saveColor(props.customer.id, otherColor)).data;
            replaceColor(otherColor);
          }
        }
      }

      Object.keys(iFrameRefs).forEach((group) => {
        iFrameRefs[group].current.contentWindow.postMessage(
          JSON.stringify({colors: flattenColors(colors)}),
          "*",
        );
      });
    }
  };

  const findColor = (category) => {
    let flatColors = {};
    Object.keys(colors).map(
      (group) => colors[group].map((color) => flatColors[color.category] = color),
    );
    return flatColors[category] || null;
  };

  const replaceColor = (newColor) => {
    Object.entries(colors).forEach(([group, groupColors]) => {
      groupColors.forEach((color, index) => {
        if (color.category === newColor.category) {
          colors[group][index] = newColor;
          return false;
        }
        return true;
      });
    });
  };

  const flattenColors = (groupedColors) => {
    let flatColors = {};
    Object.keys(colors).map(
      (group) => groupedColors[group].map((color) => flatColors[color.category] = color.value),
    );
    return flatColors;
  };

  return <CCard className="mb-3">
    <CCardHeader>
      <h2>Customise Colors</h2>
      <p>This page allows you to change the colors of the questionnaire as displayed to your customers.</p>
    </CCardHeader>
    <CCardBody>
      {Object.keys(colors).map((group) => {
        return <CCard key={props.selectedCustomer + group} className="mb-3">
          <CCardHeader>{groupLabels[group] || group}</CCardHeader>
          <CCardBody>
            <CRow>
              <CCol md="6">
                {colors[group].map((color) => {
                  return labels[color.category] ?
                    <ColorPickerRow
                      key={color.category}
                      color={color}
                      label={labels[color.category]}
                      handleChange={handleChange}/>
                    :
                    null;
                })}
              </CCol>
              <CCol md="6">
                <IframeComm
                  attributes={{
                    id: "iframe_" + group,
                    src: (process.env.REACT_APP_JACQ_URL || "https://jacq.advicerobo.com") +
                      "/?preview=true#/preview/" + group + "",
                    width: "100%",
                    height: "600",
                    title: group,
                  }}
                  ref={iFrameRefs[group]}
                  postMessageData={{colors: flattenColors(colors)}}
                />
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>;
      })}
    </CCardBody>
  </CCard>;
}

QuestionnaireColors.propTypes = {
  selectedCustomer: PropTypes.number,
  customer: PropTypes.object,
};

const groupLabels = {
  "page": "The introduction and the final page",
  "question": "The question pages",
  "load": "The \"loading...\" screen",
};

const labels = {
  pageBg: "Background",
  pageText: "Text in the slides",
  pageBtnBg: "Button background",
  pageBtnText: "Button text",
  // loadBg: "Load screen background",
  // loadText: "Load screen text",
  questionBg: "Page background",
  questionHeadBg: "Header background",
  questionHeadText: "Header text",
  questionAnswerBg: "Answer button background",
  questionAnswerActive: "Selected answer background",
  questionAnswerText: "Answer button text",
  questionAnswerActiveText: "Selected answer text",
  questionAnswerHelper: "Answer button icons and details",
  questionBtnBg: "Next question button background",
  questionBtnText: "Next question button text",
  progress: "Progress bar background",
};

const categoryMapping = {
  "questionHeadBg": ["loadBg"],
  "questionHeadText": ["loadText"],
};

const mapStateToProps = state => {
  return {
    selectedCustomer: state.customer.selectedCustomer,
    customer: getCurrentCustomer(state.customer),
  };
};

export default connect(mapStateToProps)(QuestionnaireColors);
