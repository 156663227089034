import React, {useEffect} from "react";
import {Button, Col, Row} from "reactstrap";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {faCheck, faEnvelope, faTable, faTimes, faVial} from "@fortawesome/free-solid-svg-icons";
import {useDispatch, useSelector} from "react-redux";
import IntroductionCard from "./IntroductionCard";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "./Introduction.scss";
import {getOfferProduct} from "services/productService";
import {setOfferProduct} from "store/productSlice";

const IntroductionTrial = (props) => {
  const dispatch = useDispatch();
  const offerProduct = useSelector(state => state.product.offerProduct);

  useEffect(() => {
    if (!offerProduct) {
      getOfferProduct().then(products => {
        if (products.product) {
          dispatch(setOfferProduct(products.product.id));
        }
      });
    }
  }, [props]);

  const close = event => {
    event.stopPropagation();
    if (props.close) {
      props.close();
    }
  };
  const orderUrl = offerProduct ? `/subscribe/${offerProduct}` : "/choose-subscription";

  return <div className="introduction">
    <Row>
      <Col><p>&nbsp;</p></Col>
      <Col xs="1" sm="1" lg="1">
        <Button outline size="sm" title="Hide introduction steps" onClick={close} >
          <FontAwesomeIcon icon={faTimes}/>
        </Button>
      </Col>
    </Row>
    <Row className="introduction-trial">
      <IntroductionCard icon={faVial} caption="Try" color="warning" target="/create-session" title="Try it">
        You can try MyCreditRobo yourself, right here in the browser
      </IntroductionCard>
      <IntroductionCard icon={faEnvelope} caption="Invite" color="success" target="/create-session/send" title="Send it">
        Invite colleagues and/or friends to the CreditRobo questionnaire with an email.
        Do not forget to invite yourself as well.
      </IntroductionCard>
      <IntroductionCard icon={faTable} caption="The scores" color="dark" target="/data/scores" title="Discover it">
        On the 24/7 dashboard you can see the applicants go through the <Link to="/graph/funnel">funnel</Link> as
        they fill in the questionnaire. Examine the <Link to="/score-distribution">distribution</Link>.
        See and/or download the actual resulting <Link to="/data/scores">scores</Link>
      </IntroductionCard>
      <IntroductionCard icon={faCheck} caption="Subscribe" color="info" target={orderUrl} title="Use it">
        When you are convinced, subscribe so that you can customise the look and feel.
        And integrate our solution in to your process.
      </IntroductionCard>
    </Row>
    <hr/>
  </div>;
};

IntroductionTrial.propTypes = {
  close: PropTypes.func,
};

export default IntroductionTrial;
