import React, {useEffect, useState} from "react";
import "react-tabs/style/react-tabs.css";
import PropTypes from "prop-types";
import {CTable} from "@coreui/react";

const ApplicantScoreExpandedDeviceMeta = ({items}) => {
  const [deviceMeta, setDeviceMeta] = useState([]);

  useEffect(() => {
    if (Object.keys(items).length !== 0) {
      setDeviceMeta([
        {label: "Browser name", info: items.browserName},
        {label: "Browser version", info: items.browserVersion},
        {label: "Model", info: items.deviceModel},
        {label: "Manufacturer", info: items.deviceManufacturer},
        {label: "OS", info: items.osName},
        {label: "OS Version", info: items.osVersion},
        {label: "Mobile", info: items.deviceMobile ? "Yes" : "No"},
        {label: "Desktop", info: items.deviceDesktop ? "Yes" : "No"},
        {label: "Tablet", info: items.deviceTablet ? "Yes" : "No"},
        {label: "Postal code", info: items.locationAddressZip},
        {label: "City", info: items.locationAddressCity},
        {label: "Region", info: items.locationRegionName},
        {label: "Country", info: items.locationCountryName},
      ]);
    }
  }, [items]);

  const getPlatformDataColumns = () => {
    return [
      {key: "label", label: "Label"},
      {key: "info", label: "Info"},
    ];
  };

  return (
    <CTable
      items={deviceMeta}
      columns={getPlatformDataColumns()}
      hover
      striped
      bordered
    />
  );
};

ApplicantScoreExpandedDeviceMeta.propTypes = {
  items: PropTypes.object,
};

export default ApplicantScoreExpandedDeviceMeta;
