import React, {useState} from "react";
import {CBadge} from "@coreui/react";
import {Tooltip} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faThumbsDown, faThumbsUp} from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";

const ScoreTooltipItem = ({id, isPositive, style, score}) => {
  if (score > 1) {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);

    return (
      <>
        <CBadge
          id={"alertTooltip-score-badge-" + id}
          style={style}
        >
          {score}
        </CBadge>
        <Tooltip
          placement="left"
          target={"alertTooltip-score-badge-" + id}
          isOpen={tooltipOpen}
          toggle={toggle}
        >
          <h5 className="mt-2">
            <FontAwesomeIcon icon={isPositive ? faThumbsUp : faThumbsDown} color={isPositive ? "green" : "red"}/>
            {isPositive ? " Positive score prediction! " : " Negative score prediction! "}
          </h5>
          <p>
            Users can input a threshold value, which serves as the reference point for highlighting predictions. <br/>
            <span style={{color: "red"}}>
                It can be changed in settings.
            </span>
          </p>
        </Tooltip>
      </>
    );
  }

  return score;
};

ScoreTooltipItem.propTypes = {
  id: PropTypes.number,
  isPositive: PropTypes.bool,
  style: PropTypes.object,
  score: PropTypes.number,
};

export default ScoreTooltipItem;