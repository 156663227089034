import React from "react";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChartPie, faSpinner} from "@fortawesome/free-solid-svg-icons";
import {CCol, CWidgetStatsF} from "@coreui/react";
import {UncontrolledTooltip} from "reactstrap";

export default function DashboardWidget(props) {
  const id = "dashboardWidget" + props.name.toLowerCase().replace(/\s/g, "");

  return (
    <CCol xs={12} sm={6} lg={4}>
      <CWidgetStatsF
        id={id}
        className="mb-3"
        icon={<FontAwesomeIcon fixedWidth={true} icon={props.icon || faChartPie} size="2x"/>}
        padding={false}
        title={props.name}
        value={props.value !== null ? props.value : <FontAwesomeIcon spin={true} icon={faSpinner}/>}
        color={props.color || "info"}
      />
      {props.tooltip ?
        <UncontrolledTooltip target={id} placement="bottom">
          <div className="text-muted text-uppercase">{props.tooltipPrefix}</div>{props.tooltip}
        </UncontrolledTooltip>
        : null}
    </CCol>
  );
}

DashboardWidget.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  tooltipPrefix: PropTypes.string,
  tooltip: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  icon: PropTypes.object,
  color: PropTypes.string,
};