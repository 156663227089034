import React, {useState} from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {Alert, Button} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {UserService} from "services/userService";
import {CFormInput, CFormText, CInputGroup, CInputGroupText} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import {cilUser} from "@coreui/icons";

/**
 * @param props
 * @returns {*}
 * @constructor
 */
const RequestPasswordResetForm = (props) => {
  const IS_CLEAN = "clean";
  const IS_PENDING = "pending";
  const IS_ERROR = "error";
  const IS_COMPLETED = "completed";
  const [username, setUsername] = useState(props.username);
  const [step, setStep] = useState(IS_CLEAN);
  const [alert, setAlert] = useState("");
  let formRef = React.createRef();

  const handleChange = (e) => {
    setUsername(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formRef.current.reportValidity()) {
      setStep(IS_PENDING);
      setAlert("");

      UserService.requestPasswordReset(username, onSuccess, onFail);
    } else {
      setStep(IS_ERROR);
    }
  };

  const onSuccess = () => {
    setStep(IS_COMPLETED);
  };

  const onFail = (errorMessage) => {
    setStep(IS_ERROR);
    setAlert(errorMessage.response.data.message);
  };

  return (
    <form name="form" onSubmit={handleSubmit} ref={formRef}>
      <h1>Forgot your password</h1>
      {step === IS_COMPLETED ?
        <div>
          <p className="text-muted">We received your request and if there is an account with this email,
                you should receive a email message shortly.<br/></p>
        </div>
        :
        <div>
          <p className="text-muted">You forgot or password or you would like to change it.<br/>
                Enter your email address below and click send. We will email you a link to reset your password.</p>

          <Alert color="danger" isOpen={step === IS_ERROR} toggle={() => {
            setStep(IS_CLEAN);
          }}>
            <strong>Error:</strong> {alert}
          </Alert>

          <CInputGroup className="mb-3">
            <CInputGroupText>
              <CIcon icon={cilUser} />
            </CInputGroupText>
            <CFormInput
              placeholder="Email address" type="email" autoComplete="email" required={true}
              className="form-control" name="username" value={username} onChange={handleChange}
            />
            {step === IS_ERROR && !username &&
              <CFormText>Username is required</CFormText>
            }
          </CInputGroup>

          <div className="row">
            <div className="col-6">
              <Button color="info" className="px-4" disabled={step === IS_PENDING}
                tooltip={"Login instead"}>
                {step === IS_PENDING ?
                  <FontAwesomeIcon fixedWidth={true} icon={faSpinner} spin={true} className="ml-2 mr-2"/> :
                  <span>Send</span>
                }
              </Button>

            </div>
            <div className="text-right col-6 pt-2">
              <Link to="/login">Login</Link>
            </div>
          </div>
        </div>
      }
    </form>
  );
};

RequestPasswordResetForm.propTypes = {
  username: PropTypes.string,
};

export default RequestPasswordResetForm;