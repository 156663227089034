import {API_SERVER} from "./config";
import $apiWithToken from "http";

export async function getToken(applicant, customer) {
  const urlObject = new URL(API_SERVER + "/api/v1/preview/token");

  urlObject.searchParams.append("customer", customer);
  urlObject.searchParams.append("applicant", applicant);

  return $apiWithToken.get(urlObject.toString()).catch((error) => {
    return {error: true, message: error};
  });
}

export async function sendInvite(applicant, customer, email, message) {
  const urlObject = new URL(API_SERVER + "/api/v1/preview/invite");
  urlObject.searchParams.append("customer", customer);

  return $apiWithToken.post(
    urlObject.toString(),
    {
      applicant: applicant,
      email: email,
      message: message,
    }
  ).catch((error) => {
    return {error: true, message: error};
  });
}

export async function uploadOpenBanking(file, applicant) {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("applicant", applicant);
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };

  return $apiWithToken.post(
    "/api/v1/open-banking/proceed",
    formData,
    config
  ).catch((error) => {
    return {error: true, message: error};
  });
}