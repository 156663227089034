import {createSlice} from "@reduxjs/toolkit";

const contentSlice = createSlice({
  name: "content",
  initialState: {
    sidebarUnfoldable: false,
    sidebarShow: true,
  },
  reducers: {
    setUnfoldable(state, action) {
      state.sidebarUnfoldable = action.payload;
    },
    setSidebarShow(state, action) {
      state.sidebarShow = action.payload;
    }
  }
});

export const {setUnfoldable, setSidebarShow} = contentSlice.actions;

export default contentSlice.reducer;
