import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import {getSessionCount} from "services/customerService";
import {
  Alert,
} from "reactstrap";
import {useSelector} from "react-redux";

/**
 * Displays the number of sessions left with in the customers license
 * @constructor
 */
function OverageWarning(props) {
  const selectedCustomers = useSelector(state => state.customer.selectedCustomers);
  const selectedCustomer = useSelector(state => state.customer.selectedCustomer);
  const [sessionLimit, setSessionLimit] = useState(null);
  const [usedSession, setUsedSession] = useState(null);
  const [color, setColor] = useState("black");

  useEffect(() => {
    (async function fetchCount() {
      const result = await getSessionCount(selectedCustomer, selectedCustomers);
      setSessionLimit(result.sessionLimit);
      setUsedSession(result.usedSession);
      if (result.sessionLimit > 0 && result.usedSession >= result.sessionLimit) {
        setColor("red");
      } else if (result.sessionLimit > 0 && result.usedSession >= (result.sessionLimit * 0.8)) {
        setColor("orange");
      }
    })();
  }, []);

  if (props.licences.length === 0) {
    return null;
  }

  return (sessionLimit > 0 && usedSession >= sessionLimit) ?
    <Alert color="danger">
      You have created <strong style={{color: color}}>{usedSession}</strong> session of
      <strong style={{color: "black"}}>{sessionLimit}</strong> questionnaire sessions with your license.</Alert>
    : null;
}

OverageWarning.propTypes = {
  licences: PropTypes.array.isRequired,
};

export default (OverageWarning);