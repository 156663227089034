import React, {useEffect, useState} from "react";
import "react-tabs/style/react-tabs.css";
import PropTypes from "prop-types";
import {CTable} from "@coreui/react";
import {prepareItemsForTable} from "../../../services/general";

const ApplicantScoreExpandedEmailRep = ({items}) => {
  const [reputation, setReputation] = useState([]);

  useEffect(() => {
    if (Object.keys(items).length !== 0) {
      setReputation(prepareItemsForTable(items));
    }
  }, [items]);

  const getEmailReputationDataColumns = () => {
    return [
      {key: "label", label: "Label"},
      {key: "info", label: "Info"},
    ];
  };

  return (
    <CTable
      responsive
      items={reputation}
      columns={getEmailReputationDataColumns()}
      hover
      striped
      bordered
    />
  );
};

ApplicantScoreExpandedEmailRep.propTypes = {
  items: PropTypes.object,
};

export default ApplicantScoreExpandedEmailRep;
