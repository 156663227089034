import React, {useState} from "react";
import {Button, FormText, Input, InputGroup, InputGroupText, Progress} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLock, faSpinner} from "@fortawesome/free-solid-svg-icons";
import {passwordChange} from "services/userService";
import PasswordMask from "react-password-mask";
import * as zxcvbn from "zxcvbn";
import {CAlert} from "@coreui/react";

function PasswordChangeForm() {
  const IS_CLEAN = "clean";
  const IS_PENDING = "pending";
  const IS_ERROR = "error";
  const IS_COMPLETED = "completed";
  const [step, setStep] = useState(IS_CLEAN);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [alert, setAlert] = useState("");
  const [score, setScore] = useState(0);
  const strengths = ["Very Weak", "Weak", "Good", "Strong", "Very Strong"];
  const colors = ["danger", "danger", "warning", "success", "success"];
  const progress = [25, 25, 50, 75, 100];

  let formRef = React.createRef();

  const handleChangeCurrent = (e) => {
    setCurrentPassword(e.target.value);
  };

  const handleChangeNew = (e) => {
    let password = e.target.value;
    let minLength = e.target.minLength;
    const result = zxcvbn(password);
    setScore(result.score);
    setAlert("");
    setStep(IS_CLEAN);
    if (password.length < minLength) {
      setAlert("A password length cannot be smaller than " + minLength.toString());
      setStep(IS_ERROR);
    } else if (result.score < 2) {
      setAlert(result.feedback.warning || "The password strength is weak");
      setStep(IS_ERROR);
    }
    setNewPassword(password);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formRef.current.reportValidity()) {
      setStep(IS_PENDING);
      setAlert("");
      passwordChange(currentPassword, newPassword).then(
        (data) => {
          console.log(data);
          setStep(IS_COMPLETED);
        },
      ).catch((error) => {
        setStep(IS_ERROR);
        setAlert(error.response.data.message);
      });
    } else {
      setStep(IS_ERROR);
    }
  };

  return (
    <form name="form" onSubmit={handleSubmit} ref={formRef}>
      <h2>A new password</h2>
      {step === IS_COMPLETED ?
        <div><p>Your password has been changed. <br/>Please use your new password to login.</p>
          <Button variant="info" href="/login" color="info" className="px-4">Login</Button>
        </div>
        :
        <div>
          <p className="text-muted">You would like to change your password<br/>
            Enter your current and new password in the form below </p>

          <CAlert dismissible visible={step === IS_ERROR} color="danger">
            <strong>Error:</strong> {alert}
          </CAlert>

          <InputGroup className="mb-4">
            <InputGroupText>
              <FontAwesomeIcon icon={faLock}/>
            </InputGroupText>
            <Input
              placeholder="Current Password" type="password" autoComplete="current-password"
              minLength="8" maxLength="4096"
              className="form-control" name="currentPassword"
              value={currentPassword} onChange={handleChangeCurrent}/>
            {step === IS_PENDING && !currentPassword &&
              <FormText>Password is required</FormText>
            }
          </InputGroup>
          <InputGroup className="mb-2">
            <InputGroupText>
              <FontAwesomeIcon icon={faLock}/>
            </InputGroupText>
            <PasswordMask
              placeholder="New Password" autoComplete="new-password"
              minLength={8} maxLength={4096}
              className="form-control" name="password"
              inputStyles={{border: "none"}}
              buttonStyles={{marginTop: "-14px", right: "2px"}}
              value={newPassword} onChange={handleChangeNew}/>
          </InputGroup>
          <Progress
            className="mb-2" style={{height: "25px"}} color={colors[score]}
            value={newPassword.length >= 8 ? progress[score] : 0}>
            {newPassword.length >= 8 ? strengths[score] : ""}
          </Progress>
          {step === IS_PENDING && !newPassword &&
            <FormText>Password is required</FormText>
          }

          <div className="row">
            <div className="col-6">
              <Button
                color="info" className="px-4" disabled={newPassword === "" || alert !== "" || step === IS_PENDING}
                tooltip={"Login instead"}>
                {step === IS_PENDING ?
                  <FontAwesomeIcon fixedWidth={true} icon={faSpinner} spin={true} className="ml-2 mr-2"/> :
                  <span>Set password</span>
                }
              </Button>
            </div>
          </div>
        </div>
      }
    </form>
  );
}

PasswordChangeForm.propTypes = {};

export default PasswordChangeForm;