import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {getAvailableLanguages, signUp} from "services/userService";
import validator from "validator";
import ReCAPTCHA from "react-google-recaptcha";
import {GOOGLE_RECAPTCHA_KEY} from "services/config";

import {
  Form,
  FormFeedback,
  FormGroup,
  FormText,
  Label,
  Input,
  Button,
  Alert,
} from "reactstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {sendEvent} from "helpers/tracking";

function StepOne(props) {

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [companyName, setCompanyName] = useState("");
  // const [website, setWebsite] = useState("");
  const [website] = useState("");
  const [email, setEmail] = useState("");
  const [language, setLanguage] = useState("en");
  const [reCaptcha, setReCaptcha] = useState(false);
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const [signUpComplete, setSignUpComplete] = useState(false);
  const [availableLanguages, setAvailableLanguages] = useState({});
  const [languagesLoaded, setLanguagesLoaded] = useState(false);
  const emailRegex = /@((hotmail)|(yahoo)|(gmail))\./i;

  useEffect(() => {
    if (!languagesLoaded) {
      setLanguagesLoaded(true);
      getAvailableLanguages().then(({data}) => {
        setAvailableLanguages(data);
      });
    }
  },[languagesLoaded, setAvailableLanguages]);

  const isValid = (elementName) => {
    switch (elementName) {
    case "name":
      return validator.isLength(name, {min: 3, max: 255}) && validator.isAlpha(name.replace(/\W/gi, ""));
    case "email":
      return validator.isEmail(email) && !emailRegex.test(email);
    case "website":
      return validator.isURL(website);
    case "phone":
      return (validator.isMobilePhone(phone, "any") || validator.isNumeric(phone)) && phone.length > 10;
    case "companyName":
      return validator.isLength(companyName, {min: 2, max: 255}) && validator.isAlphanumeric(companyName.replace(/\W/gi, ""));
    case "agreeTerms":
      return !!agreeTerms;
    case "reCaptcha":
      return !!reCaptcha;
    default:
      return true;
    }
  };

  const validateIfSet = (value, elementName) => {
    if (value && value !== false) {
      return setError(Object.assign({}, error, {[elementName]: !isValid(elementName)}));
    }
  };

  function handleSubmit(e) {
    e.preventDefault();
    // Validate the form.
    setError({});
    let valid = true;
    const formErrors = ["name", "email", "phone", "companyName", "reCaptcha", "agreeTerms"].reduce((errors, elementName) => {
      errors[elementName] = !isValid(elementName);
      valid = valid && !errors[elementName];
      return errors;
    }, {});
    if (!valid) {
      setError(formErrors);
      sendEvent("sign-up", "step-1-submit-invalid", "myCreditRobo");
      return false;
    }

    sendEvent("sign-up", "step-1-submit-valid", "myCreditRobo");
    // Validation passed.
    setLoading(true);
    signUp(companyName, email, language, name, phone, website).then(() => {
      sendEvent("sign-up", "step-1-post-success", "myCreditRobo");
      setSignUpComplete(true);
    }).catch(errorMessage => {
      console.log(errorMessage);
      setError({message: "A technical error occurred: " + errorMessage.response.data.message});
      sendEvent("sign-up", "step-1-post-error", "myCreditRobo");
      setLoading(false);
    });
  }
  // Show confirmation if completed:
  if (signUpComplete) {
    if (props.setStep) {
      props.setStep();
    }

    return (
      <div>
        <h1>Thank you for signing up!</h1>
        <p>You will receive an email message shortly on the address you filled in.</p>
        <p>Please confirm your email address to access MyCreditRobo by clicking the link in this message.</p>
      </div>
    );
  }
  // Show the form:
  return (
    <div>
      <h1>Sign up for a MyCreditRobo account</h1>
      <p>You can choose a free trial or a subscription</p>
      <Form onSubmit={handleSubmit}>
        <Alert color="danger" isOpen={error.message != null} toggle={() => setError({})}>
          {error.message ? error.message : ""}
        </Alert>
        <FormGroup>
          <Label for="inputName">Your name</Label>
          <Input type="text" id="inputName"
            invalid={error.name}
            valid={error.name === false}
            name="name" value={name}
            onChange={e => setName(e.target.value)} onBlur={e => validateIfSet(name, e.target.name)}
          />
          <FormFeedback>Please enter a name with a length between 3 and 255.</FormFeedback>
          <FormFeedback>Please enter characters only</FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label for="inputCompanyName">Company name</Label>
          <Input type="text" id="inputCompanyName"
            invalid={error.companyName}
            valid={error.companyName === false}
            name="companyName"
            value={companyName} onChange={e => setCompanyName(e.target.value)}
            onBlur={e => validateIfSet(companyName, e.target.name)}/>
          <FormFeedback>Please enter your company name with a length between 2 and 255 characters.</FormFeedback>
          <FormFeedback>Please enter alphanumeric only</FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label for="inputEmail">Business email address</Label>
          <Input type="email" id="inputEmail"
            invalid={error.email}
            valid={error.email === false}
            name="email" value={email}
            onChange={e => setEmail(e.target.value)} onBlur={e => validateIfSet(email, e.target.name)}/>
          <FormFeedback>Please enter a valid corporate email address.</FormFeedback>
          <FormText>Please do not use a gmail, yahoo or hotmail address.</FormText>
        </FormGroup>
        <FormGroup>
          <Label for="inputPhone">Phone</Label>
          <PhoneInput type="tel" enableSearchField={true} id="inputPhone" name="phone" required="required"
            value={phone} onChange={value => {setPhone(value.replace(/[^0-9+]/g, ""));}}
            isValid={() => phone === "" || isValid("phone") === true}
          />
          {phone !== "" && !isValid("phone") ? <FormText><Label style={{color:"#f86c6b"}}>Please enter a valid phone number</Label></FormText> : ""}
        </FormGroup>
        <FormGroup>
          <Label for="inputLanguage">Preferred language</Label>
          <Input type="select" invalid={error.language} valid={error.language === false} name="language" id="inputLanguage"
            value={language} onChange={e => setLanguage(e.target.value)} onBlur={e => validateIfSet(language, e.target.name)}>
            {Object.entries(availableLanguages).map(([languageCode, languageName]) => {
              return <option value={languageCode} key={languageCode}>{languageName}</option>;
            })}
          </Input>
          <FormText className="form-text text-muted">This questionnaire will be served to your customers in this
            language.</FormText>
        </FormGroup>
        <FormGroup>
          <ReCAPTCHA
            sitekey={GOOGLE_RECAPTCHA_KEY}
            onChange={() => setReCaptcha(true)}
            onExpired={() => setReCaptcha(false)}
            onErrored={() => setReCaptcha(false)}
          />
          {error.reCaptcha && !isValid("reCaptcha") ? <FormText><Label style={{color:"#f86c6b"}}>Please check the reCaptcha</Label></FormText> : ""}
        </FormGroup>
        <FormGroup check>
          <Label check>
            <Input
              type="checkbox" name="agreeTerms" invalid={error.agreeTerms} required
              onChange={e => setAgreeTerms(e.target.checked)} onBlur={e => validateIfSet(agreeTerms, e.target.name)}/>
              I have read and agree to the&nbsp;
            <a href="https://advicerobo.com/wp-content/uploads/2020/04/2020-Terms_Conditions-MyCreditRobo-by-AdviceRobo.pdf" target="terms-and-conditions">
              Terms and Conditions
            </a> and the&nbsp;
            < a href="https://advicerobo.com/privacy-security/" target="privacy">
            Privacy statement
            </a>. I consent that the information I provide can be used by AdviceRobo to contact me.
          </Label>
        </FormGroup>
        <Button className="mt-3" color="info" disabled={loading}>
          {loading ?
            <FontAwesomeIcon fixedWidth={true} icon={faSpinner} spin={true} className="ml-2 mr-2"/> :
            <span>Sign up</span>
          }
        </Button>
      </Form>
    </div>
  );
}

StepOne.propTypes = {
  setStep: PropTypes.func,
};

export default StepOne;
