import React from "react";
import {ButtonToolbar} from "reactstrap";
import PropTypes from "prop-types";
import FilterButton from "./FilterButton";

export default function DataFilterSelector(props) {
  function getAvailableOptions() {
    let options = [null];
    for (let i in props.licences) {
      let dependentOption = props.licences[i][props.dependentType];
      if (props.selectedDependentValue && props.selectedDependentValue !== dependentOption) {
        continue;
      }
      let option = props.licences[i][props.selectorType];
      if (options.indexOf(option) === -1) {
        options.push(option);
      }
    }
    return options;
  }

  return (
    <ButtonToolbar>
      {getAvailableOptions().map(value =>
        <FilterButton
          isSelected={props.selectedValue === value}
          key={props.selectorType + "_" + value}
          onChange={() => props.onChange(props.selectorType, value)}
          value={value}
        />,
      )}
    </ButtonToolbar>
  );
}

DataFilterSelector.propTypes = {
  licences: PropTypes.arrayOf(PropTypes.shape({
    version: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,
  })).isRequired,
  selectorType: PropTypes.string.isRequired,
  dependentType: PropTypes.string.isRequired,
  selectedValue: PropTypes.string,
  selectedDependentValue: PropTypes.string,
  onChange: PropTypes.func,
};