import React from "react";
import PasswordResetForm from "components/PasswordResetForm";
import {useParams} from "react-router-dom";
import PropTypes from "prop-types";

function StepTwo(props) {
  const {token} = useParams();

  return <PasswordResetForm {...props} token={token} isWelcomePage={true}/>;
}

StepTwo.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      token: PropTypes.string
    })
  }),
  requestPasswordReset: PropTypes.func,
  username: PropTypes.string,
  token: PropTypes.string,
  isWelcomePage: PropTypes.bool,
};
  
export default StepTwo;