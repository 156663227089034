/**
 * @typedef {Object} Token
 * @property {String} token The jwt token
 */

const LOCALSTORAGE_JWT_KEY = "jwt";

export function getAuthorizationHeader() {
  const token = getJwtToken();
  if (!token) {
    return {};
  }

  return {
    Authorization: "Bearer " + token.token
  };
}

/**
 * Store JWT token in local storage to keep user logged in between page refreshes
 * @param {Object} token
 */
export function setJwtToken(token) {
  localStorage.setItem(LOCALSTORAGE_JWT_KEY, JSON.stringify(token));
}

/**
 * Get the JWT token object from local storage
 * @return {Token|null}
 */
export function getJwtToken() {
  const tokenString = localStorage.getItem(LOCALSTORAGE_JWT_KEY);
  if (!tokenString) {
    return null;
  }
  return JSON.parse(tokenString);
}

/**
 * Clear the JWT token, effectively logging out the user
 */
export function clearJwtToken() {
  localStorage.removeItem(LOCALSTORAGE_JWT_KEY);
}

/**
 * Validate if the user has a valid JWT token
 * @return {boolean}
 */
export function userHasValidJwtToken() {
  const token = getJwtToken();

  return !(token === null || typeof token.token !== "string" ||
    token.token.length <= 0);
}

/**
 * @return {Object|null}
 */
export function getJwtPayload() {
  const token = getJwtToken();
  if (token === null) {
    return null;
  }
  const encodedPayload = token.token.split(".")[1];
  return JSON.parse(window.atob(encodedPayload));
}
