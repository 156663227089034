import {Button} from "reactstrap";
import React from "react";
import PropTypes from "prop-types";

const FilterButton = (props) => {
  return <Button
    className="mb-1 mx-1"
    color={props.value ? "info" : "success"}
    outline={!props.isSelected}
    onClick={props.onChange}
  >{ props.value || "All" }</Button>;
};

FilterButton.propTypes = {
  isSelected: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.string,
};

export default FilterButton;