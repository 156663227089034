import {createSlice} from "@reduxjs/toolkit";

const cartSlice = createSlice({
  name: "cart",
  initialState: {
    products: [],
    vatNumber: "",
    purchase: {
      sessionId: null,
    },
    error: "",
  },
  reducers: {
    addToCart(state, action) {
      state.products = [...state.products, action];
    },
    setPurchase(state, action) {
      state.purchase = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
  }
});


export const {addToCart, setPurchase, setError} = cartSlice.actions;

export default cartSlice.reducer;