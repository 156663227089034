import React, {useState} from "react";
import {isAdmin} from "services/userService";
import {deleteCustomer} from "services/customerService";
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {Button} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import LicensesTable from "components/LicensesTable";
import {faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {updateCustomers} from "store/customerSlice";
import {CCard, CCardBody, CSpinner} from "@coreui/react";
import DataTable from "react-data-table-component";

function CustomersOverview() {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const customers = useSelector(state => state.customer.customers);

  if (!isAdmin()) {
    throw  new Error("You do not have access to this page.");
  }

  if (!customers || loading) {
    return <CSpinner/>;
  }

  const performDelete = (customerId) => {
    if (!window.confirm("Are you sure you want to remove this customer?")) {
      return;
    }
    setLoading(true);
    deleteCustomer(customerId).then(() => {
      dispatch(updateCustomers(customers.filter(customer => customer.id !== customerId)));
      setLoading(false);
    });
  };

  const customersColumns = () => {
    let columns = [
      {
        name: "Id",
        selector: row => row.id,
        sortable: true,
        maxWidth: 50,
      },
      {
        name: "Name",
        selector: row => row.name,
        sortable: true,
      },
      {
        name: "Slug",
        selector: row => row.slug,
        sortable: true,
      },
      {
        name: "Trial",
        selector: row => row.trial,
        sortable: true,
      },
      {
        name: "Version",
        selector: row => row.defaultVersion,
        sortable: true,
      },
      {
        name: "Language",
        selector: row => row.defaultLanguage,
        sortable: true,
      },
      {
        id: "delete",
        name: "Delete",
        selector: row => {
          return <Button color="danger" outline onClick={() => performDelete(row.id)}>
            <FontAwesomeIcon icon={faTrashAlt} fixedWidth/> Delete
          </Button>;
        },
        sortable: true,
      },
    ];

    return columns;
  };

  return <CCard>
    <CCardBody>
      <DataTable
        columns={[...customersColumns()]}
        title="Customer list"
        data={customers}
        expandableRows
        pagination
        dense
        expandableRowsComponent={LicensesTable}
      />
    </CCardBody>
  </CCard>;
}

CustomersOverview.propTypes = {
  customers: PropTypes.array,
};

export default CustomersOverview;
