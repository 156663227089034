import React, { Component } from "react";
import { Provider } from "react-redux";
import LoginRouter from "./components/LoginRouter";
import "react-dates/initialize";
import store from "./store";
import {initializeSentry} from "./services/errorReporting";
import * as Sentry from "@sentry/browser";
import {initializeReactGA} from "./helpers/tracking";
import "./scss/style.scss";

initializeSentry();

export default class App extends Component {

  componentDidCatch(error, errorInfo) {
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      Sentry.captureException(error);
    });
  }

  render() {
    initializeReactGA();
    return (
      <Provider store={store}>
        <LoginRouter/>
      </Provider>
    );
  }
}
