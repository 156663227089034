import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import PropTypes from "prop-types";
import {getCurrentCustomer, shouldBuy} from "services/customerService";
import {Button, Table} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import "./css/ProductOffering.scss";
import ProductFeatures from "components/ProductFeatures";
import {CCard, CContainer, CSpinner} from "@coreui/react";
import {loadProducts} from "services/productService";
import {setOfferProduct, setProducts} from "store/productSlice";
import {SLUG_MY_CREDIT_ROBO} from "services/config";

const ProductOffering = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const products = useSelector(state => state.product.products.filter((product) => product.type === "pcs" && product.subscription));
  const customer = useSelector(state => getCurrentCustomer(state.customer));

  useEffect(() => {
    if (!loading && products.length === 0) {
      setLoading(true);
      loadProducts().then(({data}) => {
        dispatch(setProducts(data));

        const myCreditRobo = products.filter((product) => product.slug && product.slug === SLUG_MY_CREDIT_ROBO).shift();
        dispatch(setOfferProduct(myCreditRobo.id));
      });
    }
  }, [props, loading, setLoading]);

  if (!products) {
    return <CSpinner/>;
  }

  const goOrder = (productId) => {
    if (typeof props.setStep === "function") {
      props.setStep();
    }


    navigate(`/subscribe/${productId}`);
  };

  const features = {
    "amount": {label: "Price per month", type: "currency"},
    "setup": {label: "Set up costs (first month only)", type: "currency", default: 100},
    "sessionLimit": {label: "Number of scores per month", type: "int"},
    "dashboard": {label: "MyCreditRobo Dashboard 24/7", type: "bool", default: true},
    "scores": {label: "View and download psychographic credit scores", type: "bool", default: true},
    "subscore": {label: "View and download psychographic sub scores", type: "bool"},
    "progressData": {label: "View additional behavioural data", type: "bool"},
    "customiseColors": {label: "Customise colours", default: true},
    "customiseCopy": {label: "Customise intro text", default: true},
  };

  /**
   * Renders the row with the link to get the product.
   * @param products
   * @returns {*}
   */
  const headerLinksRow = (products) =>
    <tr>
      <td>&nbsp;</td>
      {products.map((product) =>
        <td key={product.id} className="content">
          {shouldBuy(customer) && product.slug === "trial-credit-robo" ?
            <div className="pt-2"><Link to="/">Start free trial</Link></div>
            : null
          }
          {shouldBuy(customer) && product.amount ?
            <Button color="info" block onClick={() => goOrder(product.id)}>
              <FontAwesomeIcon className="nav-icon" icon={faCheck}/>&nbsp;Buy now
            </Button>
            : null}
        </td>,
      )}
    </tr>;

  return <CContainer className="productOffering">
    <CCard>
      <Table hover striped responsive>
        <thead>
          <tr>
            <th>&nbsp;</th>
            {products.map((product) =>
              <th key={product.id} className={product.slug}>
                <div className="head">{product.title} </div>
              </th>,
            )}
          </tr>
          <tr>
            <td>&nbsp;</td>
            {products.map((product) =>
              <td key={product.id} className="content">{product.content}</td>,
            )}
          </tr>
          {headerLinksRow(products)}
        </thead>
        <tbody>
          <ProductFeatures features={features} products={products}/>
          {headerLinksRow(products)}
        </tbody>
      </Table>
      <p className="text-center text-muted">All prices exclude VAT.</p>
    </CCard>
  </CContainer>;
};

ProductOffering.propTypes = {
  setStep: PropTypes.func,
};


export default ProductOffering;

