import React from "react";
import PropTypes from "prop-types";
import {CButton, CCard, CCardBody, CCardHeader, CCardText} from "@coreui/react";

const PaymentResult = (props) => {
  if (props.success) {
    return (<CCard>
      <CCardHeader>Order Confirmation!</CCardHeader>
      <CCardBody>
        <CCardText>Thank you! Your payment is confirmed and the license has been added to your account.</CCardText>
        <CButton variant="info" href="/" color="info" className="px-4">Get Started</CButton>
      </CCardBody>
    </CCard>);
  }

  return (<CCard>
    <CCardHeader>Checkout Cancellation</CCardHeader>
    <CCardBody>
      <CCardText>Your payment has been cancelled. You have not been charged. If you wish to try again, please click
        the button below.</CCardText>
      <CButton variant="info" href="/choose-subscription" color="info" className="px-4">Start Again</CButton>
    </CCardBody>
  </CCard>);
};

PaymentResult.propTypes = {
  success: PropTypes.number.isRequired,
};

export default PaymentResult;
