import React from "react";
import PropTypes from "prop-types";
import BooleanField from "./CustomSettingsFields/BooleanField";
import StringField from "./CustomSettingsFields/StringField";
import NumberField from "./CustomSettingsFields/NumberField";

const CustomerSettingField = ({id, field, defaultValue, type, onFieldChanged}) => {
  const key = id + field;

  const convertToBoolean = (value) => {
    return value === "true" || value === true || value === 1 || value === "1";
  };

  const generateField = (id, field, defaultValue, type, onFieldChanged) => {
    switch (type) {
    case "boolean":
      return (
        <BooleanField
          id={id}
          field={field}
          checked={convertToBoolean(defaultValue)}
          onFieldChanged={onFieldChanged}/>
      );
    case "string":
      return (
        <StringField
          id={id}
          field={field}
          defaultValue={defaultValue}
          type={type}
          onFieldChanged={onFieldChanged}/>
      );
    case "number":
      return (
        <NumberField
          id={id}
          field={field}
          defaultValue={defaultValue}
          type={type}
          onFieldChanged={onFieldChanged}/>
      );
    default:
      return (
        <StringField
          id={id}
          field={field}
          defaultValue={defaultValue}
          type={type}
          onFieldChanged={onFieldChanged}/>
      );
    }
  };

  return (
    <div className="mb-3" key={key}>
      {generateField(id, field, defaultValue, type, onFieldChanged)}
    </div>
  );
};

CustomerSettingField.propTypes = {
  id: PropTypes.number,
  field: PropTypes.string,
  type: PropTypes.string,
  defaultValue: PropTypes.any,
  onFieldChanged: PropTypes.func,
};

export default CustomerSettingField;
