import {isAdmin} from "services/userService";
import {Line} from "react-chartjs-2";
import React from "react";
import PropTypes from "prop-types";
import {CCard, CCardBody, CCardHeader} from "@coreui/react";
import {CategoryScale, Chart, LinearScale, LineElement, PointElement} from "chart.js";
import DataTable from "react-data-table-component";

Chart.register(PointElement, LineElement, LinearScale, CategoryScale);

function DashboardCurveChartsAndMetrics(props) {
  if (props.modelMetrics === null || !isAdmin()) {
    return null;
  }

  const metrics = props.modelMetrics;
  const listOfCharts = [];

  let index = 0;
  for (let model in metrics) {
    const options = {
      scales: {
        myAxis: {
          position: "top",
          title: {
            display: true,
            font: {
              size: 18,
              weight: "bold"
            },
            text: "ROC Curve"
          },
          ticks: {
            display: false
          }
        },
        y: {
          min: 0,
          max: 1,
          position: "left",
          title: {
            display: true,
            text: "True positive rate",
            font: {
              size: 14,
              weight: "bold"
            }
          }
        },
        x: {
          title: {
            display: true,
            text: "False positive rate",
            font: {
              size: 14,
              weight: "bold"
            },
          },
          ticks: {
            display: true,
            callback: function (value) {
              return Math.round(value * 100) / 100;
            }
          }
        }
      }
    };

    let curveLenght = (metrics[model]["curve_data"]["FPR"]).length;
    let noSkills = [];
    let incrementedValue = 0;
    for (let i = 0; i <= curveLenght; i++) {
      noSkills[i] = incrementedValue;
      incrementedValue += 1 / (curveLenght - 1);
    }

    const data = {
      datasets: [{
        data: metrics[model]["curve_data"]["TPR"],
        label: metrics[model]["title"],
        fill: false,
        backgroundColor: "#4dbd74",
        borderColor: "#4dbd74",
      }, {
        data: noSkills,
        fill: false,
        label: "Random (no skill)",
        borderDash: [5, 5],
        borderColor: "grey",
        backgroundColor: "grey",
      }],
      labels: metrics[model]["curve_data"]["FPR"]
    };

    let tableData = [];
    for (let metric in metrics[model]["metrics"]) {
      let value = metrics[model]["metrics"][metric];
      if (Array.isArray(value)) {
        value = (value).reduce((a, b) => a + b, 0) / (value).length;
      }
      tableData.push({
        "name": metric,
        "value": Math.round(value * 100) / 100
      });
    }

    listOfCharts.push(
      <CCard key={index} className="mb-3">
        <CCardHeader>
          Metrics for {metrics[model]["title"]}
        </CCardHeader>
        <CCardBody>
          <div className={"row"}>
            <div className={"col-md-6 col-xs-12"}>
              <Line key={index} data={data} options={options}/>
            </div>
            <div className={"col-md-6 col-xs-12"}>
              <DataTable
                columns={[
                  {
                    name: "Metric names",
                    selector: row => row.name,
                  },
                  {
                    name: "Scores",
                    selector: row => row.value,
                  },
                ]}
                data={tableData}
              />
            </div>
          </div>
        </CCardBody>
      </CCard>
    );
    index++;
  }

  return (
    <div className={"col-12"}>
      {listOfCharts}
    </div>
  );
}

DashboardCurveChartsAndMetrics.propTypes = {
  modelMetrics: PropTypes.object,
};

export default DashboardCurveChartsAndMetrics;
