import React, {useState, useEffect} from "react";
import {connect, useDispatch, useSelector} from "react-redux";
import {UncontrolledAlert, Card, CardBody, Row, Col, Button} from "reactstrap";
import {DateRangePicker} from "react-dates";
import moment from "moment";
import "moment/min/locales";
import {INFO_POSITION_BEFORE} from "react-dates/constants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendar} from "@fortawesome/free-regular-svg-icons";
import {StringParam, NumberParam, useQueryParams} from "use-query-params";
import {
  filterIsInInitialState,
} from "actions/filterAction";
import PropTypes from "prop-types";
import "react-dates/lib/css/_datepicker.css";
import DataFilterSelector from "./DataFilterSelector";
import {getCurrentCustomers} from "services/customerService";
import {clearResultCount, setFilter} from "store/filterSlice";
import {CFormSwitch} from "@coreui/react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import {setSelectedCustomers} from "store/customerSlice";

const DataFilter = (props) => {
  const [queryParams, setQueryParams] = useQueryParams({
    startDate: StringParam,
    endDate: StringParam,
    version: StringParam,
    language: StringParam,
    isTest: NumberParam,
    selfService: NumberParam,
    customers: Array,
  });

  const [locale] = useState("en"); // TODO: Maybe this should be a prop
  const [selectedStartDate, setSelectedStartDate] = useState(
    getQueryParamAsDate(queryParams.startDate || props.filter.startDate));
  const [selectedEndDate, setSelectedEndDate] = useState(
    getQueryParamAsDate(queryParams.endDate || props.filter.endDate));
  const [focusedInput, setFocusedInput] = useState(null);
  const customerState = useSelector((state) => state.customer);
  const selectedCustomers = useSelector((state) => state.customer.selectedCustomers);
  const [isSelectedCustomersChanged, setIsSelectedCustomersChanged] = useState(false);
  const filters = useSelector((state) => state.filter.filters);
  const dispatch = useDispatch();

  moment.locale(locale); // Sets month/week names to locale

  useEffect(() => {
    if (filterIsInInitialState(queryParams) && !filterIsInInitialState(filters)) {
      setQueryParams({...filters, customers: selectedCustomers});
    }

    if (isSelectedCustomersChanged) {
      onFilterChange({...filters, customers: selectedCustomers});
      setQueryParams({...filters, customers: selectedCustomers});
      setIsSelectedCustomersChanged(false);
    } else {
      updateDateFilter();
    }
  }, [selectedCustomers, focusedInput]);

  function getQueryParamAsDate(queryParam) {
    if (queryParam === undefined || queryParam === null || queryParam === "null") {
      return null;
    }
    return moment(queryParam);
  }

  function formatDateFilters() {
    return {
      startDate: selectedStartDate && selectedStartDate.format("YYYY-MM-DD"),
      endDate: selectedEndDate && selectedEndDate.format("YYYY-MM-DD"),
    };
  }

  function updateSelectedDates({startDate, endDate}) {
    setSelectedStartDate(startDate);
    setSelectedEndDate(endDate);
  }

  function onDatePickerFocusChange(focusedInputName) {
    setFocusedInput(focusedInputName);
  }

  function updateDateFilter() {
    if (focusedInput) {
      return; // Only update after selection has finished (no input focused anymore)
    }

    const dateFilters = formatDateFilters();
    let updateFilter = false;

    // Update the query params if needed
    if (queryParams) {
      if (queryParams.startDate !== dateFilters.startDate || queryParams.endDate !== dateFilters.endDate) {
        setQueryParams({...filters, ...dateFilters, customers: selectedCustomers});
        updateFilter = true;
      }
    }

    // Save start and end date to stored filters
    if (props.filter.startDate !== dateFilters.startDate || props.filter.endDate !== dateFilters.endDate) {
      updateFilter = true;
    }

    if (updateFilter) {
      onFilterChange({...filters, ...dateFilters, customers: selectedCustomers});
    }
  }

  function onFilterChange(filter) {
    dispatch(setFilter(filter));
    dispatch(clearResultCount());
  }

  function setToStartAndEndOf(unitOfTime) {
    updateSelectedDates({
      startDate: moment().startOf(unitOfTime),
      endDate: moment().endOf(unitOfTime),
    });
    onDatePickerFocusChange(null);
  }

  function renderCalendarSidePanel() {
    return <Col className="mt-3">
      <Button color="link" onClick={setToStartAndEndOf.bind(this, "day")}>Today</Button><br/>
      <Button color="link" onClick={setToStartAndEndOf.bind(this, "month")}>This month</Button><br/>
      <Button color="link" onClick={setToStartAndEndOf.bind(this, "quarter")}>This quarter</Button><br/>
      <Button color="link" onClick={setToStartAndEndOf.bind(this, "year")}>This year</Button><br/>
    </Col>;
  }

  function changeFilter(filterName, value) {
    setQueryParams({
      [filterName]: value,
    });

    onFilterChange({...filters, [filterName]: value, customers: selectedCustomers});
  }

  function renderResultCount() {
    const count = props.resultCount;
    if (count.applicants !== null && count.sessions !== null) {
      let str = count.applicants + " applicant";
      if (count.applicants !== 1) {
        str += "s";
      }
      str += " and " + count.sessions + " session";
      if (count.sessions !== 1) {
        str += "s";
      }
      str += " in this selection.";
      return str;
    } else {
      return <div>&nbsp;</div>;
    }
  }

  const getSelectOptions = () => {
    const customers = customerState.customers;
    const options = [];
    customers.map((customer) => {
      options.push({
        value: customer.id,
        label: customer.name,
      });
    });

    return options;
  };

  const getDefaultSelectOptions = () => {
    const customers = customerState.customers;
    const options = [];
    customers.map((customer) => {
      if (selectedCustomers.includes(customer.id)) {
        options.push({
          value: customer.id,
          label: customer.name,
        });
      }
    });

    return options;
  };

  const animatedComponents = makeAnimated();

  return (
    <Card className={"mb-3"}>
      <CardBody>
        <Row>
          <Col md="6" className="mb-3">
            <h4>Date range</h4>
            <DateRangePicker
              startDate={selectedStartDate}
              startDateId="start_date_input"
              endDate={selectedEndDate}
              endDateId="end_date_input"
              onDatesChange={updateSelectedDates.bind(this)}
              onClose={updateDateFilter.bind(this)}
              focusedInput={focusedInput}
              onFocusChange={onDatePickerFocusChange.bind(this)}
              numberOfMonths={1}
              showClearDates={true}
              customInputIcon={<FontAwesomeIcon icon={faCalendar}/>}
              firstDayOfWeek={1}
              isOutsideRange={() => {
                return false;
              }}
              hideKeyboardShortcutsPanel={true}
              small={true}
              calendarInfoPosition={INFO_POSITION_BEFORE}
              renderCalendarInfo={renderCalendarSidePanel.bind(this)}
              displayFormat={() => moment.localeData(locale).longDateFormat("L")} // Sets selected date display format
            />
          </Col>

          <Col md="6" className="mb-3">
            <h4>Select accounts</h4>
            <Select
              onChange={(selectedOption) => {
                const selectedCustomersList = [];
                if (selectedOption.length > 0) {
                  for (const index in selectedOption) {
                    selectedCustomersList.push(selectedOption[index].value);
                  }
                }

                setIsSelectedCustomersChanged(true);
                dispatch(setSelectedCustomers(selectedCustomersList));
              }}
              closeMenuOnSelect={false}
              components={animatedComponents}
              defaultValue={getDefaultSelectOptions()}
              isMulti
              options={getSelectOptions()}
            />
          </Col>

          {props.licences.length > 1 ?
            <Col md="6" sm="6" className="mb-3">
              <h4>Version</h4>
              <DataFilterSelector
                licences={props.licences}
                selectorType="version"
                dependentType="language"
                selectedValue={queryParams.version || null}
                selectedDependentValue={queryParams.language || null}
                onChange={changeFilter.bind(this)}
              />
            </Col>
            : ""}

          {props.licences.length > 1 ?
            <Col md="6" sm="6" className="mb-3">
              <h4>Language</h4>
              <DataFilterSelector
                licences={props.licences}
                selectorType="language"
                dependentType="version"
                selectedValue={queryParams.language || null}
                selectedDependentValue={queryParams.version || null}
                onChange={changeFilter.bind(this)}
              />
            </Col>
            : ""}

          {props.selfService ?
            <Col md="4" sm="6" className="d-flex mb-3">
              <h4>&nbsp;</h4>
              <CFormSwitch
                className="switch-sm" checked={props.selfService === 1} onChange={
                  () => {
                    changeFilter("selfService", props.selfService === null ? 1 : null);
                  }
                }
              />
              <div>
                Only self service
              </div>
            </Col>
            : ""}

          <Col md="4" sm="6" className="d-flex mb-3">
            <h4>&nbsp;</h4>
            <CFormSwitch
              className="switch-sm" checked={props.isTest === 0} onChange={
                () => {
                  changeFilter("isTest", props.isTest === 0 ? null : 0);
                }
              }
            />
            <div>
              {props.isTest === 0 ? "Include" : "Exclude"} test applicants
            </div>
          </Col>
        </Row>

        {!props.dateOnly ?
          <Row className="mb-3">
            <Col md="8" sm="6">
              {renderResultCount()}
              {props.trial &&
                <UncontrolledAlert className="mt-2 mb-0" color="warning">
                  <strong>Note:</strong> The results below are limited to a set of 20, because you&apos;re using a trial
                  account.
                </UncontrolledAlert>
              }
            </Col>
          </Row>
          : ""}
      </CardBody>
    </Card>
  );
};

DataFilter.propTypes = {
  dateOnly: PropTypes.bool,
  filter: PropTypes.shape({
    endDate: PropTypes.string,
    language: PropTypes.string,
    startDate: PropTypes.string,
    version: PropTypes.string,
  }),
  isTest: PropTypes.number,
  licences: PropTypes.array,
  resultCount: PropTypes.shape({applicants: PropTypes.number, sessions: PropTypes.number}),
  selfService: PropTypes.bool,
  trial: PropTypes.bool,
};

function mapStateToProps(state) {
  const customers = getCurrentCustomers(state.customer);
  let licences = [];
  let trial = false;
  if (customers.length > 0) {
    Object.values(customers).reduce((refs, customer) => {
      licences = refs.licences.concat(customer.licences);
      return refs;
    });
    if (customers.length === 1) {
      licences = customers[0].licences;
    }
  }

  return {
    filter: state.filter.filters,
    resultCount: state.filter.resultCount,
    trial: trial,
    licences: licences,
    isTest: state.filter.filters.isTest,
    selfService: Boolean(state.filter.filters.selfService),
  };
}


export default connect(mapStateToProps)(DataFilter);
