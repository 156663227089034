import Documentation from "./route/Documentation";
import Applicants from "./route/Applicants";
import Customisation from "./route/Customisation/Customisation";
import QuestionnaireTexts from "./route/Customisation/QuestionnaireTexts";
import QuestionnaireColors from "./route/Customisation/QuestionnaireColors";
import CreateSession from "./route/Customisation/CreateSession";
import CustomersOverview from "./route/CustomersOverview";
import Settings from "./route/Settings";
import InvoiceData from "./route/InvoiceData";
import SubscriptionData from "./route/SubscriptionData";
import OpenBanking from "./route/OpenBanking";
// import FeatureImportance from "./route/FeatureImportance";
import RocCurve from "./route/RocCurve";
import CustomerSettings from "./route/CustomerSettings";
import Dashboard from "./route/Dashboard";

const routes = [
  {path: "/dashboard", exact: true, name: "Dashboard", element: Dashboard},
  {path: "/api-documentation", exact: true, name: "API Integration", element: Documentation},
  {path: "/applicants", exact: true, name: "Applicants", element: Applicants},
  {path: "/applicant/openbanking", exact: true, name: "Demo open banking", element: OpenBanking},
  {path: "/questionnaire", exact: true, name: "Customise", element: Customisation},
  {path: "/questionnaire/texts", exact: true, name: "Questionnaire texts", element: QuestionnaireTexts},
  {path: "/questionnaire/colors", exact: true, name: "Questionnaire colors", element: QuestionnaireColors},
  {path: "/questionnaire/create-session", exact: true, name: "Test customization", element: CreateSession},
  {path: "/admin/customers", exact: true, name: "Customers", element: CustomersOverview},
  {path: "/admin/settings", exact: true, name: "Customers", element: Settings},
  {path: "/admin/invoices", exact: true, name: "Invoices", element: InvoiceData},
  {path: "/admin/subscriptions", exact: true, name: "Subscriptions", element: SubscriptionData},
  {path: "/model-metrics/roc-curve", exact: true, name: "ROC-curve", element: RocCurve},
  // {path: "/model-metrics/feature-importance", exact: true, name: "Feature importance", element: FeatureImportance},
  {path: "/customer/settings", exact: true, name: "Settings", element: CustomerSettings},
  {path: "/customer/invoices", exact: true, name: "Invoices", element: InvoiceData},
  {path: "/customer/subscriptions", exact: true, name: "Subscriptions", element: SubscriptionData},
];

export default routes;
