
export const API_SERVER = process.env.REACT_APP_API_URL || "https://admin-api.local:8443";
export const API_VERSION = "v1";
export const API_URL = `${API_SERVER}/api`;
export const API_URL_WITH_VERSION = `${API_SERVER}/api/${API_VERSION}`;
export const API_CUSTOMERS_PATH = "/api/api_customers";
export const API_PURCHASES_PATH = "/api/purchases";
export const API_SUBSCRIPTION_PATH = "/api/subscriptions";
export const API_COLORS_PATH = "/api/colors";

export const STRIPE_API_KEY = process.env.REACT_APP_STRIPE_API_KEY || "pk_test_k53Cn6bwQutXtwh4hehfCTdW00gVxbU0gk";
export const GOOGLE_RECAPTCHA_KEY = process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY || "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI";

export const SLUG_MY_CREDIT_ROBO = "my-credit-robo";