import {initialState} from "store/filterSlice";

export function filterIsInInitialState(object) {
  for (let key in initialState.filters) {
    if (Object.prototype.hasOwnProperty.call(initialState.filters, key)) {
      if (!Object.prototype.hasOwnProperty.call(object, key) || object[key] !== initialState.filters[key]) {
        return false;
      }
    }
  }
  return true;
}