import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import DataTable from "react-data-table-component";

function LicensesTable({data}) {
  const licencesColumns = () => {
    return [
      {
        name: "Version",
        selector: row => row.version,
        sortable: true,
      },
      {
        name: "Language",
        selector: row => row.language,
        sortable: true,
      },
      {
        name: "Max Sessions",
        selector: row => row.sessionLimit || "no limit",
        sortable: true,
      },
      {
        name: "Expiry date",
        selector: row => row.expiry ? moment(row.expiry).format("DD-MM-YYYY") : "none",
        sortable: true,
      },
      {
        name: "Comment",
        selector: row => row.comment,
        sortable: true,
      },
    ];
  };

  return <DataTable
    title="Licences"
    columns={[...licencesColumns()]}
    data={data.licences}
  />;
}

LicensesTable.propTypes = {
  data: PropTypes.shape({
    licences: PropTypes.array,
  }),
};

export default LicensesTable;
