import React, {useState, useEffect} from "react";
import {Form, FormGroup, Input, FormText, Label, FormFeedback} from "reactstrap";
import {getAvailableLanguages} from "services/userService";
import {checkVAT, countries} from "jsvat";
import PropTypes from "prop-types";

export default function QuestionnaireConfigForm(props) {
  const [languagesLoaded, setLanguagesLoaded] = useState(false);
  const [availableLanguages, setAvailableLanguages] = useState({});

  useEffect(() => {
    if (!languagesLoaded) {
      getAvailableLanguages().then(({data}) => {
        setAvailableLanguages(data);
        setLanguagesLoaded(true);
      });
    }
  }, [languagesLoaded, setAvailableLanguages]);

  return <div style={{margin: "3em 0"}}>
    <Form>
      <FormGroup>
        <Label for="inputLanguage">Questionnaire language</Label>
        <Input
          type="select" name="language" id="inputLanguage" value={props.options.language}
          onChange={(event) => props.changeHandler(
            {language: event.target.value, version: props.options.version})}>
          {Object.entries(availableLanguages).map(([languageCode, languageName]) => {
            return <option value={languageCode} key={languageCode}>{languageName}</option>;
          })}
        </Input>
        <FormText className="form-text text-muted">This questionnaire will be presented to your customers in this
          language.</FormText>
      </FormGroup>
      <FormGroup>
        <Label for="inputVatNumber">Vat Number <span className="text-muted small">(not required)</span></Label>
        <Input
          type="text" id="inputVatNumber"
          invalid={props.vatNumber !== "" && !checkVAT(props.vatNumber, countries).isValid}
          valid={props.vatNumber === "" || checkVAT(props.vatNumber, countries).isValid}
          name="vatNumber" value={props.vatNumber}
          onChange={(event) => props.setVatNumber(event.target.value)}/>
        <FormText className="form-text text-muted">If available, please enter your VAT identification number and we will not charge you VAT (21%, Netherlands).</FormText>
        <FormFeedback>Please enter a valid eu vat number.</FormFeedback>
      </FormGroup>
    </Form>
  </div>;
}

QuestionnaireConfigForm.propTypes = {
  options: PropTypes.shape({
    language: PropTypes.string,
    version: PropTypes.string,
  }),
  vatNumber: PropTypes.string,
  changeHandler: PropTypes.func.isRequired,
  setVatNumber: PropTypes.func.isRequired,
};