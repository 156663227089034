import React, {useEffect, useState} from "react";
import "react-tabs/style/react-tabs.css";
import PropTypes from "prop-types";
import {CTable} from "@coreui/react";
import {prepareItemsForTable} from "../../../services/general";

const ApplicantScoreExpandedEmailSoc = ({items}) => {
  const [emailSocial, setEmailSocial] = useState([]);

  useEffect(() => {
    if (Object.keys(items).length !== 0) {
      setEmailSocial(prepareItemsForTable(items));
    }
  }, [items]);

  const getEmailSocialDataColumns = () => {
    return [
      {key: "label", label: "Label"},
      {key: "info", label: "Info"},
    ];
  };

  return (
    <CTable
      items={emailSocial}
      columns={getEmailSocialDataColumns()}
      hover
      striped
      bordered
    />
  );
};

ApplicantScoreExpandedEmailSoc.propTypes = {
  items: PropTypes.object,
};

export default ApplicantScoreExpandedEmailSoc;
