import React, {useEffect, useState} from "react";
import {isAdmin, UserService} from "services/userService";
import CustomerSetting from "components/CustomerSetting";
import {CCard, CCardBody, CCardGroup, CSpinner} from "@coreui/react";
import PasswordChangeForm from "../components/PasswordChangeForm";

export default function CustomerSettings() {
  const [settings, setSettings] = useState();
  const [isSettingsLoding, setIsSettingsLoading] = useState(false);

  useEffect(() => {
    setIsSettingsLoading(true);
    UserService.getCustomerSettings().then(({data}) => {
      if (data.success) {
        setSettings(data.data);
      }

      setIsSettingsLoading(false);
    }).catch(() => {
      setIsSettingsLoading(false);
    });
  }, []);

  const renderCustomerSettings = () => {
    const cards = [];
    for (let setting in settings) {
      const customerSetting = settings[setting];

      cards.push(
        <CustomerSetting
          key={customerSetting.id}
          id={customerSetting.id}
          name={customerSetting.customer}
          data={customerSetting.settings}
          fields={customerSetting.settings_fields}
        />,
      );
    }

    return cards;
  };

  return (
    <>
      {isSettingsLoding ? <CSpinner/> : renderCustomerSettings()}

      {!isAdmin() ? <CCardGroup className="mb-3">
        <CCard>
          <CCardBody>
            <div>
              <PasswordChangeForm/>
            </div>
          </CCardBody>
        </CCard>
      </CCardGroup> : null}
    </>
  );
}
