import {arrayToQueryString, buildQueryString} from "./general";
import "url-search-params-polyfill";
import $apiWithToken from "http";

const CUSTOMER_KEY = "customer";

const getQueryParams = () => new URLSearchParams(window.location.search);

export function getSelectedCustomer() {
  const queryParams = getQueryParams();
  if (queryParams.has(CUSTOMER_KEY)) {
    return parseInt(queryParams.get(CUSTOMER_KEY), 10);
  }
  return null;
}

export function saveSelectedCustomer(customer) {
  getQueryParams().set(CUSTOMER_KEY, customer);
}

export function getSelectedCustomers() {
  const queryParams = getQueryParams();
  if (queryParams.has(CUSTOMER_KEY)) {
    return (queryParams.getAll(CUSTOMER_KEY)).map((customer) => parseInt(customer));
  }
  return null;
}

export function getSelectedCustomersFromQueryString() {
  const params = new URLSearchParams(window.location.search);
  if (!params.has("customers")) {
    return null;
  }

  return (params.get("customers").split(",")).map(customer => parseInt(customer));
}

export function saveSelectedCustomers(customer) {
  customer.forEach(id => getQueryParams().append(CUSTOMER_KEY, id));
}

export function clearSelectedCustomer() {
  getQueryParams().delete(CUSTOMER_KEY);
}

export function getCurrentCustomer(customerData) {
  if (customerData.customers && customerData.customers.length > 0) {
    return customerData.customers.find((customer) => {
      return customer.id === customerData.selectedCustomer;
    });
  }
  return null;
}

export function getCurrentCustomers(customerData) {
  if (customerData.customers) {
    return customerData.customers.filter((customer) => {
      return customerData.selectedCustomers.indexOf(customer.id) > -1;
    });
  }
  return null;
}

/**
 * Filters out any licenses that have expired.
 * @param {Object} customer
 * @returns {Array|*}
 */
export function getValidLicences(customer) {
  if (!customer || !customer.licences) {
    return [];
  }

  return customer.licences.filter((license) =>
    !license.expiry || Date.parse(license.expiry) > Date.now()
  );
}

export function shouldBuy(customer) {
  return !customer || (customer.trial || getValidLicences(customer).length === 0);
}

export function currentCustomerHas(customerData, property) {
  const customer = getCurrentCustomer(customerData);
  if (customer === undefined || !customer) {
    return false;
  }
  return Object.prototype.hasOwnProperty.call(customer, property) && customer[property];
}

export async function loadCustomers() {
  return $apiWithToken.get("/api/api_customers");
}

export async function getCustomerTexts(customer, customers = []) {
  const params = {customer: customer, customers: customers};

  return $apiWithToken.get(`api/v1/customer/texts?${buildQueryString(params)}`);
}

export async function saveCustomerTexts(slides, skipIntro, completeTitle, completeText, customer) {
  return $apiWithToken.post("/api/v1/customer/texts", {
    slides: slides,
    skipIntro: skipIntro,
    completeTitle: completeTitle,
    completeText: completeText,
    customerId: customer,
  });
}

export async function getSessionCount(customer, customers = []) {
  const params = {customer: customer, customers: customers};

  return $apiWithToken.get(`/api/v1/sessionCount?${buildQueryString(params)}`);
}

export async function deleteCustomer(customerId) {
  return $apiWithToken.delete(`/api/api_customers/${customerId}.json`);
}

export async function updateCustomerSettings(customerId, data) {
  return $apiWithToken.put(`/api/v1/customer/settings/${customerId}/update`, data);
}

export async function getCustomerApiKeys(customers) {
  const query = arrayToQueryString("customer", customers);

  return $apiWithToken.get(`/api/api_keys?${query}`);
}