import React from "react";
import {Button} from "reactstrap";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import {getOfferProduct} from "services/productService";
import {setOfferProduct} from "store/productSlice";
import {CSpinner} from "@coreui/react";

const PleasePurchase = () => {
  // eslint-disable-next-line no-unused-vars
  const offerProduct = useSelector(state => state.product.offerProduct);
  const dispatch = useDispatch();

  if (offerProduct === null) {
    getOfferProduct().then(({data}) => {
      dispatch(setOfferProduct(data.product.id));
    });
  }

  return offerProduct ?
    <div>
      <p>You need to upgrade to access this functionality.</p>
      <Link to={"/subscribe/" + offerProduct}>
        <Button color="warning" size="lg" block>
          <FontAwesomeIcon className="nav-icon" icon={faCheck}/>upgrade now
        </Button>
      </Link>
    </div>
    : <CSpinner />;
};

export default PleasePurchase;
