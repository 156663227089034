import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Button, Card, CardBody, CardHeader} from "reactstrap";
import {getFunnelData} from "services/statistics";
import DataFilter from "components/DataFilter";
import {CategoryScale, LinearScale, BarElement, Chart} from "chart.js";
import {Bar} from "react-chartjs-2";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileDownload} from "@fortawesome/free-solid-svg-icons";
import {isAdmin} from "services/userService";
import {setResultCount} from "store/filterSlice";
import {CSpinner} from "@coreui/react";
import {getQueryFilterParams} from "../services/general";

Chart.register(CategoryScale, LinearScale, BarElement);

/**
 * Examples at
 * @link https://github.com/coreui/coreui-free-react-admin-template/blob/master/src/views/Charts/Charts.js
 */
function FunnelMetrics() {
  const dispatch = useDispatch();
  const filter = useSelector(state => state.filter.filters);
  const [loading, setLoading] = useState(true);
  const [parameters, setParameters] = useState({});
  const [funnelChartData, setFunnelChartData] = useState({
    labels: ["Created", "Started", "Completed"],
    datasets: [],
  });

  useEffect(() => {
    if (filter.customers) {
      setLoading(true);
      const data = getQueryFilterParams(
        filter,
        filter.customers
      );

      setParameters(data);
      getFunnelData(data).then(({data}) => updateFunnelChart(data));
    }
  }, [filter]);

  function updateFunnelChart(json) {
    setLoading(false);
    dispatch(setResultCount({sessions: json.count.sessions, applicants: json.count.applicants}));
    let dataSet = {
      label: "Number of sessions",
      backgroundColor: "#1b8eb7",
      borderColor: "#1985ac",
      borderWidth: 1,
      data: [json.data.created, json.data.started, json.data.success],
      fill: false,
    };
    setFunnelChartData({
      ...funnelChartData,
      datasets: [dataSet],
    });
  }

  function downloadData() {
    // TODO: ARPCS-283 Point to CSV export url instead of json dump
    getFunnelData(parameters, true);
  }
  return (
    <div>
      <DataFilter />
      <Card className="mb-3">
        <CardHeader>
          Funnel metrics
          <div className="card-header-actions">{
            isAdmin() ?
              <Button color="link" className="card-header-action" onClick={downloadData}>
                <FontAwesomeIcon icon={faFileDownload}/> Download
              </Button>
              : null
          }</div>
        </CardHeader>
        <CardBody>
          {loading ? <CSpinner/> :
            <div style={{maxWidth: "800px", margin: "auto"}}>
              <Bar data={funnelChartData}/>
            </div>
          }
        </CardBody>
      </Card>
    </div>
  );
}

export default FunnelMetrics;