import React from "react";
import "react-tabs/style/react-tabs.css";
import PropTypes from "prop-types";
import {CTable} from "@coreui/react";

const ApplicantScoreExpandedPcsScore = ({data, info}) => {
  const getTableData = () => {
    const tableData = [
      {
        name: "Total score",
        score: `${data.score}`,
      },
    ];

    info.dimensions.forEach((dimension) => {
      if (dimension && dimension.name && dimension.accessor && data[dimension.accessor]) {
        tableData.push({
          name: dimension.name.charAt(0).toUpperCase() + dimension.name.slice(1),
          score: data[dimension.accessor],
        });
      }
    });

    return tableData;
  };

  return (
    <CTable
      responsive
      items={getTableData()}
      columns={[
        {key: "name", label: "Name"},
        {key: "score", label: "Score"},
      ]}
      hover
      striped
      bordered
    />
  );
};

ApplicantScoreExpandedPcsScore.propTypes = {
  info: PropTypes.shape({
    dimensions: PropTypes.array,
  }),
  data: PropTypes.shape({
    applicantExternalId: PropTypes.string,
    score: PropTypes.number,
    applicantId: PropTypes.number,
    success: PropTypes.bool,
  }),
};

export default ApplicantScoreExpandedPcsScore;
