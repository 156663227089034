import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import {Bar} from "react-chartjs-2";
import {CCard} from "@coreui/react";
import PropTypes from "prop-types";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function FeatureImportance({featureImportance}) {
  const indexAxis = "y";
  const position = "right";

  const getOptions = (title, descriptions) => {
    return {
      indexAxis: indexAxis,
      elements: {
        bar: {
          borderWidth: 2,
        },
      },
      responsive: true,
      plugins: {
        legend: {
          position: position,
        },
        title: {
          display: true,
          text: title,
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              if (descriptions[context.label]) {
                return ` ${descriptions[context.label]}: ${context.parsed.x}`;
              }
              return context.parsed.y;
            },
          },
        },
      },
    };
  };

  const getData = (data, style, label) => {
    const labels = Object.keys(data);

    return {
      labels: labels,
      datasets: [
        {
          label: label,
          data: labels.map((category) => parseFloat(data[category])),
          borderColor: style.borderColor,
          backgroundColor: style.backgroundColor,
        },
      ],
    };
  };

  const getImportance = (featureImportance) => {
    const importance = [];
    for (const feature in featureImportance) {
      importance.push(
        <CCard className="mb-3" key={feature}>
          <Bar
            options={getOptions(featureImportance[feature].title, featureImportance[feature].descriptions)}
            data={getData(featureImportance[feature].data, featureImportance[feature].style, featureImportance[feature].title)}
          />
        </CCard>
      );
    }

    return importance;
  };

  return <>{getImportance(featureImportance)}</>;
}

FeatureImportance.propTypes = {
  featureImportance: PropTypes.object,
};

export default FeatureImportance;
