import {createSlice} from "@reduxjs/toolkit";
import {setJwtToken, userHasValidJwtToken} from "helpers/jwt";
import {logout as logoutUser} from "services/userService";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    isAuthenticated: userHasValidJwtToken(),
  },
  reducers: {
    setAuthenticated(state, action) {
      setJwtToken(action.payload);
      state.isAuthenticated = userHasValidJwtToken();
    },
    logout(state) {
      logoutUser();
      state.isAuthenticated = false;
    }
  }
});

export const {setAuthenticated, logout} = authSlice.actions;

export default authSlice.reducer;