import React from "react";
import CIcon from "@coreui/icons-react";
import {
  cilSpeedometer,
} from "@coreui/icons";
import {CNavGroup, CNavItem} from "@coreui/react";
import {
  faBookOpen,
  faChartBar,
  faChartSimple,
  faPalette,
  faTable,
  faUserCircle
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {isAdmin} from "./services/userService";

const _nav = {
  1: {
    component: CNavItem,
    name: "Dashboard",
    to: "/dashboard",
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon"/>
  },
  2: {
    component: CNavGroup,
    name: "Statistic",
    icon: <FontAwesomeIcon icon={faChartBar} className="nav-icon"/>,
    items: [
      {
        component: CNavItem,
        name: "Funnel metrics",
        to: "/statistic/funnel",
      },
      {
        component: CNavItem,
        name: "Score distribution",
        to: "/statistic/score",
      },
    ],
  },
  3: {
    component: CNavGroup,
    name: "Data",
    icon: <FontAwesomeIcon icon={faTable} className="nav-icon"/>,
    items: [
      {
        component: CNavItem,
        name: "Applicants Data",
        to: "/applicants",
      },
    ],
  },
  5: {
    component: CNavGroup,
    name: "Customise questionnaire",
    icon: <FontAwesomeIcon icon={faPalette} className="nav-icon"/>,
    items: [
      {
        component: CNavItem,
        name: "Questionnaire texts",
        to: "/questionnaire/texts",
      },
      {
        component: CNavItem,
        name: "Questionnaire colors",
        to: "/questionnaire/colors",
      },
      {
        component: CNavItem,
        name: "Test customization",
        to: "/questionnaire/create-session",
      },
    ],
  },
  6: {
    component: CNavGroup,
    name: "Documentation",
    icon: <FontAwesomeIcon icon={faBookOpen} className="nav-icon"/>,
    items: [
      {
        component: CNavItem,
        name: "API Integration",
        to: "/api-documentation",
      },
    ],
  },
  7: {
    component: CNavItem,
    name: "Settings",
    icon: <FontAwesomeIcon icon={faUserCircle} className="nav-icon"/>,
    to: "/customer/settings",
  },
};

let _admin_nav = [];
if (isAdmin()) {
  _admin_nav = {
    4: {
      component: CNavGroup,
      name: "Model metrics",
      icon: <FontAwesomeIcon icon={faChartSimple} className="nav-icon"/>,
      items: [
        {
          component: CNavItem,
          name: "ROC-curve",
          to: "/model-metrics/roc-curve",
        },
        {
          component: CNavItem,
          name: "Dynamic Scores",
          disabled: true,
          to: "/model-metrics/dynamic-scores",
        },
      ],
    },
    8: {
      component: CNavGroup,
      name: "Administration",
      icon: <FontAwesomeIcon icon={faUserCircle} className="nav-icon"/>,
      items: [
        {
          component: CNavItem,
          name: "Customers",
          to: "/admin/customers",
        },
        {
          component: CNavItem,
          name: "Settings",
          to: "/admin/settings",
        },
        {
          component: CNavItem,
          name: "Invoices",
          to: "/admin/invoices",
        },
        {
          component: CNavItem,
          name: "Subscriptions",
          to: "/admin/subscriptions",
        },
      ],
    },
  };

  _nav[3].items = [
    ..._nav[3].items,
    {
      component: CNavItem,
      name: "Demo open banking",
      to: "/applicant/openbanking",
    }
  ];
}

const _navigation = Object.values({..._nav, ..._admin_nav});

export default _navigation;
