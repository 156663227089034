import React from "react";
import PropTypes from "prop-types";

const Currency = (props) => {
  let amount = props.amount || 0;
  let symbol = null;
  if (!isNaN(amount)) {
    if (Number.isInteger(amount)) {
      amount = props.amount + ",-";
    } else {
      amount = Math.round(amount * 100) / 100;
    }
    symbol = props.symbol || "€";
  }

  return <span className="currency">
    {symbol ? <span className="symbol">{symbol}</span> : null}
    <span className="amount">{amount}</span>
  </span>;
};

Currency.propTypes = {
  amount: PropTypes.any,
  symbol: PropTypes.string,
};

export default Currency;