import React, {useState} from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCardHeader,
  CForm,
} from "@coreui/react";
import CustomerSettingField from "./CustomerSettingField";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {updateCustomerSettings} from "services/customerService";
import PropTypes from "prop-types";

export default function CustomerSetting({id, name, data, fields}) {
  const [fieldsData, setFieldsData] = useState(data);
  const [isLoading, setIsloading] = useState(false);

  const onFieldChanged = (currentValue, fieldName) => {
    setFieldsData({...fieldsData, [fieldName]: currentValue});
  };

  const generateSettingFields = (fields, data) => {
    const fieldsList = [];

    for (const field in fields) {
      const fieldInfo = fields[field];

      let value = "";
      if (Object.keys(data).includes(fieldInfo.setting)) {
        value = data[fieldInfo.setting];
      }

      fieldsList.push(
        <CustomerSettingField
          key={id + field}
          type={fieldInfo.type}
          field={fieldInfo.setting}
          id={id}
          defaultValue={value}
          onFieldChanged={onFieldChanged}
        />,
      );
    }

    return fieldsList;
  };

  const handleSubmit = () => {
    setIsloading(true);
    updateCustomerSettings(id, fieldsData).then(() => {
      setIsloading(false);
    }).catch(() => {
      setIsloading(false);
    });
  };

  return (
    <CCardGroup className="mb-3">
      <CCard>
        <CCardHeader>{name}</CCardHeader>
        <CCardBody>
          <CForm>
            {generateSettingFields(fields, fieldsData)}
            <CButton color="info" onClick={handleSubmit} className="px-4" disabled={isLoading}>
              {isLoading ?
                <FontAwesomeIcon fixedWidth={true} icon={faSpinner} spin={true} className="ml-2 mr-2"/> :
                <span>Update settings</span>
              }
            </CButton>
          </CForm>
        </CCardBody>
      </CCard>
    </CCardGroup>
  );
}

CustomerSetting.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  data: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
  fields: PropTypes.array,
};
