import React from "react";
import {
  CDropdown,
  CDropdownDivider,
  CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from "@coreui/react";
import {
  cilCash,
  cilLockLocked,
  cilUser,
  cilUserFollow,
} from "@coreui/icons";
import CIcon from "@coreui/icons-react";

import {getJwtPayload} from "helpers/jwt";
import {faUserCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useNavigate} from "react-router-dom";
import {isAdmin} from "../../services/userService";

const AppHeaderDropdown = () => {
  const user = getJwtPayload();
  const navigate = useNavigate();

  return (
    <CDropdown variant="nav-item">
      <CDropdownToggle placement="bottom-end" className="py-0" caret={false}>
        <FontAwesomeIcon className="mx-3" icon={faUserCircle}/>
        <span>{user.username || ""}</span>
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        <CDropdownHeader className="bg-light fw-semibold py-2">Settings</CDropdownHeader>
        <CDropdownItem
          className="mt-2"
          onClick={() => isAdmin() ? navigate("/admin/settings") : navigate("/customer/settings")}
        >
          <CIcon icon={cilUser} className="me-2"/>
          Settings
        </CDropdownItem>
        <CDropdownItem onClick={() => isAdmin() ? navigate("/admin/invoices") : navigate("/customer/invoices")}>
          <CIcon icon={cilCash} className="me-2"/>
          Invoices
        </CDropdownItem>
        <CDropdownItem
          onClick={() => isAdmin() ? navigate("/admin/subscriptions") : navigate("/customer/subscriptions")}
        >
          <CIcon icon={cilUserFollow} className="me-2"/>
          Subscriptions
        </CDropdownItem>
        <CDropdownDivider/>
        <CDropdownItem href="/logout">
          <CIcon icon={cilLockLocked} className="me-2"/>
          Logout
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  );
};

export default AppHeaderDropdown;
