import React, {useState} from "react";
import {CCard, CCardBody, CTable} from "@coreui/react";
import "react-tabs/style/react-tabs.css";
import PropTypes from "prop-types";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";

const RiskFeaturesExpanded = ({data}) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const handleTabSelect = (index) => {
    setActiveTabIndex(index);
  };

  const reorderRiskFeatures = (riskFeatures, type) => {
    const orderFields = {
      "affordability": [
        "date_range",
        "uncategorized_expenses_sum",
        "cpa_expenses_mt",
        "n_transactions_expenses",
        "amount_min",
        "closing_balance_skew",
        "date_nunique",
        "automated_payment_sum",
        "amount_max",
        "closing_balance_kurtosis",
        "n_transactions_income",
        "desc._collector_id_(dd)_mt",
        "bank_count",
        "closing_balance_mean",
        "closing_balance_max",
        "time_mean",
        "duo_income_sum",
        "closing_balance_min",
        "time_variance",
        "desc._collector_id_(dd)_mv",
        "closing_balance_variance",
        "cash_w_sum",
        "gas_station_sum",
        "desc._mandate_reference_(dd)_mv",
        "cafe_sum",
        "health_sum",
        "food_drinks_sum",
        "bar_sum",
        "bicycle_store_sum",
        "zoo_sum",
        "desc._payment_reference_mt",
        "desc._mandate_reference_(dd)_nunique",
        "bnpl_sum",
        "benefit_sum"
      ],
      "overdraft": [
        "loan_fraction_expenses",
        "loan_sum",
        "loan_fraction_income",
        "cpa_nunique",
        "automated_payment_sum",
        "time_mean",
        "n_transactions_income",
        "direct_debit_correction_mean",
        "time_variance",
        "desc._collector_id_(dd)_mt",
        "cpa_expenses_mt",
        "type_of_payment_nunique",
        "cpa_expenses_mv",
        "i_savings_fraction_expenses",
        "automated_payment_mean",
        "bnpl_sum",
        "desc._collector_id_(dd)_mv",
        "clothing_store_fraction_expenses",
        "type_of_payment_mt",
        "desc._collector_id_(dd)_count",
        "gym_sum",
        "n_transactions_per_day",
        "savings_account",
        "entertainment_fraction_income",
        "amount_skew",
        "bnpl_fraction_income",
        "income_variance",
        "personal_care_sum",
        "direct_debit_correction_sum",
        "salary_fraction_income",
        "type_of_payment_mv",
        "weekday_mean",
        "amount_mean",
        "expenses_variance",
        "car_repair_fraction_expenses",
        "parking_sum",
        "tax_sum",
        "liquor_store_fraction_income",
        "desc._pay_machine_id_nunique",
        "amount_variance",
        "uncategorized_income_fraction_expenses",
        "date_range"
      ]
    };
    const featureImportance = orderFields[type];

    const reorderedRiskFeatures = [];
    for (let key in featureImportance) {
      if (featureImportance[key] in riskFeatures) {
        reorderedRiskFeatures[featureImportance[key]] = `${riskFeatures[featureImportance[key]]}`;
      }
    }

    for (let key in riskFeatures) {
      if (!(key in reorderedRiskFeatures)) {
        reorderedRiskFeatures[key] = `${riskFeatures[key]}`;
      }
    }

    return reorderedRiskFeatures;
  };

  const getColumns = () => {
    return [
      {key: "name", label: "Label"},
      {key: "score", label: "Info"},
    ];
  };

  const getTableData = () => {
    const tableData = [];
    const riskFeatures = reorderRiskFeatures(data.risk_features, data.type);
    for (let key in riskFeatures) {
      tableData.push({
        name: key,
        score: data.risk_features[key] ? data.risk_features[key] : "0"
      });
    }

    return tableData;
  };

  const getCategorizationMetricsColumns = () => {
    return [
      {key: "category", label: ""},
      {key: "total_amount", label: "Total amount (€)"},
      {key: "percentage_of_total", label: "Percentage of total"},
    ];
  };

  const getExpensesCategorizationMetricsData = () => {
    return getMetricsData(data.categorization_metrics.categories.expense);
  };

  const getIncomeCategorizationMetricsData = () => {
    return getMetricsData(data.categorization_metrics.categories.income);
  };

  const getMetricsData = (data) => {
    return Object.keys(data.amount).map(category => ({
      category,
      total_amount: data.amount[category],
      percentage_of_total: data.total_percentage[category]
    }));
  };

  return (
    <CCard className="mt-1">
      <Tabs selectedIndex={activeTabIndex} onSelect={handleTabSelect}>
        <CCardBody>
          <TabList>
            <Tab>Risk features</Tab>
            <Tab>Categorization metrics</Tab>
          </TabList>
          <TabPanel>
            <CTable
              responsive
              items={getTableData()}
              columns={getColumns()}
              hover
              striped
              bordered
            />
          </TabPanel>
          <TabPanel>
            <div>
              <p>Percentage of transactions categorized: {data.categorization_metrics.transactions_categorized}</p>
              <p>Percentage of total value (in €) categorized: {data.categorization_metrics.total_value_categorized}</p>
              <p>Total number of days in overdraft: {data.categorization_metrics.total_number_of_days_in_overdraft}</p>
              <p>Fraction of total days in
                overdraft: {data.categorization_metrics.fraction_of_total_days_in_overdraft}</p>
              <p>Total income - total expenses = {data.categorization_metrics.total_income_total_expenses}</p>
            </div>
            <h5>Income</h5>
            <CTable
              responsive
              items={getIncomeCategorizationMetricsData()}
              columns={getCategorizationMetricsColumns()}
              hover
              striped
              bordered
            />
            <h5>Expenses</h5>
            <CTable
              responsive
              items={getExpensesCategorizationMetricsData()}
              columns={getCategorizationMetricsColumns()}
              hover
              striped
              bordered
            />
          </TabPanel>
        </CCardBody>
      </Tabs>
    </CCard>
  );
};

RiskFeaturesExpanded.propTypes = {
  data: PropTypes.shape({
    type: PropTypes.string,
    amount: PropTypes.object,
    total_percentage: PropTypes.object,
    overdraft: PropTypes.number,
    saving: PropTypes.number,
    risk_features: PropTypes.object,
    categorization_metrics: PropTypes.object,
  }),
};

export default RiskFeaturesExpanded;
