import React, {useEffect, useState} from "react";
import {isAdmin} from "services/userService";
import DashboardCurveChartsAndMetrics from "components/DashboardCurveChartsAndMetrics";
import {getModelMetrics} from "services/statistics";
import FeatureImportance from "components/FeatureImportance";

function RocCurve() {
  const [loadingModelMetrics, setLoadingModelMetrics] = useState(false);
  const [modelMetrics, setModelMetrics] = useState({});
  const [featureImportance, setFeatureImportance] = useState({});

  useEffect(() => {
    setLoadingModelMetrics(true);

    getModelMetrics().then(({data}) => {
      setModelMetrics(data["curve-data"] ? data["curve-data"] : {});
      setFeatureImportance(data["feature-importance"] ? data["feature-importance"] : {});
      setLoadingModelMetrics(false);
    });
  }, []);

  return (
    <>
      {isAdmin() && !loadingModelMetrics && modelMetrics && Object.entries(modelMetrics).length > 0 ?
        <DashboardCurveChartsAndMetrics modelMetrics={modelMetrics}/> : ""}
      {isAdmin() && !loadingModelMetrics && featureImportance && Object.entries(featureImportance).length > 0 ?
        <FeatureImportance featureImportance={featureImportance}/> : ""}
    </>
  );
}

export default RocCurve;
