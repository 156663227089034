import React, {useState, useEffect} from "react";
import {useSelector} from "react-redux";
import Currency from "components/Products/Currency";
import {
  Alert,
  Button,
} from "reactstrap";
import {
  loadSubscriptions,
  cancelSubscription,
  APPROVED,
  CANCEL,
} from "services/purchaseService";
import moment from "moment";
import {CCard, CCardBody} from "@coreui/react";
import DataTable from "react-data-table-component";

function SubscriptionData() {
  const selectedCustomer = useSelector(state => state.customer.selectedCustomer);
  const [subscriptions, setSubscriptions] = useState([]);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [subscriptionsLoaded, setSubscriptionsLoaded] = useState(false);

  const getOrderLinesForSubscription = (orderLines) => {
    if (!orderLines) {
      return [];
    }
    return orderLines.filter(order => order.product.subscription === true);
  };
  const sum = (lines, key) => {
    return lines.reduce((a, b) => a + (b[key] || 0), 0);
  };
  const updateSubscriptionData = (subscriptions) => {
    if (!subscriptions) {
      return [];
    }
    subscriptions = subscriptions.filter((subscription) => {
      return subscription.status === APPROVED && subscription.purchases &&
        getOrderLinesForSubscription(subscription.purchases[subscription.purchases.length - 1].orderLines).length > 0;
    }).map((subscription) => {
      const orderLines = getOrderLinesForSubscription(subscription.purchases[subscription.purchases.length - 1].orderLines);
      subscription.amountExcludedTax = sum(orderLines, "amountExcludedTax");
      subscription.tax = sum(orderLines, "tax");
      subscription.amount = sum(orderLines, "amount");
      subscription.title = orderLines[0].product.title;
      return subscription;
    });
    setSubscriptions(subscriptions);
    setLoading(false);
  };

  useEffect(() => {
    if (!subscriptionsLoaded) {
      setLoading(true);
      setSubscriptionsLoaded(true);

      loadSubscriptions().then(response => updateSubscriptionData(response.data));
    }
  }, [subscriptionsLoaded]);

  const subscriptionColumns = () => [
    {
      id: "title",
      name: "Product",
      selector: row => row.title,
      maxWidth: 200,
    },
    {
      id: "amountExcludedTax",
      name: "Amount",
      selector: row => <Currency amount={row.amountExcludedTax}/>,
    },
    {
      id: "vatIncluded",
      name: "VAT",
      selector: row => (row.amountExcludedTax === row.amount) ? "reverse charged" : <Currency amount={row.tax}/>,
    },
    {
      id: "amount",
      name: "Total amount",
      selector: row => <Currency amount={row.amount}/>,
    },
    {
      id: "postedAt",
      name: "Start date",
      selector: row => moment(row.postedAt).format("DD-MM-YYYY"),
    },
    {
      id: "updatedAt",
      name: "Last changed",
      selector: row => moment(row.updatedAt).format("DD-MM-YYYY"),
    },
    {
      id: "cancel",
      name: "Cancel",
      selector: row => getCancelButton(row.status, row.id),
    },
  ];

  const performCancel = (subscriptionId) => {
    setLoading(true);
    setError(false);
    setSuccess(false);
    cancelSubscription(selectedCustomer, subscriptionId).then(() => {
      setSuccess("The Subscription is cancelled successfully");
      let data = subscriptions.map(row => {
        if (row.id === subscriptionId) {
          row.status = CANCEL;
        }
        return row;
      });
      setLoading(false);
      setSubscriptions(data);
    }).catch(() => {
      setLoading(false);
      setError("Please contact Customer Support");
    });
  };

  const getCancelButton = (status, subscriptionId) => {
    if (status === APPROVED) {
      return <Button size="sm" onClick={() => performCancel(subscriptionId)} color="danger">Cancel</Button>;
    }

    return false;
  };

  return <CCard>
    <CCardBody>
      {error ? <Alert color="danger">{error}</Alert> : null}
      {success ? <Alert color="success">{success}</Alert> : null}
      <div className={"data-content clearfix"}>
        <DataTable
          columns={subscriptionColumns()}
          progressPending={loading}
          title="Subscriptions"
          data={subscriptions}
          pagination
          dense
        />
      </div>
      <p className="text-center text-muted mt-2">If we have your Euro VAT Id we can reverse charge the VAT.</p>
    </CCardBody>
  </CCard>;
}

export default SubscriptionData;
