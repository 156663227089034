import {API_COLORS_PATH, API_CUSTOMERS_PATH, API_SERVER} from "./config";
import $apiWithToken from "http";

export async function saveColor(customerId, color) {
  if (!color.customer && customerId) {
    color.customer = `${API_CUSTOMERS_PATH}/${customerId}`;
  }

  if (!color.category) {
    throw Error("Trying to save a color without category");
  }
  if (!color.value) {
    throw Error("Trying to save a color without value");
  }
  if (!color.customer) {
    throw Error("Trying to save a color without customer");
  }

  let url = `${API_SERVER}${API_COLORS_PATH}`;
  if (color.id) {
    url += `/${color.id}`;

    return await $apiWithToken.put(url, color);
  }

  return await $apiWithToken.post(url, color);
}