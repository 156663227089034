import {Navigate} from "react-router-dom";
import {useSelector} from "react-redux";

const ProtectedRoute = ({children}) => { // eslint-disable-line
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  if (!isAuthenticated) {
    return (<Navigate to="/login" replace={true}/>); // eslint-disable-line
  }

  return (children);
};

export default ProtectedRoute;