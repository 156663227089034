import {getAuthorizationHeader} from "helpers/jwt";
import {buildQueryString, filterValuesForFilename} from "./general";
import {API_URL} from "./config";
import $apiWithToken from "http/index";


const downloadFileHeaders = {
  Accept: "text/csv",
};

const doFetchAsJSON = (path, downloadFileName) => {
  return downloadData(path, downloadFileName, "application/json");
};

const downloadData = (path, downloadFileName, acceptType) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Accept": acceptType,
      ...getAuthorizationHeader()
    }
  };

  return fetch(`${API_URL}/${path}`, requestOptions)
    .then(response => response.blob())
    .then(blob => {
      try {
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = downloadFileName || "data.txt";
        link.click();
      } catch (e) {
        console.error(e);
      }
    });
};

export function getFunnelData(params = {}, forDownload = false) {
  if (forDownload) {
    // TODO: Rework to using axios
    return doFetchAsJSON(`v1/funnel?${buildQueryString(params)}`, "funnel" + filterValuesForFilename(params) + ".json");
  }

  return $apiWithToken.get(`/api/v1/funnel?${buildQueryString(params)}`);
}

export function getScoreDistribution(params = {}, forDownload = false) {
  if (forDownload) {
    // TODO: Rework to using axios
    return doFetchAsJSON(`v1/scores/distribution?${buildQueryString(params)}`, "scoreDistribution" + filterValuesForFilename(params) + ".json");
  }

  return $apiWithToken.get(`/api/v1/scores/distribution?${buildQueryString(params)}`);
}

export async function getDashboardMetrics(customers) {
  return $apiWithToken.get(`/api/v1/metrics?${buildQueryString({customers: customers})}`);
}

export async function getScoreData(params) {
  return await $apiWithToken.get(`/api/v1/scores/data?${buildQueryString(params)}`);
}

export async function getScoreDataAsCSV(params) {
  const fileName = "Score-Data" + filterValuesForFilename(params) + ".csv";

  $apiWithToken.get(
    `api/v1/scores/data.csv?${buildQueryString(params)}`,
    {
      responseType: "blob",
      headers: downloadFileHeaders
    }
  ).then((response) => handleFileDownloadResponse(response, fileName)).catch(error => console.log(error));
}

export function getProgressDataAsCSV(params) {
  const fileName = "Progress-Data" + filterValuesForFilename(params) + ".csv";

  $apiWithToken.get(
    `api/v1/progress/data.csv?${buildQueryString(params)}`,
    {
      responseType: "blob",
      headers: downloadFileHeaders
    }
  ).then((response) => handleFileDownloadResponse(response, fileName)).catch(error => console.log(error));
}

export function getBrowserDataAsCSV(params) {
  const fileName = "Browser-Data" + filterValuesForFilename(params) + ".csv";

  $apiWithToken.get(
    `api/v1/platform/data.csv?${buildQueryString(params)}`,
    {
      responseType: "blob",
      headers: downloadFileHeaders
    }
  ).then((response) => handleFileDownloadResponse(response, fileName)).catch(error => console.log(error));
}

export function getTimePerQuestionAsCSV(params) {
  const fileName = "Time-per-Question" + filterValuesForFilename(params) + ".csv";

  $apiWithToken.get(
    `api/v1/timePerQuestion/data.csv?${buildQueryString(params)}`,
    {
      responseType: "blob",
      headers: downloadFileHeaders
    }
  ).then((response) => handleFileDownloadResponse(response, fileName)).catch(error => console.log(error));
}

export async function getModelMetrics() {
  return $apiWithToken.get("/api/v1/model/metrics");
}

export async function getFeatureImportance() {
  return $apiWithToken.get("/api/v1/feature/importance");
}

const handleFileDownloadResponse = (response, fileName) => {
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
};