import {useSelector} from "react-redux";
import React, {useState} from "react";
import {CFormSelect} from "@coreui/react";
import {Button} from "reactstrap";
import {getQueryFilterParams} from "services/general";
import PropTypes from "prop-types";

const ApplicantExport = ({onExport}) => {
  const filter = useSelector(state => state.filter.filters);
  const selectedCustomers = useSelector(state => state.customer.selectedCustomers);
  const [exportDataType, setExportDataType] = useState("score");

  return (
    <>
      <CFormSelect
        defaultValue={exportDataType}
        onChange={(e) => setExportDataType(e.target.value)}
        options={[
          {label: "Score data", value: "score"},
          {label: "Time data", value: "time"},
          {label: "Digital footprint data", value: "footprint"},
          {label: "Progress data", value: "progress"}
        ]}
      />

      <Button
        className={"btn-info"}
        onClick={() => onExport(getQueryFilterParams(filter, selectedCustomers), exportDataType)}
      >
        Export
      </Button>
    </>
  );
};

ApplicantExport.propTypes = {
  onExport: PropTypes.func,
};

export default ApplicantExport;