import React, {useEffect} from "react";
import PropTypes from "prop-types";

const IframeComm = React.forwardRef((props, ref) => { // eslint-disable-line
  const serializePostMessageData = (data) => {
    if (typeof data === "object") {
      return JSON.stringify(data);
    } else if (typeof data === "string") {
      return data;
    } else {
      return `${data}`;
    }
  };

  const sendMessage = (postMessageData) => {
    const {targetOrigin} = props;
    const serializedData = serializePostMessageData(postMessageData);
    if (ref.current && ref.current.contentWindow) {
      ref.current.contentWindow.postMessage(serializedData, targetOrigin);
    }
  };

  useEffect(() => {
    ref.current.addEventListener("load", () => sendMessage(props.postMessageData));
  });

  const {attributes} = props;

  const defaultAttributes = {
    allowFullScreen: false,
    frameBorder: 0,
    sandbox: "allow-scripts allow-same-origin",
  };

  const mergedAttributes = Object.assign(
    {},
    defaultAttributes,
    attributes,
  );

  return (
    <iframe
      title={attributes.title}
      ref={ref}
      {...mergedAttributes}
    />
  );
});

IframeComm.defaultProps = {
  targetOrigin: "*",
  postMessageData: "",
};

IframeComm.propTypes = {
  attributes: PropTypes.shape({
    allowFullScreen: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
    ]),
    frameBorder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    title: PropTypes.string,
    scrolling: PropTypes.string,
    // https://www.html5rocks.com/en/tutorials/security/sandboxed-iframes/
    sandbox: PropTypes.string,
    srcDoc: PropTypes.string,
    src: PropTypes.string.isRequired,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),

  postMessageData: PropTypes.any.isRequired,
  targetOrigin: PropTypes.string,
};

export default IframeComm;