import React, {useEffect, useState} from "react";
import {connect, useDispatch} from "react-redux";
import PropTypes from "prop-types";
import {ButtonToolbar} from "reactstrap";
import {getCurrentCustomer} from "services/customerService";
import FilterButton from "components/FilterButton";
import {setFilter} from "store/filterSlice";

const LanguageSelector = (props) => {
  // unique set of languages from licences:
  const dispatch = useDispatch();
  const {filter} = props;
  const languages = [...new Set(props.licences.map(licence => licence.language))];
  const [language, setLanguage] = useState(filter.language);

  const onChange = language => {
    setLanguage(language);
    dispatch(setFilter({language: language}));
    props.onChange();
  };

  useEffect(() => {
    if (!props.isFilterSet && filter.language) {
      dispatch(setFilter(filter));
    }
  }, [filter, props]);

  return languages.length > 1 ?
    <div>
      <h5>Select the language</h5>
      <ButtonToolbar>
        {languages.map(value =>
          <FilterButton
            isSelected={language === null ? filter.language === value : language === value}
            key={"language_" + value}
            onChange={() => onChange(value)}
            value={value}
          />,
        )}
      </ButtonToolbar>
    </div>
    : "";
};

LanguageSelector.propTypes = {
  filter: PropTypes.object,
  licences: PropTypes.array,
  isFilterSet: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  setFilter: PropTypes.func,
};

function mapStateToProps(state) {
  const customer = getCurrentCustomer(state.customer);
  const filters = state.filter.filters || {};
  return {
    filter: {language: filters.language || customer ? customer.defaultLanguage : null},
    isFilterSet: !!filters.language,
    licences: customer ? customer.licences : [],
  };
}

export default connect(mapStateToProps)(LanguageSelector);
