import React, {useState, useEffect} from "react";
import {loadPurchases} from "services/purchaseService";
import Currency from "components/Products/Currency";
import {Button} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileDownload} from "@fortawesome/free-solid-svg-icons";
import {CCard, CCardBody} from "@coreui/react";
import DataTable from "react-data-table-component";

function InvoiceData() {
  const [purchases, setPurchases] = useState([]);
  const [loading, setLoading] = useState(false);
  const [purchasesLoaded, setPurchasesLoaded] = useState(false);

  useEffect(() => {
    if (!purchasesLoaded) {
      setLoading(true);
      setPurchasesLoaded(true);
      loadPurchases().then((purchases) => updatePurchaseData(purchases.data));
    }
  }, [purchasesLoaded]);

  const updatePurchaseData = (purchases) => {
    setPurchases(purchases || []);
    setLoading(false);
  };

  const getPriceWithoutVat = (orderLines) => {
    orderLines = orderLines.filter(order => order.product.slug !== "vat");
    if (orderLines.length === 0) {
      return "-";
    }

    return orderLines.length > 1 ? orderLines.reduce((rowA, rowB) => (rowA.amount ? rowA.amount : rowA) + rowB.amount) : orderLines[0].amount;
  };

  const downloadPdf = (path, id) => {
    const anchor = document.createElement("a");
    anchor.style.visibility = "hidden";
    document.body.appendChild(anchor);
    anchor.href = path;
    anchor.download = "Invoice-" + id + ".pdf";
    anchor.click();
  };

  const purchaseColumns = () =>
    [
      {
        id: "totalAmount",
        name: "Total Amount",
        selector: row => <Currency amount={getPriceWithoutVat(row.orderLines)}/>,
        sortable: true,
      },
      {
        name: "Status",
        selector: row => row.status,
        sortable: true,
      },
      {
        id: "postedAt",
        name: "Time",
        selector: row => new Date(row.postedAt).toLocaleString(),
      },
      {
        id: "invoicePdf",
        name: "Download Invoice",
        selector: row => row.invoicePdf !== null ?
          <Button
            color="link" className="card-header-action"
            onClick={() => downloadPdf(row.invoicePdf, row.id)}>
            <FontAwesomeIcon icon={faFileDownload}/> Download
          </Button> :
          "-",
      },
    ];


  const purchaseSubColumns = () => {
    return [
      {
        id: "title",
        name: "Product",
        selector: row => row.product.title,
      },
      {
        id: "amount",
        name: "Amount",
        selector: row => <Currency amount={row.amount.toString()}/>,
      },
      {
        name: "Version",
        selector: row => row.version,
      },
      {
        name: "Language",
        selector: row => row.language,
      },
    ];
  };

  const purchaseSub = ({data}) => {
    return (
      <DataTable
        className="p-5"
        columns={[...purchaseSubColumns()]}
        progressPending={loading}
        data={data.orderLines.filter(order => order.product.slug !== "vat")}
        dense
      />
    );
  };

  return (
    <CCard>
      <CCardBody>
        <DataTable
          columns={[...purchaseColumns()]}
          progressPending={loading}
          title="Invoices"
          data={purchases}
          expandableRows
          pagination
          dense
          expandableRowsComponent={purchaseSub}
        />
      </CCardBody>
    </CCard>
  );
}

export default InvoiceData;
