import authSlice from "./authSlice";
import customerSlice from "./customerSlice";
import contentSlice from "./contentSlice";
import filterSlice from "./filterSlice";
import productSlice from "./productSlice";
import cartSlice from "./cartSlice";

const slices = {
  auth: authSlice,
  customer: customerSlice,
  content: contentSlice,
  filter: filterSlice,
  product: productSlice,
  cart: cartSlice,
};

export default slices;
