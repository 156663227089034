import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilePdf} from "@fortawesome/free-regular-svg-icons";
import {
  implementationGuide,
  lastUpdated,
  version,
} from "helpers/implementation-guide";
import "./Documentation.scss";
import "pygments-css/github.css"; // For code block styling
import ApiKeys from "components/ApiKeys";
import {CButton, CCard, CCardBody, CCardHeader} from "@coreui/react";

export default function Documentation() {

  const openDocumentation = (url) => {
    window.open(url, "_blank");
  };

  function cleanUpHtml(html) {
    // Replace image paths to include documentation folder
    html = html.replace(new RegExp("src=\"_images/", "g"), "src=\"documentation/_images/");

    // Make sure external links use target="_blank"
    html = html.replace(new RegExp("href=\"http", "g"), "target=\"_blank\" href=\"http");

    // Inject version string
    const versionString = "<p>Version " + version + ", last update: " + lastUpdated + "</p>";
    html = html.replace(new RegExp("<div class=\"toctree-wrapper compound\">\\s*</div>", "g"), versionString);

    return html;
  }

  return <>
    <ApiKeys/>
    <CCard className={"mb-3"}>
      <CCardHeader><h4>API Documentation</h4></CCardHeader>
      <CCardBody>
        <div className="card-header-actions">
          <CButton color="outline-info" onClick={() => openDocumentation("/documentation/Implementationguide.pdf")}>
            <FontAwesomeIcon icon={faFilePdf} fixedWidth/> Download as pdf
          </CButton>
        </div>

        <div className="html-documentation" dangerouslySetInnerHTML={{__html: cleanUpHtml(implementationGuide)}}/>
      </CCardBody>
    </CCard>
  </>;
}