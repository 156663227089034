import React, {useEffect, useState} from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Col,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
// import {AppSwitch} from "@coreui/react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faTimes} from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import "route/Customisation/QuestionnaireTexts.scss";
import {getCurrentCustomer, getCustomerTexts, saveCustomerTexts} from "services/customerService";
import {connect, useSelector} from "react-redux";
import PleasePurchase from "components/PleasePurchase";
import LanguageSelector from "components/LanguageSelector";
import {CFormSwitch, CSpinner} from "@coreui/react";

function QuestionnaireTexts(props) {
  const selectedCustomer = useSelector(state => state.customer.selectedCustomer);
  const selectedCustomers = useSelector(state => state.customer.selectedCustomers);
  const [skipIntro, setSkipIntro] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalEditIndex, setModalEditIndex] = useState(null);
  const [slides, setSlides] = useState([]);
  const [loadedCustomer, setLoadedCustomer] = useState(null);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [completeTitle, setCompleteTitle] = useState("");
  const [completeText, setCompleteText] = useState("");

  let modalTitleRef = React.createRef();
  let modalTextRef = React.createRef();

  const loadCustomerTextsToState = async () => {
    setLoading(true);
    const customerTexts = (await getCustomerTexts(selectedCustomer, selectedCustomers));

    setSlides(customerTexts.data.slides);
    setCompleteTitle(customerTexts.data.completeTitle);
    setCompleteText(customerTexts.data.completeText);
    setSkipIntro(customerTexts.data.skipIntro);
    setLoadedCustomer(props.selectedCustomer);
    setLoading(false);
  };

  useEffect(() => {
    if (props.selectedCustomer && props.selectedCustomer !== loadedCustomer && !loading) {
      loadCustomerTextsToState();
    }
  }, []);

  function openModal(editIndex = null) {
    setModalEditIndex(editIndex);
    setModalOpen(true);
  }

  function closeModal() {
    setModalOpen(false);
  }

  function modalFinish() {
    let newSlides = Array.from(slides);
    if (modalEditIndex !== null) {
      newSlides.splice(modalEditIndex, 1, {
        title: modalTitleRef.current.value,
        text: modalTextRef.current.value,
      });

    } else {
      newSlides = newSlides.concat({
        title: modalTitleRef.current.value,
        text: modalTextRef.current.value,
      });
    }
    closeModal();
    setSlides(newSlides);
  }

  function removeSlide(index) {
    let newSlides = Array.from(slides);
    newSlides.splice(index, 1);
    setSlides(newSlides);
  }

  async function save() {
    setSaving(true);
    await saveCustomerTexts(slides, skipIntro, completeTitle, completeText, selectedCustomer);

    setSaving(false);
  }

  if (props.customer && props.customer.trial) {
    return <PleasePurchase/>;
  }

  return <Card className="mb-3">
    <CardHeader>
      Questionnaire texts
    </CardHeader>
    <CardBody>
      <LanguageSelector onChange={loadCustomerTextsToState}/>
    </CardBody>
    <CardBody>
      {!loadedCustomer ? <CSpinner/> :
        <div>
          <h5>Introduction slides</h5>
          <p>When applicants start the questionnaire, we will display an introduction.
            You can enable or disable this introduction and edit the text of this introduction below.</p>
          <p>To save your changes click the &quot;save&quot; button at bottom of the page.</p>
          <Card className={"mb-3"}>
            <CardBody>
              <div className="clearfix mb-3">
                <CFormSwitch
                  variant="pill"
                  color="danger"
                  className="float-left mr-2"
                  checked={skipIntro}
                  onChange={e => {
                    setSkipIntro(e.target.checked);
                  }}/>
                <span>Skip intro page and display the first question directly.</span>
              </div>
            </CardBody>
          </Card>
          {!skipIntro ?
            <div>
              <Row className={"mb-3"}>
                {slides && slides.map((slide, index) => {
                  return (<Col xl="4" lg="6" sm="12" key={index}>
                    <Card className="card-hover intro-slide">
                      <CardHeader><strong>Slide {index + 1} of {slides.length}</strong>
                        <div className="card-header-actions">
                          <Button
                            outline
                            color="danger"
                            size="sm"
                            title="Delete slide"
                            onClick={(event) => {
                              event.stopPropagation();
                              removeSlide(index);
                            }}>
                            <FontAwesomeIcon icon={faTimes}/>
                          </Button></div>
                      </CardHeader>
                      <CardBody onClick={() => {
                        openModal(index);
                      }} title="Click to edit this slide">
                        <h5>{slide.title}</h5>
                        {slide.text}
                      </CardBody>
                    </Card>
                  </Col>);
                })}
              </Row>

              <Modal isOpen={modalOpen} toggle={closeModal} onOpened={() => {
                if (modalEditIndex !== null) {
                  modalTitleRef.current.value = slides[modalEditIndex].title;
                  modalTextRef.current.value = slides[modalEditIndex].text;
                }
              }}>
                <ModalHeader toggle={closeModal}>{modalEditIndex !== null ? "Edit" : "Add"} slide</ModalHeader>
                <ModalBody>
                  <Form>
                    <FormGroup>
                      <Label for="inputSlideTitle">Title</Label>
                      <Input type="text" name="inputSlideTitle" id="inputSlideTitle" innerRef={modalTitleRef}/>
                    </FormGroup>
                    <FormGroup>
                      <Label for="inputSlideText">Text</Label>
                      <Input type="textarea" name="text" id="inputSlideText" rows="6" innerRef={modalTextRef}/>
                    </FormGroup>
                  </Form>
                </ModalBody>
                <ModalFooter>
                  <Button color="success" onClick={modalFinish}>OK</Button>{" "}
                  <Button color="secondary" onClick={closeModal}>Cancel</Button>
                </ModalFooter>
              </Modal>

              <Button color="success" onClick={() => {
                openModal();
              }}>
                <FontAwesomeIcon icon={faPlus}/> Add slide
              </Button>
            </div>
            : null
          }

          <h5 className="mt-3">Complete page</h5>
          <p>When the applicant has completed the questionnaire we display the text below.
            If your integration provides a url to redirect applicant to,
            we will redirect the applicant after answering the last question and <i>not</i> show this screen.
          </p>
          <Card>
            <CardBody>
              <Form>
                <FormGroup>
                  <Label for="inputCompleteTitle">Title</Label>
                  <Input
                    type="text"
                    name="inputCompleteTitle"
                    id="inputCompleteTitle"
                    value={completeTitle}
                    onChange={e => {
                      setCompleteTitle(e.target.value);
                    }}/>
                </FormGroup>
                <FormGroup>
                  <Label for="inputCompleteText">Text</Label>
                  <Input
                    type="textarea" name="text" id="inputCompleteText" rows="6" value={completeText}
                    onChange={e => {
                      setCompleteText(e.target.value);
                    }}/>
                </FormGroup>
              </Form>
            </CardBody>
          </Card>
        </div>}
    </CardBody>
    <CardFooter>

      <Button onClick={save} disabled={saving || !loadedCustomer} color="info">
        {saving ? "Saving..." : "Save"}
      </Button>
    </CardFooter>
  </Card>;
}

QuestionnaireTexts.propTypes = {
  selectedCustomer: PropTypes.number,
  customer: PropTypes.shape({
    trial: PropTypes.bool,
  }),
};

const mapStateToProps = state => {
  return {
    selectedCustomer: state.customer.selectedCustomer,
    customer: getCurrentCustomer(state.customer),
  };
};

export default connect(mapStateToProps)(QuestionnaireTexts);