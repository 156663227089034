import React, {useState} from "react";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
} from "reactstrap";
import {Form, FormGroup, FormText, Input, Label} from "reactstrap";
import {getToken, sendInvite} from "services/scoringAPIService";
import {connect, useSelector} from "react-redux";
import PropTypes from "prop-types";
import {currentCustomerHas, getCurrentCustomer, getValidLicences} from "services/customerService";
import "route/Customisation/css/CreateSession.scss";
import SessionCount from "components/SessionCount";
import {getJwtPayload} from "helpers/jwt";
import validator from "validator";
import PleasePurchase from "components/PleasePurchase";
import {CSpinner} from "@coreui/react";

function CreateSession(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [isSendByEmail, setIsSendByEmail] = useState(props.sendByEmail || false);
  const selectedCustomer = useSelector(state => state.customer.selectedCustomer);
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const [error, setError] = useState({isError: false, header: "", message: ""});
  const [info, setInfo] = useState("");
  const payload = getJwtPayload();

  let formRef = React.createRef();

  const toggleEmailLink = () => {
    setIsSendByEmail(!isSendByEmail);
  };

  const handleSubmit = async () => {
    if (validate()) {
      setIsLoading(true);
      setError({isError: false});
      setInfo("");
      let result = null;
      let applicantEmail = isSendByEmail ? email : payload.username;
      let applicantName = applicantEmail.split("@")[0].replace(/\W/g, "");
      applicantName = applicantName + "_" + (Math.floor(Math.random() * 26) + Date.now()).toString().substr(-5);

      if (isSendByEmail) {
        result = (await sendInvite(applicantName, selectedCustomer, email, message)).data;
        if (result.sent > 0) {
          setInfo("Sent an email to " + email);
          setEmail("");
          setMessage("");
        }
      } else {
        result = (await getToken(applicantName, selectedCustomer)).data;
        if (result.url) {
          window.open(result.url, "_blank");
        }
      }
      setIsLoading(false);
      if (result.error) {
        setError({isError: true, header: result.error, message: result.message});
      }
    }
  };

  const validate = () => {
    return formRef.current.reportValidity();
  };

  if (!props.customer) {
    return <CSpinner/>;
  }
  const validEmail = !email || validator.isEmail(email);
  const validLicences = getValidLicences(props.customer);

  return <Card>
    <CardHeader>
      {props.trial ?
        <h4>Test your customisation</h4>
        :
        <h4>{isSendByEmail ? "Invite a colleague" : "Try it yourself"}</h4>
      }
      <SessionCount licences={validLicences}/>
    </CardHeader>
    {validLicences.length === 0 ?
      <CardBody><PleasePurchase/></CardBody>
      :
      <CardBody>
        <p>This page lets you create a session, so that you can test the questionnaire.
          Click the button to start the questionnaire in a new tab or check the box below to email the link to the
          questionnaire to a colleague.</p>

        {error && error.isError ? <Alert color="danger">Something went wrong:<br/>
          <strong>{error.header}</strong><br/>
          {error.message}
        </Alert> : null}
        {info ? <Alert color="success">{info}</Alert> : null}

        <Form onSubmit={(event) => event.preventDefault()} innerRef={formRef}>
          <FormGroup check onClick={toggleEmailLink} className="toggle-email">
            <Input type="checkbox" name="sendEmail" onChange={() => {}} id="sendEmail" checked={isSendByEmail}/>
            <Label for="message">Send an invitation by email</Label>
          </FormGroup>

          {isSendByEmail ?
            <div>
              <FormGroup>
                <Label for="email">Email</Label>
                <Input
                  type="email" name="email" id="email" value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  onBlur={validate} required valid={!!email && !!validEmail} invalid={!validEmail}
                  placeholder="the email address that will receive the link"/>
                <FormText>This is the recipient address. We will send the invite but not store this address.</FormText>
              </FormGroup>
              <FormGroup>
                <Label for="message">
                  Add a message to explain why you are sending this invite
                </Label>
                <Input
                  type="textarea" name="message" id="message"
                  onChange={(e) => setMessage(e.target.value)}
                  value={message}
                  placeholder="a personal note we will add to the email."
                />
              </FormGroup>
              <Button color="info" className="float-md-right" disabled={isLoading} onClick={handleSubmit}>
                Send by email
              </Button>
            </div>
            :
            <div>
              <Button color="info" className="float-md-right" disabled={isLoading} onClick={handleSubmit}>
                {isLoading ? <CSpinner/> : null}
                Start in new tab
              </Button>
            </div>
          }
        </Form>
      </CardBody>
    }
  </Card>;
}

CreateSession.propTypes = {
  selectedCustomer: PropTypes.number,
  customer: PropTypes.shape({
    licences: PropTypes.array,
  }),
  sendByEmail: PropTypes.bool,
  trial: PropTypes.bool,
};

const mapStateToProps = state => {
  return {
    selectedCustomer: state.customer.selectedCustomer,
    customer: getCurrentCustomer(state.customer),
    trial: currentCustomerHas(state.customer, "trial"),
  };
};

export default connect(mapStateToProps)(CreateSession);