import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {
  getSelectedCustomer,
  getSelectedCustomersFromQueryString,
  loadCustomers,
} from "services/customerService";

const fetchCustomers = createAsyncThunk("customers/fetchCustomers", async () => {
  return (await loadCustomers()).data;
});

const customerSlice = createSlice({
  name: "customer",
  initialState: {
    isCustomersLoading: false,
    customers: [],
    trial: false,
    selectedCustomers: [],
    selectedCustomer: null,
  },
  reducers: {
    setSelectedCustomers(state, action) {
      if (action.payload.length > 0) {
        state.selectedCustomer = action.payload[0];
      }
      state.selectedCustomers = action.payload;
    },
    updateCustomers(state, action) {
      if (action.payload.length > 0) {
        state.selectedCustomer = action.payload[0];
      }
      state.customers = action.payload;
    }
  },
  extraReducers: {
    [fetchCustomers.pending]: (state) => {
      state.isCustomersLoading = true;
    },
    [fetchCustomers.fulfilled]: (state, action) => {
      state.customers = action.payload;
      if (action.payload.length > 0) {
        state.selectedCustomer = getSelectedCustomer() || action.payload[0].id;
        state.selectedCustomers = getSelectedCustomersFromQueryString() || action.payload.map((customer) => customer.id);
      }
      state.isCustomersLoading = false;
    },
    [fetchCustomers.rejected]: (state) => {
      state.isCustomersLoading = false;
    },
  }
});

export const {setSelectedCustomers, updateCustomers} = customerSlice.actions;

export default customerSlice.reducer;

export const fetchCustomersAsync = () => (dispatch) => {
  dispatch(fetchCustomers());
};
