import $apiWithToken from "http";
import {buildQueryString} from "./general";

export default class ApplicantService {
  static async loadPlatformData(applicantExternalId, customers) {
    const customer = customers[0];
    const data = {
      "applicant": applicantExternalId,
      customers,
      customer
    };

    return await $apiWithToken.get(`/api/v1/applicant/platform/data?${buildQueryString(data)}`);
  }

  static async loadAnswerTimeData(applicantExternalId) {
    return await $apiWithToken.get(`/api/v1/applicant/timePerQuestion/data?${buildQueryString({"applicant": applicantExternalId})}`);
  }

  static async getList() {
    return await $apiWithToken.get("/api/v1/applicant/list");
  }

  static async getOpenBankingByApplicant(applicant) {
    return await $apiWithToken.get(`/api/v1/open-banking/${applicant}/list`);
  }

  static async deleteOpenBanking(id) {
    return await $apiWithToken.delete(`/api/v1/open-banking/${id}/delete`);
  }

  static async updateStatus(applicantId, status) {
    return await $apiWithToken.patch("/api/v1/applicant/status", {applicant: applicantId, status: status});
  }

  static async updateArrears(applicantId, arrears) {
    return await $apiWithToken.patch("/api/v1/applicant/arrears", {applicant: applicantId, arrears: arrears});
  }
}