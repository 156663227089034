import React, {useEffect, useState} from "react";
import Spinner from "components/Spinner";
import {getCurrentCustomer, shouldBuy} from "services/customerService";
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {Alert, Button, Table} from "reactstrap";
import QuestionnaireConfigForm from "components/QuestionnaireConfigForm";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleLeft, faSpinner} from "@fortawesome/free-solid-svg-icons";
import Currency from "./Products/Currency";
import {checkVAT, countries} from "jsvat";
import {addToCart} from "store/cartSlice";
import {CSpinner} from "@coreui/react";
import {loadProducts} from "services/productService";
import {setOfferProduct, setProducts} from "store/productSlice";
import {SLUG_MY_CREDIT_ROBO} from "services/config";
import {useNavigate} from "react-router-dom";

const Purchase = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const customer = useSelector(state => getCurrentCustomer(state.customer));
  const products = useSelector(state => state.product.products);
  const cartError = useSelector(state => state.cart.error);
  const [processing, setProcessing] = useState(false);
  const [productOptions, setProductOptions] = useState({language: null, version: null});
  const [vatNumber, setVatNumber] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!loading && products.length === 0) {
      setLoading(true);

      loadProducts().then(({data}) => {
        dispatch(setProducts(data));

        const myCreditRobo = products.filter((product) => product.slug && product.slug === SLUG_MY_CREDIT_ROBO).shift();
        dispatch(setOfferProduct(myCreditRobo.id));
      });
    }
  }, [props, loading, setLoading]);

  const goBack = () => {
    if (typeof props.setStep === "function") {
      props.setStep();
    }
    navigate("/choose-subscription/");
  };

  const submitHandler = async event => {
    event.stopPropagation();
    setProcessing(true);
    productList.map(product => dispatch(addToCart({id: product.id, options: productOptions})));
    await props.checkout();
    setProcessing(false);
  };

  if (!customer || !products) {
    return <CSpinner/>;
  }
  // eslint-disable-next-line
  // noinspection EqualityComparisonWithCoercionJS
  const productList = products.filter(product => product.slug === "setup")
    .concat(
      products.filter(product => props.productId === product.id)
        .concat(
          products.filter(product => product.slug === "vat" && !checkVAT(vatNumber, countries).isValid),
        ),
    );

  if (!productOptions.language && customer.defaultLanguage) {
    setProductOptions({language: customer.defaultLanguage, version: customer.defaultVersion});
    setVatNumber(customer.vatNumber !== null ? customer.vatNumber : "");
  }

  // Check the need to buy a subscription
  if (!shouldBuy(customer)) {
    return <p>You have a valid subscription. Thank you.</p>;
  }

  if (processing) {
    return <p className="text-muted"> We are processing your order ... <Spinner size={1}/></p>;
  }

  return <div className="pt-3">
    <Alert color="danger" isOpen={cartError.length > 0}>
      <strong>Error:</strong> {cartError}
    </Alert>
    <strong>You are ordering:</strong>
    <Table style={{margin: "2em auto"}}>
      <tbody>
        {
          productList.map(product =>
            <tr key={product.id}>
              <th>{product.title}</th>
              <td><Currency amount={product.amount}/></td>
              <td>{product.subscription ? "Recurring monthly, cancel anytime." : product.content}</td>
            </tr>,
          )
        }
      </tbody>
    </Table>
    <QuestionnaireConfigForm
      options={productOptions} vatNumber={vatNumber} changeHandler={setProductOptions}
      setVatNumber={setVatNumber}/>
    <Button color="link" disabled={processing} onClick={goBack} className="float-md-left">
      <FontAwesomeIcon icon={faAngleLeft} className="pt-1"/> Back
    </Button>
    <Button color="info" disabled={processing} onClick={submitHandler} className="float-md-right">
      {processing ?
        <FontAwesomeIcon fixedWidth={true} icon={faSpinner} spin={true} className="ml-2 mr-2"/> :
        <span>Yes, proceed to payment</span>
      }
    </Button>
  </div>;
};

Purchase.propTypes = {
  checkout: PropTypes.func,
  history: PropTypes.object,
  productId: PropTypes.number.isRequired,
  setStep: PropTypes.func,
};

export default Purchase;
