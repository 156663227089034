import {buildQueryString} from "./general";
import {API_PURCHASES_PATH, API_SUBSCRIPTION_PATH, STRIPE_API_KEY} from "./config";
import $apiWithToken from "http";

export const RUNNING = "paid";
export const APPROVED = "approved";
export const CANCEL = "cancel";

export function makePurchase(customerId, products, vatNumber) {
  return $apiWithToken.post("/api/v1/payment/create", {customerId, products, vatNumber});
}

export function redirect(session_id) {
  // eslint-disable-next-line no-undef
  const stripe = Stripe(STRIPE_API_KEY);
  stripe.redirectToCheckout({
    sessionId: session_id
  }).then(function (result) {
    console.log(result);
    // If `redirectToCheckout` fails due to a browser or network
    // error, display the localized error message to your customer
    // using `result.error.message`.
  });
}

export async function cancelSubscription(customerId, subscriptionId) {
  return $apiWithToken.post("/api/v1/payment/unsubscription", {customerId, subscriptionId});
}

export async function loadPurchases(filters = {}) {
  return $apiWithToken.get(`${API_PURCHASES_PATH}?${buildQueryString(filters)}`);
}

export async function loadSubscriptions() {
  return $apiWithToken.get(API_SUBSCRIPTION_PATH);
}