import React, {useState} from "react";
import PropTypes from "prop-types";
import {useParams} from "react-router-dom";
import Stepper from "react-stepper-horizontal";
import StepOne from "components/SignUp/form/StepOne";
import StepTwo from "components/SignUp/form/StepTwo";
import logo from "image/logo_full.jpeg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendar, faCreditCard, faEnvelope, faTachometerAlt} from "@fortawesome/free-solid-svg-icons";
import ProductOffering from "route/ProductOffering";
import PurchaseRoute from "route/PurchaseRoute";
import PaymentResult from "route/PaymentResult";
import {sendEvent} from "helpers/tracking";
import {CCard, CCardBody, CCol, CContainer, CRow} from "@coreui/react";

function SignUp(props) {
  const [step, setStep] = useState(props.step || 0);
  const activeColor = "#66B767";
  const completeColor = "#A2D3E4";

  const steps = [
    {title: "Personal Info"},
    {title: "Confirm Email"},
    {title: "Set Password"},
    {title: "Choose subscription"},
    {title: "Checkout"},
  ];
  const {productId} = useParams();
  const stepPurchase = () => setStep(4);
  const stepSignUpSubmitted = () => setStep(1);

  const renderForm = (step) => {
    sendEvent("sign-up", "show-step-" + step, "myCreditRobo");
    switch (step) {
    case 2:
      return <StepTwo {...props}/>;
    case 3:
      return <ProductOffering setStep={stepPurchase}/>;
    case 4:
      return <PurchaseRoute productId={parseInt(productId)}/>;
    case 5:
      return <PaymentResult success={1} {...props} />;
    case 6:
      return <PaymentResult success={0} {...props} />;
    case 0:
    case 1:
    default:
      return <StepOne setStep={stepSignUpSubmitted} {...props} />;
    }
  };

  return <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
    <CContainer>
      <CRow>
        <CCard className="p-4 col-md-9">
          <div className="pb-md-4">
            <Stepper
              activeStep={step}
              steps={steps}
              activeColor={activeColor}
              completeColor={completeColor}
              circleFontSize={16}
              titleFontSize={14}/>
          </div>
          <div className="form-page adaptive-content">
            {renderForm(step)}
          </div>
        </CCard>
        <CCard className="p-4 col-md-3">
          <CCardBody>
            <div className="align-items-center" style={{"marginBottom": "30px"}}>
              <img src={logo} alt="AdviceRobo logo" style={{maxWidth: "100%"}}/>
            </div>
            {step <= 4 ?
              <div>
                <CRow>
                  <CCol md={1}><FontAwesomeIcon className="nav-icon" icon={faCalendar} color="green" size="lg"/></CCol>
                  <CCol><p>30 days for free.</p></CCol>
                </CRow>
                <CRow>
                  <CCol md={1}><FontAwesomeIcon className="nav-icon" icon={faEnvelope} color="green" size="lg"/></CCol>
                  <CCol><p>Send 20 free invites to friends and colleagues.</p></CCol>
                </CRow>
                <CRow>
                  <CCol md={1}>
                    <FontAwesomeIcon className="nav-icon" icon={faTachometerAlt} color="green" size="lg"/>
                  </CCol>
                  <CCol><p>See their scores in the dashboard.</p></CCol>
                </CRow>
                <CRow>
                  <CCol md={1}>
                    <FontAwesomeIcon className="nav-icon" icon={faCreditCard} color="green" size="lg"/>
                  </CCol>
                  <CCol><p>No credit card required for free trial.</p></CCol>
                </CRow>
              </div>
              : null
            }
          </CCardBody>
        </CCard>
      </CRow>
    </CContainer>
  </div>;
}

SignUp.propTypes = {
  step: PropTypes.number,
};
export default SignUp;