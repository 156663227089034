import React from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import {Container} from "reactstrap";
import Dashboard from "route/Dashboard";
import Documentation from "route/Documentation";
import Applicants from "route/Applicants";
import {QueryParamProvider} from "use-query-params";
import {ReactRouter6Adapter} from "use-query-params/adapters/react-router-6";
import Customisation from "route/Customisation/Customisation";
import QuestionnaireTexts from "route/Customisation/QuestionnaireTexts";
import QuestionnaireColors from "route/Customisation/QuestionnaireColors";
import CreateSession from "route/Customisation/CreateSession";
import {isAdmin} from "services/userService";
import CustomersOverview from "route/CustomersOverview";
import Settings from "route/Settings";
import InvoiceData from "route/InvoiceData";
import SubscriptionData from "route/SubscriptionData";
import FunnelMetrics from "route/FunnelMetrics";
import ScoreDistribution from "route/ScoreDistribution";
import {CookiesProvider} from "react-cookie";
import OpenBanking from "route/OpenBanking";
import RocCurve from "route/RocCurve";
// import FeatureImportance from "route/FeatureImportance";
import CustomerSettings from "route/CustomerSettings";

export default function MainContent() {
  return (
    <main className="main"> {/* Padding-top needed because we don"t use the breadcrumbs bar */}
      <Container fluid>
        <QueryParamProvider
          adapter={ReactRouter6Adapter}
        >
          <CookiesProvider>
            <Routes>
              <Route path="/" exact={true} name="Dashboard" element={<Navigate to={"/dashboard"}/>}/>
              <Route path="/dashboard" exact={true} name="Dashboard" element={<Dashboard/>}/>
              <Route path="/api-documentation" exact={true} name="Api documentation" element={<Documentation/>}/>
              <Route path="/applicants" exact={true} name="Applicants" element={<Applicants/>}/>
              <Route path="/questionnaire" exact={true} name="Customise" element={<Customisation/>}/>
              <Route
                path="/questionnaire/texts" exact={true} name="Questionnaire texts"
                element={<QuestionnaireTexts/>}/>
              <Route
                path="/questionnaire/colors" exact={true} name="Questionnaire colors"
                element={<QuestionnaireColors/>}/>
              <Route path="/questionnaire/create-session" exact={true} name="CreateSession" element={<CreateSession/>}/>
              <Route path="/statistic/funnel" exact={true} name="FunnelMetrics" element={<FunnelMetrics/>}/>
              <Route path="/statistic/score" exact={true} name="ScoreDistribution" element={<ScoreDistribution/>}/>
              <Route path="/customer/settings" exact={true} name="Settings" element={<CustomerSettings/>}/>
              {isAdmin()
                ?
                <>
                  <Route path="/admin/settings" exact={true} name="Settings" element={<Settings/>}/>
                  <Route path="/admin/invoices" exact={true} name="Settings" element={<InvoiceData/>}/>
                  <Route path="/admin/subscriptions" exact={true} name="Subscriptions" element={<SubscriptionData/>}/>
                  <Route path="/admin/customers" exact={true} name="Users" element={<CustomersOverview/>}/>
                  <Route path="/applicant/openbanking" exact={true} name="Demo open banking" element={<OpenBanking/>}/>
                  <Route path="/model-metrics/roc-curve" exact={true} name="ROC-curve" element={<RocCurve/>}/>
                  {/*<Route*/}
                  {/*  path="/model-metrics/feature-importance"*/}
                  {/*  exact={true}*/}
                  {/*  name="Feature importance"*/}
                  {/*  element={<FeatureImportance/>}*/}
                  {/*/>*/}
                </>
                : ""}
              {!isAdmin()
                ?
                <>
                  <Route path="/customer/invoices" exact={true} name="Settings" element={<InvoiceData/>}/>
                  <Route path="/customer/subscriptions" exact={true} name="Subscriptions" element={<SubscriptionData/>}/>
                </>
                : ""}
            </Routes>
          </CookiesProvider>
        </QueryParamProvider>
      </Container>
    </main>
  );
}