import React from "react";
import {CCard, CCardBody, CCardGroup, CCardHeader} from "@coreui/react";
import PasswordChangeForm from "components/PasswordChangeForm";

export default function Settings(props) {
  return (
    <CCardGroup>
      <CCard>
        <CCardHeader><h4>Settings</h4></CCardHeader>
        <CCardBody>
          <div>
            <PasswordChangeForm {...props} />
          </div>
        </CCardBody>
      </CCard>
    </CCardGroup>
  );
}
