import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";

export default function Spinner(props) {

  return (<span className="spinner" style={{fontSize: (props.size || 2) + "em"}}>
    <FontAwesomeIcon icon={faCog} spin={true} fixedWidth={true}/>
  </span>);
}

Spinner.propTypes = {
  size: PropTypes.number,
};