import React, {useEffect} from "react";
import MainContent from "components/MainContent";
import AppFooter from "components/AppFooter";
import AppSidebar from "components/AppSidebar";
import AppHeader from "components/AppHeader";
import {useDispatch, useSelector} from "react-redux";
import {fetchCustomersAsync} from "store/customerSlice";
import Loading from "components/Loading";

export default function Layout() {
  const dispatch = useDispatch();
  const customers = useSelector((state) => state.customer.customers);
  const isCustomersLoading = useSelector((state) => state.customer.isCustomersLoading);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  useEffect(() => {
    if (isAuthenticated && !isCustomersLoading) {
      dispatch(fetchCustomersAsync());
    }
  }, [dispatch]);

  if (isCustomersLoading || !customers.length) {
    return <Loading />;
  }

  return (
    <div>
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <AppHeader />
        <div className="body flex-grow-1 px-3">
          <MainContent />
        </div>
        <AppFooter />
      </div>
    </div>
  );
}