import React from "react";
import {CFormLabel, CFormSwitch} from "@coreui/react";
import {modifyKeyToLabel} from "services/general";
import PropTypes from "prop-types";

const BooleanField = ({id, field, checked, onFieldChanged}) => {
  const key = id + field;

  return (
    <>
      <CFormLabel htmlFor={key}>{modifyKeyToLabel(field)}</CFormLabel>
      <br/>
      <CFormSwitch id={key} checked={checked} name={field} onChange={(event) => {
        onFieldChanged(event.currentTarget.checked, event.currentTarget.name);
      }}/>
    </>
  );
};

BooleanField.propTypes = {
  id: PropTypes.number,
  field: PropTypes.string,
  type: PropTypes.string,
  checked: PropTypes.bool,
  onFieldChanged: PropTypes.func,
};

export default BooleanField;
