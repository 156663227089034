import React from "react";
import {Link} from "react-router-dom";
import {CCard, CCardBody, CCardHeader, CCol, CRow} from "@coreui/react";

function Customisation() {
  return <CRow>
    <CCol>
      <CCard>
        <CCardHeader>Change Text</CCardHeader>
        <CCardBody>
          <p>Change the text that applicants see when starting and completing the questionnaire.
            Or choose to ship the intro completely</p>
          <Link to="/questionnaire/texts" variant="info" block>Customise text</Link>
        </CCardBody>
      </CCard>
    </CCol>
    <CCol>
      <CCard>
        <CCardHeader>Change Colours</CCardHeader>
        <CCardBody>
          <p>Change the colours of the questionnaire to match your styling.</p>
          <Link to={"/questionnaire/colors"} variant="info" block>Customise colours</Link>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>;
}

export default Customisation;