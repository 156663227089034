export const defaultColors = {
  page:
          [
            {
              "category": "pageBg",
              "value": "#F85972",
            }
            ,
            {
              "category": "pageText",
              "value": "#FFF",
            }
            ,
            {
              "category": "pageBtnBg",
              "value": "#0000CC",
            }
            ,
            {
              "category": "pageBtnText",
              "value": "#FFF",
            }
            ,
          ],
  question: [
    {
      "category": "questionHeadBg",
      "value": "#0000CC",
    },

    {
      "category": "questionHeadText",
      "value": "#FFF",
    }
    ,
    {
      "category": "questionBtnBg",
      "value": "#F85972",
    }
    ,
    {
      "category": "questionBtnText",
      "value": "#FFF",
    }
    ,
    {
      "category": "questionAnswerActive",
      "value": "#0000CC",
    }, {
      "category": "questionAnswerActiveText",
      "value": "#FFF",
    },
    {
      "category": "questionAnswerHelper",
      "value": "#F85972",
    },
    {
      "category": "progress",
      "value": "#0000CC",
    },
    {
      "category": "loadBg",
      "value": "#010127",
    }
    ,
    {
      "category": "loadText",
      "value": "#FFF",
    },
  ],
}
;

export function getOrderedColors(colors) {
  if (!colors || !Array.isArray(colors) || colors === []) {
    return  defaultColors;
  }
  // Create color map based on structure of defaultColors.
  const orderedColors = {};
  Object.keys(defaultColors).forEach((groupName) => {
    orderedColors[groupName] = [];
    defaultColors[groupName].forEach((defaultColor) => {
      const color = colors.find(color => {
        return color.category === defaultColor.category;
      });
      orderedColors[groupName].push(color || defaultColor);
    });

  });
  return orderedColors;
}
