import "whatwg-fetch"; // polyfill for IE
import {getAuthorizationHeader} from "helpers/jwt";
import {API_SERVER} from "services/config";

export function fetchResourceAsJson(resourceUrl, filters = {}) {
  let urlObject = new URL(API_SERVER + resourceUrl + ".json");
  /** @type {object} value */
  for (const [name, value] of Object.entries(filters)) {
    if (value === null) {
      continue;
    }
    urlObject.searchParams.append(name, value);
  }

  return fetch(urlObject.toString(), {
    method: "GET",
    headers: {
      "Accept": "application/json",
      ...getAuthorizationHeader(),
    },
  });
}

export function redirectToLogin() {
  window.location.href = "/login";
}

export function getQueryFilterParams(filter, selectedCustomers) {
  const paramsObj = {};
  /** @type {object} value */
  for (const [name, value] of Object.entries(filter)) {
    if (value === null || value === undefined) {
      continue;
    }

    paramsObj[name] = value;
  }

  if (selectedCustomers && selectedCustomers.length > 0) {
    paramsObj["customer"] = selectedCustomers[0];
    paramsObj["customers"] = selectedCustomers;
  }

  return paramsObj;
}

export function filterValuesForFilename(filters) {
  let fileName = "";
  for (let key in filters) {
    if (filters.hasOwnProperty(key) && filters[key]) { // eslint-disable-line
      fileName += "_" + key + "_" + filters[key];
    }
  }

  return fileName;
}

export const buildQueryString = (params) => {
  const searchParams = new URLSearchParams();

  for (const paramIndex in params) {
    if (Array.isArray(params[paramIndex])) {
      params[paramIndex].forEach(value => searchParams.append(`${paramIndex}[]`, value));

      continue;
    }

    searchParams.append(`${paramIndex}`, params[paramIndex]);
  }

  return searchParams.toString();
};

export function arrayToQueryString(key, array) {
  if (!Array.isArray(array) || array.length === 0) {
    return "";
  }
  const queryString = array.map(item => `${key}[]=${encodeURIComponent(item)}`).join("&");

  return queryString;
}

export const modifyKeyToLabel = ($value) => {
  const modifiedStr = $value.replace(/_/g, " ");

  return modifiedStr.charAt(0).toUpperCase() + modifiedStr.slice(1);
};

export const convertString = (input) => {
  // Split the string by underscore
  let words = input.split("_");

  // Capitalize the first letter of the first word and make the rest of the word lowercase
  words[0] = words[0].charAt(0).toUpperCase() + words[0].slice(1).toLowerCase();

  // Join the first word with the rest of the words in lowercase
  let result = words[0] + " " + words.slice(1).join(" ").toLowerCase();

  return result;
};

export const isBool = (value) => {
  return typeof value === "boolean" || value instanceof Boolean;
};


export const prepareItemsForTable = (items) => {
  const data = [];

  for (let key in items) {
    let value = items[key];
    if (Object.prototype.toString.call(value) === "[object Object]") {
      for (let subKey in value) {
        let subValue = value[subKey];
        if (Array.isArray(subValue)) {
          subValue = subValue.length === 0 ? "-" : subValue.join(", ");
        }

        subValue = isBool(subValue) ? (subValue ? "Yes" : "No") : String(subValue);

        data.push(
          {label: convertString(subKey), info: subValue},
        );
      }

      continue;
    }

    if (Array.isArray(value)) {
      value = value.length === 0 ? "-" : value.join(", ");
    }

    data.push(
      {label: convertString(key), info: isBool(value) ? (value ? "Yes" : "No") : String(value)},
    );
  }

  return data;
};
