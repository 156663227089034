import React, {useState} from "react";
import "react-tabs/style/react-tabs.css";
import ApplicantService from "services/applicantService";
import PropTypes from "prop-types";
import RiskFeaturesExpanded from "components/Applicant/RiskFeaturesExpanded";
import DataTable from "react-data-table-component";
import {Button} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashAlt} from "@fortawesome/free-solid-svg-icons";

const ApplicantScoreExpandedOB = ({openBankingData}) => {
  const [openBanking, setOpenBanking] = useState(openBankingData);

  const performOpenBankingDelete = (openBankingId) => {
    if (!window.confirm("Are you sure you want to remove this record?")) {
      return;
    }

    ApplicantService.deleteOpenBanking(openBankingId).then(({data}) => {
      if (data.success) {
        setOpenBanking(
          openBanking.filter(openBankingData => openBankingData.id !== openBankingId),
        );
      }
    });
  };

  const openBankingColumns = () => {
    return [
      {
        name: "Name",
        sortable: true,
        selector: row => row.type,
      },
      {
        name: "Prediction",
        sortable: true,
        selector: row => row.prediction,
      },
      {
        name: "Created at",
        sortable: true,
        selector: row => row.created_at,
      },
      {
        id: "delete",
        name: "Delete",
        selector: row => {
          return <Button color="danger" onClick={() => performOpenBankingDelete(row.id)}>
            <FontAwesomeIcon color="#fff" icon={faTrashAlt} fixedWidth/>
          </Button>;
        },
      },
    ];
  };

  return (
    <DataTable
      columns={[...openBankingColumns()]}
      progressPending={!openBanking}
      title="Open banking data"
      data={openBanking}
      expandableRows
      expandableRowsComponent={RiskFeaturesExpanded}
    />
  );
};

ApplicantScoreExpandedOB.propTypes = {
  openBankingData: PropTypes.object,
};

export default ApplicantScoreExpandedOB;
