import React, {useState} from "react";
import PropTypes from "prop-types";
import {SketchPicker} from "react-color";

import "./ColorPicker.scss";
import {isAdmin} from "services/userService";

export default function ColorPicker(props) {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [colorValue, setColorValue] = useState(props.color.value);

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
    props.handleChange(props.color.category, colorValue);
  };

  const handleChange = async (color) => {
    if (color.rgb.a && color.rgb.a !== 1) {
      color.hex += Math.round(color.rgb.a * 255).toString(16);
    }
    setColorValue(color.hex);
  };

  const titleProp = isAdmin() ? {title: props.title} : {};

  return <div className="color-picker" {...titleProp}>
    <div className="swatch" onClick={handleClick}>
      <div style={{background: `${colorValue}`}} className="color"/>
    </div>
    {displayColorPicker ? <div className="popover">
      <div className="cover" onClick={handleClose}/>
      <SketchPicker color={colorValue} onChange={handleChange}/>
    </div> : null}
  </div>;

}

ColorPicker.propTypes = {
  color: PropTypes.shape({
    category: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  title: PropTypes.string,
};