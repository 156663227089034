import React from "react";
import {Button, Card, CardBody, CardFooter, CardHeader, Col} from "reactstrap";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const IntroductionCard = (props) => {
  return <Col xs="12" sm="6" lg={props.colSize || 3 }>
    <Card>
      <CardHeader><h3>{props.title}</h3></CardHeader>
      <CardBody className="intro-body">{props.body || props.children}</CardBody>
      <CardFooter>
        <Link to={props.target}>
          <Button color={props.color} size="lg" block>
            <FontAwesomeIcon className="nav-icon" icon={props.icon}/>&nbsp;{props.caption}
          </Button>
        </Link>
      </CardFooter>
    </Card>
  </Col>;

};

IntroductionCard.propTypes = {
  body: PropTypes.string,
  caption: PropTypes.string,
  children: PropTypes.any,
  color: PropTypes.string,
  icon: PropTypes.object,
  colSize: PropTypes.number,
  target: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default IntroductionCard;
