import ReactGA from "react-ga";

export const initializeReactGA = () => {
  ReactGA.initialize(
    process.env.REACT_APP_GA_MEASUREMENT_ID || "UA-102048029-2",
    {
      debug: typeof process.env.REACT_APP_GA_DEBUG === "undefined" ? true : !!process.env.REACT_APP_GA_DEBUG,
      titleCase: false,
      testMode: false, // set to when developing to disable GA.
      // cookieDomain: "none",
    },
  );
};

export const pageView = () => ReactGA.pageview(window.location.pathname + window.location.search);
export const sendEvent = (category, action, label) => ReactGA.event({category, action, label});