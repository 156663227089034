import React from "react";
import DataFilter from "components/DataFilter";
import ApplicantDataTable from "components/Applicant/ApplicantDataTable";

function Applicants() {
  return (
    <div>
      <DataFilter />
      <ApplicantDataTable />
    </div>
  );
}

export default Applicants;
