import React from "react";
import PropTypes from "prop-types";
import Currency from "./Products/Currency";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";

const ProductFeatures = (props) => {
  // @todo: move this to a config:
  const isTrialProduct = (product) => product.slug === "trial-credit-robo";
  const isEnterpriseProduct = (product) => product.slug === "enterprise-credit-robo";

  const getFeatureValue = (featureName, feature, product) => {
    // Exceptions:
    if (featureName === "customiseColors" || featureName === "customiseCopy") {
      product[featureName] = !isTrialProduct(product);
    }
    if (featureName === "onPremisesHosting") {
      product[featureName] = isEnterpriseProduct(product);
    }
    if (featureName === "setup" && isTrialProduct(product)) {
      product[featureName] = "-";
    }

    switch (feature.type) {
    case "currency":
      if (isEnterpriseProduct(product)) { // Another exception.
        return <a href="https://advicerobo.com/contact/">contact us</a>;
      }
      return <Currency amount={product[featureName] || feature.default || "-"}/>;
    case "int":
      if (isEnterpriseProduct(product)) {
        return "upon request";
      }
      return product[featureName] | "-";
    case "bool":
    default:
      if (product[featureName] !== false && (product[featureName] || feature.default)) {
        return <FontAwesomeIcon className="nav-icon" color="green" icon={faCheck}/>;
      }
      return "_";
    }
  };

  return (Object.keys(props.features).map((featureName) => (
    <tr key={featureName}>
      <th className="feature">{props.features[featureName].label}</th>
      {
        props.products.map(
          (product) =>
            <td key={product.id} className="feature-value">
              {getFeatureValue(featureName, props.features[featureName], {...product})}
            </td>
        )
      }
    </tr>
  )));
};

ProductFeatures.propTypes = {
  features: PropTypes.shape({
    type: PropTypes.string,
    label: PropTypes.string,
    default: PropTypes.any,
  }),
  products: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.string,
      default: PropTypes.string,
      id: PropTypes.number,
      title: PropTypes.string,
    }),
  ),
};

export default ProductFeatures;