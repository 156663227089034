import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Button, Card, CardBody, CardHeader} from "reactstrap";
import {getScoreDistribution} from "services/statistics";
import DataFilter from "components/DataFilter";
import {Bar} from "react-chartjs-2";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileDownload} from "@fortawesome/free-solid-svg-icons";
import {isAdmin} from "services/userService";
import {setResultCount} from "store/filterSlice";
import {CSpinner} from "@coreui/react";
import {getQueryFilterParams} from "../services/general";

function ScoreDistribution() {
  const dispatch = useDispatch();
  const filter = useSelector(state => state.filter.filters);
  const [loading, setLoading] = useState(true);
  const [parameters, setParameters] = useState({});
  const [scoreDistributionData, setScoreDistributionData] = useState({
    labels: [],
    datasets: [],
  });

  useEffect(() => {
    if (filter.customers) {
      setLoading(true);
      const data = getQueryFilterParams(
        filter,
        filter.customers
      );

      setParameters(data);
      getScoreDistribution(data).then(({data}) => updateScoreDistributionChart(data));
    }
  }, [filter]);

  const scoreDistributionChartOptions = {
    maintainAspectRatio: true,
    scales: {
      y: {
        ticks: {
          beginAtZero: true,
          precision: 0
        }
      },
      x: {
        barPercentage: 0.7
      }
    },
    tooltips: {
      callbacks: {
        title: function (tooltipItem) {
          return "Scores " + tooltipItem[0].label;
        }
      }
    }
  };

  function updateScoreDistributionChart(json) {
    if (!json) {
      return;
    }
    setLoading(false);
    dispatch(setResultCount({sessions: json.count?.sessions, applicants: json.count?.applicants})); // eslint-disable-line

    let dataSet = {
      label: "Number of scores",
      backgroundColor: "#1b8eb7",
      borderColor: "#1985ac",
      borderWidth: 1,
      data: Object.values(json?.data),
      fill: false,
    };
    setScoreDistributionData({
      ...scoreDistributionData,
      labels: Object.values(json?.labels || {}),
      datasets: [dataSet],
    });
  }

  function downloadData() {
    // TODO: ARPCS-283 Point to CSV export url instead of json dump
    getScoreDistribution(parameters, true);
  }

  return (
    <div>
      <DataFilter />
      <Card className="mb-3">
        <CardHeader>
          Score distribution
          <div className="card-header-actions">{
            isAdmin() ?
              <Button color="link" className="card-header-action" onClick={downloadData}>
                <FontAwesomeIcon icon={faFileDownload}/> Download
              </Button>
              : null
          }</div>
        </CardHeader>
        <CardBody>
          {loading ? <CSpinner/> :
            <div style={{maxWidth: "800px", margin: "auto"}}>
              <Bar data={scoreDistributionData} options={scoreDistributionChartOptions}/>
            </div>
          }
        </CardBody>
      </Card>
    </div>
  );
}

export default ScoreDistribution;