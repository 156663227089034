import React, {useState} from "react";
import {useParams} from "react-router-dom";
import PropTypes from "prop-types";
import Purchase from "components/Purchase";
import {useDispatch, useSelector} from "react-redux";
import {makePurchase, redirect} from "services/purchaseService";
import ProductOffering from "./ProductOffering";
import {setError, setPurchase} from "store/cartSlice";

const PurchaseRoute = () => {
  const dispatch = useDispatch();
  const [isConfirmed, setIsConfirmed] = useState(false);
  const sessionId = useSelector(state => state.cart.purchase.sessionId);
  const selectedCustomer = useSelector(state => state.customer.selectedCustomer);
  const cartProducts = useSelector(state => state.cart.products);
  const cartVatNumber = useSelector(state => state.cart.vatNumber);

  const handleCheckout = async () => {
    makePurchase(selectedCustomer, cartProducts, cartVatNumber).then(({data}) => {
      dispatch(setPurchase(data));

      setIsConfirmed(true);
    }).catch((error) => {
      dispatch(setError(error.response.data));

      setIsConfirmed(true);
    });
  };

  const {productId} = useParams();
  if (!productId) {
    return <ProductOffering/>;
  }

  if (isConfirmed && sessionId !== null) {
    redirect(sessionId);
  }

  return <Purchase productId={parseInt(productId)} checkout={handleCheckout}/>;
};

PurchaseRoute.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      productId: PropTypes.string.isRequired,
    }),
  }),
};

export default PurchaseRoute;
