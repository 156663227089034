import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import {getCurrentCustomer, getCustomerApiKeys} from "services/customerService";
import {connect} from "react-redux";
import {Card, CardHeader, CardBody, Col, Label, Row} from "reactstrap";
import ApiKeyShowHide from "./ApiKeyShowHide";
import PleasePurchase from "./PleasePurchase";
import {CSpinner} from "@coreui/react";

function ApiKeys(props) {
  const [apiKeys, setApiKeys] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (props.customer && props.customer.trial) {
      setLoading(false);
    }

    if (props.selectedCustomers && props.selectedCustomers.length > 0) {
      setLoading(true);

      getCustomerApiKeys(props.selectedCustomers).then(({data}) => {
        setApiKeys(data);
        setLoading(!apiKeys);
      });
    }
  }, [props.customer, props.selectedCustomers]);

  return <Card className={"mb-3"}>
    <CardHeader><h4>Your API Keys</h4></CardHeader>
    <CardBody>
      {
        loading ? <CSpinner/> :
          props.customer.trial ?
            <PleasePurchase/> :
            apiKeys.map((apiKey) =>
              <Row key={apiKey.id}>
                <Col md="6">
                  <Label className='control-label'>{apiKey.name}</Label>
                </Col>
                <Col>
                  <ApiKeyShowHide apiKey={apiKey.value}/>
                </Col>
              </Row>,
            )
      }
    </CardBody>
  </Card>;
}

ApiKeys.propTypes = {
  apiKeys: PropTypes.array,
  selectedCustomer: PropTypes.number,
  selectedCustomers: PropTypes.array,
  customer: PropTypes.shape({
    apiKeys: PropTypes.array.isRequired,
    trial: PropTypes.bool,
  }),
};

const mapStateToProps = state => {
  return {
    selectedCustomer: state.customer.selectedCustomer,
    selectedCustomers: state.customer.selectedCustomers,
    customer: getCurrentCustomer(state.customer),
  };
};

export default connect(mapStateToProps)(ApiKeys);
