import React from "react";
import PropTypes from "prop-types";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import Layout from "./layout/Layout";
import Login from "./layout/Login";
import ProtectedRoute from "route/ProtectedRoute";
import RequestPasswordReset from "./layout/RequestPasswordReset";
import NotFoundPage from "./NotFoundPage";
import SignUp from "./layout/SignUp";

const LoginRouter = (props) => {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route exact path="/login" name="Login Page" element={
            <Login {...props} />
          }/>
          <Route exact path="/logout" name="Logout Page" element={
            <Navigate to={"/login"} replace={true}/>
          }/>

          <Route exact path="/signup" name="Sign up" element={<SignUp step={0}/>}/>
          <Route
            exact path="/get-started/:token" name="First Password Set Page"
            element={<SignUp step={2} username={""}/>}/>
          <Route
            exact path="/choose-subscription" name="Choose your subscription"
            element={<SignUp step={3} username={""}/>}/>
          <Route exact path="/pricing" name="Pricing overview" element={<SignUp step={3} username={""}/>}/>
          <Route
            exact path="/payment" name="Confirm and proceed to checkout"
            element={<SignUp step={4} username={""}/>}/>
          <Route exact path="/order/success" name="Payment is success" element={<SignUp step={5} username={""}/>}/>
          <Route exact path="/order/cancel" name="Payment is cancel" element={<SignUp step={6} username={""}/>}/>

          <Route exact={true} path="/subscribe/:productId" name="Confirm and proceed to checkout" element={
            <ProtectedRoute>
              <SignUp step={4} username={""}/>
            </ProtectedRoute>
          }/>

          <Route
            exact path="/forgot-password" name="Request Password Reset Page"
            element={<RequestPasswordReset username={""}/>}/>
          <Route
            exact path="/reset-password/:token" name="Password Reset Page"
            element={<RequestPasswordReset username={""}/>}/>

          <Route path="*" name="Home" element={
            <ProtectedRoute>
              <Layout/>
            </ProtectedRoute>
          }/>
          <Route path="*" element={<NotFoundPage/>}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
};

LoginRouter.propTypes = {
  loadCustomers: PropTypes.func,
  location: PropTypes.string,
};

export default LoginRouter;