import React from "react";
import PropTypes from "prop-types";

import {Col, Label, Row} from "reactstrap";
import ColorPicker from "./ColorPicker";

export default function ColorPickerRow(props) {
  const category = props.color.category;

  return <Row>
    <Col md="8">
      <Label className='control-label'>
        {props.label}
        {hints[category] ? <small id="emailHelp" className="form-text text-muted">{hints[category]}</small> : null}
      </Label>
    </Col>
    <Col md="4">
      <ColorPicker color={props.color} title={category} handleChange={props.handleChange}/>
    </Col>
  </Row>;
}

ColorPickerRow.propTypes = {
  color: PropTypes.shape({
    category: PropTypes.string.isRequired,
  }).isRequired,
  handleChange: PropTypes.func,
  label: PropTypes.string.isRequired,
};



const hints = {
  questionAnswerActive: "To see this color select an answer in the preview frame",
  questionAnswerActiveText: "To see this color select an answer in the preview frame",
  questionBtnBg: "To see this color select an answer in the preview frame",
  questionBtnText: "To see this color select an answer in the preview frame",
};