import React from "react";
import "react-tabs/style/react-tabs.css";
import PropTypes from "prop-types";
import {CTable} from "@coreui/react";

const ApplicantScoreExpandedTime = ({items}) => {
  const getAnswerTimeDataColumns = () => {
    return [
      {key: "question", label: "Question"},
      {key: "answer", label: "Answer"},
      {key: "duration", label: "Duration"},
      {key: "step", label: "Step"},
    ];
  };

  return (
    <CTable
      items={items}
      columns={getAnswerTimeDataColumns()}
      hover
      striped
      bordered
    />
  );
};

ApplicantScoreExpandedTime.propTypes = {
  items: PropTypes.array,
};

export default ApplicantScoreExpandedTime;
