import React, {useState} from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {Alert, Button, Progress} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import * as zxcvbn from "zxcvbn";
import PasswordMask from "react-password-mask";
import {UserService} from "services/userService";
import {CFormText, CInputGroup, CInputGroupText} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import {cilLockLocked} from "@coreui/icons";

const PasswordResetForm = (props) => {
  const IS_CLEAN = "clean";
  const IS_PENDING = "pending";
  const IS_ERROR = "error";
  const IS_COMPLETED = "completed";
  const [step, setStep] = useState(IS_CLEAN);
  const [password, setPassword] = useState("");
  const [alert, setAlert] = useState("");
  const [score, setScore] = useState(0);
  const strengths = ["Very Weak", "Weak", "Good", "Strong", "Very Strong"];
  const colors = ["danger", "danger", "warning", "success", "success"];
  const progress = [25,25,50,75,100];

  let formRef = React.createRef();

  const handleChange = (e) => {
    let password = e.target.value;
    let minLength = e.target.minLength;
    const result = zxcvbn(password);
    setScore(result.score);
    setAlert("");
    setStep(IS_CLEAN);
    if (password.length < minLength) {
      setAlert("A password length cannot be smaller than " + minLength.toString());
      setStep(IS_ERROR);
    }
    else if (result.score < 2) {
      setAlert(result.feedback.warning || "The password strength is weak");
      setStep(IS_ERROR);
    }
    setPassword(password);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formRef.current.reportValidity()) {
      setStep(IS_PENDING);
      setAlert("");

      UserService.passwordResetWithSignIn(
        props.token,
        password,
        () => {
          setStep(IS_COMPLETED);
        },
        ({response}) => {
          setStep(IS_ERROR);
          setAlert(response.data.message);
        },
      );
    } else {
      setStep(IS_ERROR);
    }
  };

  return (
    <form name="form" onSubmit={handleSubmit} ref={formRef}>
      <h1>{props.isWelcomePage ? "Set a password" : "A new password"}</h1>
      {step === IS_COMPLETED ?
        <div><p>Your password has been changed. <br/>Please use your new password the next time you login.</p>
          <p>If this is the first time you set your password, we will send you an email with instructions on how to use MyCreditRobo.</p>
          <Button color="info" href="/choose-subscription" className="px-4">Get Started</Button>
        </div>
        :
        <div>
          {props.isWelcomePage ?
            <p className="text-muted">To complete your registration, please fill in a password below.</p> :
            <p className="text-muted">You would like to (re)set your password<br/>
                    Enter your new password in the form below</p>
          }
          <p className="text-muted">The password should be at least 8 characters long.</p>

          <Alert color="danger" isOpen={step === IS_ERROR} toggle={() => {
            setStep(IS_CLEAN);
          }}>
            <strong>Error:</strong> {alert}
          </Alert>

          <CInputGroup className="mb-2">
            <CInputGroupText>
              <CIcon icon={cilLockLocked} />
            </CInputGroupText>
            <PasswordMask placeholder="Password" autoComplete="new-password"
              minLength={8} maxLength={4096}
              className="form-control" name="password"
              inputStyles={{border: "none"}}
              buttonStyles={{marginTop: "-14px", right: "2px"}}
              value={password} onChange={handleChange}/>
          </CInputGroup>
          <Progress className="mb-2" style={{height: "25px"}} color={colors[score]} value={password.length >= 8 ? progress[score] : 0}>{password.length >= 8 ? strengths[score] : ""}</Progress>
          {step === IS_PENDING && !password &&
            <CFormText>Password is required</CFormText>
          }

          <div className="row">
            <div className="col-6">
              <Button color="info" className="px-4" disabled={password === "" || alert !== "" || step === IS_PENDING}>
                {step === IS_PENDING ?
                  <FontAwesomeIcon fixedWidth={true} icon={faSpinner} spin={true} className="ml-2 mr-2"/> :
                  <span>Set password</span>
                }
              </Button>
            </div>
            {props.isWelcomePage ? null :
              <div className="text-right col-6 pt-2">
                <Link to="/login" tooltip={"Login instead"}>Login</Link>
              </div>
            }
          </div>
        </div>
      }
    </form>
  );
};

PasswordResetForm.propTypes = {
  token: PropTypes.string.isRequired,
  isWelcomePage: PropTypes.bool,
  passwordReset: PropTypes.func,
};

export default PasswordResetForm;
