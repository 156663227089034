import {
  clearJwtToken,
  getJwtPayload,
  getJwtToken,
  setJwtToken,
} from "helpers/jwt";
import {API_SERVER} from "./config";
// import {clearFilters} from "actions/filterAction";
import {clearSelectedCustomer} from "./customerService";
import $apiWithToken, {$api} from "http/index";

function hasRole(roleName) {
  const payload = getJwtPayload();
  if (payload && Array.isArray(payload.roles)) {
    return payload.roles.includes(roleName);
  }

  return false;
}

export function login(username, password) {
  const requestOptions = {
    method: "POST",
    headers: {"Content-Type": "application/json"},
    body: JSON.stringify({username, password}),
  };

  return fetch(`${API_SERVER}/api/login_check`, requestOptions).then(token => {
    setJwtToken(token);
    return token;
  });
}

export function refreshToken() {
  const requestOptions = {
    method: "POST",
    headers: {"Content-Type": "application/json"},
    body: JSON.stringify({refresh_token: getJwtToken().refresh_token}),
  };
  return fetch(`${API_SERVER}/api/token/refresh`, requestOptions).then(token => {
    setJwtToken(token);
    return token;
  });
}

export function logout() {
  clearJwtToken();
  // clearFilters();
  clearSelectedCustomer();
}

export function isAdmin() {
  return hasRole("ROLE_ADMIN");
}

export function isSales() {
  return hasRole("ROLE_SALES");
}

export function isCustomer() {
  return hasRole("ROLE_CUSTOMER");
}

export function isTrial() {
  return hasRole("ROLE_TRIAL");
}

export function isAtLeastCustomer() {
  return hasRole("ROLE_CUSTOMER") || hasRole("ROLE_ADMIN");

}

export async function requestPasswordReset(username, onAuthenticateSuccess, onAuthenticateFail) {
  return $apiWithToken.post("/user/reset/request-token", {username})
    .then((data) => {
      onAuthenticateSuccess(data);
    })
    .catch((error) => {
      onAuthenticateFail(error);
    });
}

export function passwordReset(token, password) {
  const requestOptions = {
    method: "POST",
    headers: {"Content-Type": "application/json"},
    body: JSON.stringify({token, password}),
  };
  return fetch(`${API_SERVER}/user/reset/password`, requestOptions).then(token => {
    setJwtToken(token);
    return token;
  });
}

export async function passwordChange(currentPassword, newPassword) {
  return $apiWithToken.post("/api/user/change/password", {currentPassword, newPassword});
}

export function getAvailableLanguages() {
  return $api.get("/user/available-languages");
}

export function signUp(companyName, email, language, name, phone, website) {
  return $api.post("/user/signup", {companyName, email, language, name, phone, website});
}

export class UserService {
  static async requestPasswordReset(username, onSuccess, onFail) {
    return await $api.post("/user/reset/request-token", {username})
      .then((data) => onSuccess(data))
      .catch((error) => onFail(error));
  }

  static async passwordResetWithSignIn(token, password, onSuccess, onFail) {
    return await $api.post("/user/reset/password", {token, password})
      .then((data) => onSuccess(data))
      .catch((error) => onFail(error));
  }

  static async login(username, password, onSuccess, onFail) {
    return await $api.post("/api/login_check", {username, password})
      .then((data) => onSuccess(data))
      .catch((error) => onFail(error));
  }

  static async getCustomerSettings() {
    return await $apiWithToken.get("/api/v1/customer/settings");
  }
}