import {createSlice} from "@reduxjs/toolkit";

const productSlice = createSlice({
  name: "product",
  initialState: {
    offerProduct: null,
    products: [],
  },
  reducers: {
    setOfferProduct(state, action) {
      state.offerProduct = action.payload;
    },

    setProducts(state, action) {
      state.products = action.payload;
    }
  }
});

export const {setOfferProduct, setProducts} = productSlice.actions;

export default productSlice.reducer;
