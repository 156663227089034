import React from "react";
import {Button, Col, Row} from "reactstrap";
import PropTypes from "prop-types";
import {faEdit, faPalette, faTable, faTimes} from "@fortawesome/free-solid-svg-icons";
import IntroductionCard from "./IntroductionCard";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "./Introduction.scss";

const IntroductionBuy = (props) => {
  const close = event => {
    event.stopPropagation();
    if (props.close) {
      props.close();
    }
  };

  return <div className="introduction">
    <Row>
      <Col><p>&nbsp;</p></Col>
      <Col xs="1" sm="1" lg="1">
        <Button outline size="sm" title="Hide introduction steps" onClick={close} >
          <FontAwesomeIcon icon={faTimes}/>
        </Button>
      </Col>
    </Row>
    <Row className="introduction-buy">
      <IntroductionCard icon={faPalette} caption="Customise colors" color="warning" colSize={4} target="/questionnaire/colors" title="Style it">
        Change the colors to match your style guide.
      </IntroductionCard>
      <IntroductionCard icon={faEdit} caption="Customise text" color="success" colSize={4} target="/questionnaire/texts" title="Write it">
        Change the introduction and completion text.
      </IntroductionCard>
      <IntroductionCard icon={faTable} caption="Make it" color="info" colSize={4} target="/api-documentation" title="Integrate it">
        Integrate MyCreditRobo and its API as part of your onboarding application process. You may need to get you web developer involved.
      </IntroductionCard>
    </Row>
    <hr/>
  </div>;
};

IntroductionBuy.propTypes = {
  close: PropTypes.func,
};

export default IntroductionBuy;
