import React from "react";
import PropTypes from "prop-types";
import copy from "copy-to-clipboard";

/**
 * This is https://www.npmjs.com/package/react-copy-to-clipboard with a tweak or two
 * It did not work as a npm package, it does work now.
 */
export class CopyToClipboard extends React.PureComponent {
  static propTypes = { // eslint-disable-line
    text: PropTypes.string.isRequired,
    children: PropTypes.element.isRequired,
    onCopy: PropTypes.func,
    options: PropTypes.shape({
      debug: PropTypes.bool,
      message: PropTypes.string
    })
  };

  static defaultProps = {
    onCopy: undefined,
    options: undefined
  };


  onClick = event => {
    const {
      text,
      onCopy,
      children,
      options
    } = this.props;

    const elem = React.Children.only(children);

    const result = copy(text, options);

    if (onCopy) {
      onCopy(text, result);
    }

    // Bypass onClick if it was present
    if (elem && elem.props && typeof elem.props.onClick === "function") {
      elem.props.onClick(event);
    }
  };

  render() {
    const {
      text: _text, // eslint-disable-line
      onCopy: _onCopy, // eslint-disable-line
      options: _options, // eslint-disable-line
      children,
      ...props
    } = this.props;
    const elem = React.Children.only(children);

    return React.cloneElement(elem, {...props, onClick: this.onClick});
  }
}
