import {createSlice} from "@reduxjs/toolkit";
import moment from "moment";

export const initialState = {
  filters: {
    startDate: moment().startOf("month").format("YYYY-MM-DD"),
    endDate: null,
    version: null,
    language: null,
    isTest: null,
    selfService: null,
    customers: null
  },
  resultCount: {
    sessions: null,
    applicants: null,
  },
};

const filterSlice = createSlice({
  name: "filter",
  initialState: initialState,
  reducers: {
    setFilter(state, action) {
      state.filters = {...state.filters, ...action.payload};
    },
    clearFilter(state) {
      state = initialState; // eslint-disable-line
    },
    setResultCount(state, action) {
      state.resultCount = action.payload;
    },
    clearResultCount(state) {
      state.resultCount = initialState.resultCount;
    }
  }
});

export const {setFilter, clearFilter, setResultCount, clearResultCount} = filterSlice.actions;

export default filterSlice.reducer;
