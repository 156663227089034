export const getCutOffSetting = (settings) => {
  const cutoff = settings.filter((setting) => {
    return setting["name"] === "score_cutoff";
  });

  if (cutoff.length > 0) {
    return parseInt(cutoff[0].value);
  }

  return 500;
};

export const getCustomerSetting = (customerId, settings) => {
  return settings.filter((setting) => {
    return setting["customer_id"] === customerId;
  });
};