import React, {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRocket, faUsers} from "@fortawesome/free-solid-svg-icons";
import {Tooltip} from "reactstrap";
import {faFlag} from "@fortawesome/free-regular-svg-icons";
import PropTypes from "prop-types";

const ApplicantTooltipItem = ({item}) => {
  const [isTooFastTooltipOpen, setIsTooFastTooltipOpen] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleTooFast = () => setIsTooFastTooltipOpen(!isTooFastTooltipOpen);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <>
      <span className="text-center">
        {
          item.isTooFast ?
            <>
              <FontAwesomeIcon id={"alertTooltip-too-fast-" + item.id} icon={faRocket} color="black"/>
              <Tooltip
                placement="left"
                target={"alertTooltip-too-fast-" + item.id}
                isOpen={isTooFastTooltipOpen}
                toggle={toggleTooFast}
              >
                {
                  item.isTooFast ?
                    <p>
                      <FontAwesomeIcon icon={faFlag} color="red"/> This applicant filled in the questionnaire too fast
                    </p>
                    : ""
                }
              </Tooltip>
            </>
            : ""
        }
      </span>
      <span className="text-center">
        {
          item.isTeamWork ?
            <>
              <FontAwesomeIcon id={"alertTooltip-" + item.id} icon={faUsers} color="black"/>
              <Tooltip
                placement="left"
                target={"alertTooltip-" + item.id}
                isOpen={tooltipOpen}
                toggle={toggle}
              >
                {
                  item.isTeamWork ?
                    <p>
                      <FontAwesomeIcon icon={faFlag} color="red"/> This applicant filled out the questionnaire as a
                      team
                    </p>
                    : ""
                }
              </Tooltip>
            </>
            : ""
        }
      </span>
    </>
  );
};

ApplicantTooltipItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    isTeamWork: PropTypes.bool,
    isTooFast: PropTypes.bool,
  }),
};

export default ApplicantTooltipItem;