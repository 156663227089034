import {CCard, CCardBody, CFormSelect} from "@coreui/react";
import DataTable from "react-data-table-component";
import ApplicantScoreExpanded from "components/Applicant/ApplicantScoreExpanded";
import React, {useEffect, useState} from "react";
import ScoreTooltipItem from "components/Applicant/ScoreTooltipItem";
import ApplicantService from "services/applicantService";
import ApplicantTooltipItem from "components/Applicant/ApplicantTooltipItem";
import {isAdmin} from "services/userService";
import {getCustomerSetting, getCutOffSetting} from "helpers/settings";
import ApplicantExport from "components/Applicant/ApplicantExport";
import {
  getBrowserDataAsCSV,
  getProgressDataAsCSV, getScoreData,
  getScoreDataAsCSV,
  getTimePerQuestionAsCSV
} from "services/statistics";
import {getQueryFilterParams, modifyKeyToLabel} from "services/general";
import {useDispatch, useSelector} from "react-redux";
import {setResultCount} from "store/filterSlice";

const ApplicantDataTable = () => {
  const dispatch = useDispatch();
  const filter = useSelector(state => state.filter.filters);

  const [expandedRows, setExpandedRows] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [paymentStatus, setPaymentStatus] = useState([]);
  const [applicantData, setApplicantData] = useState([]);
  const [applicantInfo, setApplicantInfo] = useState(null);
  const [applicantCount, setApplicantCount] = useState(0);
  const [isApplicantDataLoading, setIsApplicantDataLoading] = useState(false);

  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(10);

  const [orderColumn, setOrderColumn] = useState("id");
  const [ordering, setOrdering] = useState("desc");

  const initialScoreData = {count: {applicants: "0", sessions: "0",}, data: [], info: null};
  const exportTypes = {
    "score": getScoreDataAsCSV,
    "time": getTimePerQuestionAsCSV,
    "footprint": getBrowserDataAsCSV,
    "progress": getProgressDataAsCSV,
  };
  const highlightRowStyle = {
    borderTop: "2px solid #20A8D8",
    borderLeft: "2px solid #20A8D8",
    borderRight: "2px solid #20A8D8",
  };

  useEffect(() => {
    if (filter.customers) {
      setIsApplicantDataLoading(true);
      const data = getQueryFilterParams(
        {
          ...filter,
          page,
          limit: rows,
          orderColumn,
          ordering
        },
        filter.customers
      );

      getScoreData(data)
        .then(({data}) => updateScoreData(data))
        .catch(() => updateScoreData(initialScoreData))
        .finally(() => setIsApplicantDataLoading(false));
    }
  }, [filter, page, rows, orderColumn, ordering]);

  useEffect(() => {
    if (applicantData.length > 0 && applicantInfo) {
      const arrearsList = [];
      const arrears = applicantInfo.arrears;
      for (let i = 0; i < arrears.length; i++) {
        arrearsList.push({
          value: arrears[i],
          label: modifyKeyToLabel(arrears[i])
        });
      }
      setPaymentStatus(arrearsList);

      const statusList = [];
      const statuses = applicantInfo["status"];
      for (let i = 0; i < statuses.length; i++) {
        statusList.push({
          value: statuses[i],
          label: modifyKeyToLabel(statuses[i])
        });
      }
      setStatuses(statusList);
    }
  }, [applicantData, applicantInfo]);

  const updateScoreData = (response) => {
    setApplicantData(response.data);
    setApplicantInfo(response.info);
    setApplicantCount(parseInt(response.count.applicants));

    dispatch(
      setResultCount(
        {
          sessions: parseInt(response.count.sessions),
          applicants: parseInt(response.count.applicants)
        }
      )
    );
  };

  const applicantColumns = () => {
    let columns = [
      {
        id: "applicantExternalId",
        name: "Applicant Id",
        selector: row => row.applicantExternalId,
        sortable: true,
        maxWidth: 150,
      },
      {
        id: "postedAt",
        name: "Time",
        sortable: true,
        selector: row => row.postedAt.date,
        maxWidth: 100,
      },
      {
        id: "questionnaireVersion",
        name: "Version",
        selector: row => row.questionnaireVersion,
        sortable: true,
        maxWidth: 80,
      },
      {
        id: "questionnaireLanguage",
        name: "Language",
        selector: row => row.questionnaireLanguage,
        sortable: true,
        maxWidth: 80,
      },
      {
        id: "score",
        name: "Score",
        selector: row => {
          const settings = getCustomerSetting(row.customerId, applicantInfo.customer_settings);
          const cutoffSettingValue = getCutOffSetting(settings);
          const isPositive = row.score > cutoffSettingValue;
          let color = "#dc3545";
          if (isPositive) {
            color = "#2eb85c";
          }

          return (
            <ScoreTooltipItem
              id={row.id}
              isPositive={isPositive}
              style={{
                background: color,
                padding: "5px",
                borderRadius: "5px",
                color: "#fff",
                textAlign: "center",
              }}
              score={row.score}
            />
          );
        },
        className: "right",
        sortable: false,
      },
      {
        id: "age",
        name: "Age",
        selector: row => row.age,
        className: "right",
        sortable: false,
      },
      {
        id: "status",
        name: "Status",
        cell: (row) => {
          const [value, setValue] = useState(row.status);
          return <CFormSelect
            style={{
              border: "none"
            }}
            value={value}
            size="sm"
            onChange={(changeEvent) => {
              const selectedValue = changeEvent.nativeEvent.target.value;
              if (confirm("Are you sure you want to change an status?")) {
                ApplicantService.updateStatus(row.id, selectedValue).then(() => {
                  setValue(selectedValue);
                });
              }
            }}
            options={statuses}
          />;
        },
        className: "right",
        sortable: true,
      },
      {
        id: "paymentStatus",
        name: "Payment status",
        cell: (row) => {
          const [value, setValue] = useState(row.arrears);

          return <CFormSelect
            style={{
              border: "none"
            }}
            value={value}
            size="sm"
            onChange={(changeEvent) => {
              const selectedValue = changeEvent.nativeEvent.target.value;
              if (confirm("Are you sure you want to change an arrears?")) {
                ApplicantService.updateArrears(row.id, selectedValue).then(() => {
                  setValue(selectedValue);
                });
              }
            }}
            options={paymentStatus}
          />;
        },
        className: "right",
        sortable: true,
      },
      {
        id: "alert",
        name: "Alert",
        selector: row => (row.isTooFast || row.isTeamWork) ?
          <ApplicantTooltipItem item={row}/>
          : "",
        maxWidth: 80,
        className: "center",
      }
    ];
    // Add database id as first column for admins only.
    if (isAdmin()) {
      columns.unshift(
        {
          id: "id",
          name: "AR Id",
          selector: row => row.id,
          sortable: true,
          maxWidth: 50,
        },
      );
    }

    return columns;
  };

  const actionsMemo = React.useMemo(
    () => <ApplicantExport onExport={(params, type) => exportTypes[type](params)}/>,
    []
  );

  const handleRowExpand = (expanded, row) => {
    const newExpandedRows = !expanded
      ? expandedRows.filter((id) => id !== row.id)
      : [...expandedRows, row.id];

    setExpandedRows(newExpandedRows);
  };

  const handleSort = (column, sortDirection) => {
    setOrderColumn(column.id);
    setOrdering(sortDirection);
  };

  return (
    <CCard>
      <CCardBody>
        <div className={"data-content clearfix"}>
          {
            applicantData && applicantInfo
              ?
              <DataTable
                columns={applicantColumns()}
                progressPending={isApplicantDataLoading || !applicantData}
                title="Applicants Data"
                actions={actionsMemo}
                data={applicantData}
                expandableRowsComponentProps={{info: applicantInfo}}
                expandableRows
                onRowExpandToggled={handleRowExpand}
                pagination
                paginationTotalRows={applicantCount}
                onChangeRowsPerPage={(rows) => setRows(rows)}
                onChangePage={(page) => setPage(page)}
                sortServer
                onSort={handleSort}
                paginationServer
                conditionalRowStyles={[
                  {
                    when: (row) => expandedRows.includes(row.id),
                    style: highlightRowStyle,
                  },
                ]}
                dense
                expandableRowsComponent={ApplicantScoreExpanded}
              />
              :
              <DataTable
                progressPending={true}
                title="Applicants Data"
                actions={actionsMemo}
                columns={[]}
                data={[]}
              />
          }
        </div>
      </CCardBody>
    </CCard>
  );
};

export default ApplicantDataTable;