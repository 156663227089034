import React, {useEffect, useState} from "react";
import {CButton, CCard, CCardBody, CFormInput, CSpinner} from "@coreui/react";
import DataTable from "react-data-table-component";
import {uploadOpenBanking} from "services/scoringAPIService";
import RiskFeaturesExpanded from "components/Applicant/RiskFeaturesExpanded";
import Select from "react-select";
import ApplicantService from "services/applicantService";

function OpenBanking() {
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState();
  const [predictions, setPredictions] = useState([]);
  const [selectedApplicant, setSelectedApplicant] = useState(null);
  const [applicants, setApplicants] = useState([]);
  const [applicantsLoading, setApplicantsLoadings] = useState(false);

  useEffect(() => {
    setApplicantsLoadings(true);
    ApplicantService.getList().then(({data}) => {
      const options = data.map(item => {
        return {
          value: item.id,
          label: item.applicantExternalId
        };
      });

      setApplicants(options);
      setApplicantsLoadings(false);
    }).catch(() => {
      setApplicantsLoadings(true);
    });
  }, []);

  const handleSelectChange = (selectedOption) => {
    setSelectedApplicant(selectedOption);
  };
  const handleUploadClick = (e) => {
    e.preventDefault();

    if (!file || !selectedApplicant) {
      return;
    }

    setLoading(true);
    uploadOpenBanking(file, selectedApplicant.value).then(({data}) => {
      if (data.success) {
        let openBankingData = [];
        for (let opIndex in data.data) {
          openBankingData.push({
            "applicant": selectedApplicant.label,
            "filename": file.name,
            "prediction": data.data[opIndex].prediction,
            "type": data.data[opIndex].type,
            "risk_features": data.data[opIndex].risk_features,
            "categorization_metrics": data.data[opIndex].categorization_metrics
          });
        }

        setPredictions([
          ...predictions,
          ...openBankingData
        ]);
      }

      setLoading(false);
    }).catch(() => {
      setLoading(false);
    });
  };

  const onFileChanged = (e) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const columns = () => {
    let columns = [
      {
        name: "Applicant",
        sortable: true,
        selector: row => row.applicant,
      },
      {
        name: "Uploaded file",
        sortable: true,
        selector: row => row.filename,
      },
      {
        name: "Name",
        sortable: true,
        selector: row => row.type,
      },
      {
        name: "Prediction",
        sortable: true,
        selector: row => row.prediction,
      },
    ];

    return columns;
  };

  return <CCard>
    <CCardBody>
      <CFormInput
        onChange={onFileChanged}
        type="file"
        label="Attach your file"
        text="Must have csv extention."
      />
      <Select
        className="mb-3 mt-3"
        isDisabled={applicantsLoading === true || applicants.length === 0}
        isLoading={applicantsLoading}
        value={selectedApplicant}
        onChange={handleSelectChange}
        options={applicants}
        isSearchable={true}
        placeholder="Select applicant to assign!"
      />
      <CButton
        disabled={loading || !selectedApplicant || !file}
        type="button"
        color="info"
        onClick={handleUploadClick}
      >
        {loading ? <CSpinner className="mx-1" size="sm"/> : ""}
        <span>Proceed a file</span>
      </CButton>
    </CCardBody>

    <CCardBody>
      <div className={"data-content clearfix"}>
        <DataTable
          columns={[...columns()]}
          progressPending={!predictions}
          title="Prediction Data"
          data={predictions}
          expandableRows
          expandableRowsComponent={RiskFeaturesExpanded}
        />
      </div>
    </CCardBody>
  </CCard>;
}

export default OpenBanking;
