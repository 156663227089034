import React, {useState} from "react";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCopy, faEye, faEyeSlash} from "@fortawesome/free-regular-svg-icons";
import {Button, Col, Row} from "reactstrap";
import {CopyToClipboard} from "./CopyToClipboard";

export default function ApiKeyShowHide(props) {
  const [hidden, setHidden] = useState(true);
  const apiKey = props.apiKey;

  const [copied, setCopied] = useState(false);

  const show = () => {
    setHidden(false);
  };
  const hide = () => {
    setHidden(true);
  };

  const mask = (apiKey) => {
    apiKey = apiKey.toString();
    return apiKey.substr(0, 3) + "#################" + apiKey.substr(-2);
  };

  return (
    <Row>
      <Col><strong className={apiKey}>{hidden ? mask(apiKey) : apiKey}</strong></Col>
      <Col md="1">
        <Button onMouseDown={show} onMouseUp={hide} size="sm">{hidden ?
          <FontAwesomeIcon className="nav-icon" icon={faEye}/> :
          <FontAwesomeIcon className="nav-icon" icon={faEyeSlash}/>
        }</Button>
      </Col>
      <Col md="1">
        <CopyToClipboard text={apiKey} onCopy={() => setCopied(true)}>
          <Button size="sm">
            <FontAwesomeIcon className="nav-icon" icon={faCopy} style={copied ? {color: "#aaa"} : ""}/>
          </Button>
        </CopyToClipboard>
      </Col>
      <Col md="1"/>
    </Row>
  );

}

ApiKeyShowHide.propTypes = {
  apiKey: PropTypes.string.isRequired,
};
