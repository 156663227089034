import React from "react";
import PropTypes from "prop-types";
import logo from "image/logo_full.jpeg";
import {CardBody} from "reactstrap";
import RequestPasswordResetForm from "components/RequestPasswordResetForm";
import PasswordResetForm from "components/PasswordResetForm";
import {useParams} from "react-router-dom";
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CRow
} from "@coreui/react";

const RequestPasswordReset = (props) => {
  const {token} = useParams();

  function getPasswordResetForm() {
    if (token) {
      return <PasswordResetForm {...props} token={token}/>;
    }
    return <RequestPasswordResetForm {...props}/>;
  }

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={10}>
            <CCardGroup>
              <CCard className="text-white py-5" style={{width: "44%"}}>
                <CCardBody className="text-center justify-content-center d-flex flex-column">
                  <div className="p-4">
                    <img src={logo} alt="AdviceRobo logo" style={{maxWidth: "100%"}}/>
                  </div>
                </CCardBody>
              </CCard>
              <CCard className="p-4">
                <CardBody>
                  {getPasswordResetForm()}
                </CardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

RequestPasswordReset.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      token: PropTypes.string
    })
  }),
  requestPasswordReset: PropTypes.func,
  username: PropTypes.string,
  token: PropTypes.string,
  isWelcomePage: PropTypes.bool,
};

export default RequestPasswordReset;