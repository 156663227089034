import React from "react";
import { useSelector, useDispatch } from "react-redux";

import {CImage, CSidebar, CSidebarBrand, CSidebarNav, CSidebarToggler} from "@coreui/react";
import { AppSidebarNav } from "./AppSidebarNav";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";

// sidebar nav config
import navigation from "_nav";
import logo from "image/logo_small.jpeg";
import icon from "image/icon.jpeg";
import {setSidebarShow, setUnfoldable} from "store/contentSlice";

const AppSidebar = () => {
  const dispatch = useDispatch();
  const unfoldable = useSelector((state) => state.content.sidebarUnfoldable);
  const sidebarShow = useSelector((state) => state.content.sidebarShow);

  return (
    <CSidebar
      position="fixed"
      unfoldable={unfoldable}
      visible={sidebarShow}
      onVisibleChange={(visible) => {
        dispatch(setSidebarShow(visible));
      }}
    >
      <CSidebarBrand className="d-none d-md-flex" to="/">
        <CImage className="sidebar-brand-full" src={logo} height={40} width={77} />
        <CImage className="sidebar-brand-narrow" src={icon} height={30} width={30} />
      </CSidebarBrand>
      <CSidebarNav>
        <SimpleBar>
          <AppSidebarNav items={navigation} />
        </SimpleBar>
      </CSidebarNav>
      <CSidebarToggler
        className="d-none d-lg-flex"
        onClick={() => dispatch(setUnfoldable(!unfoldable))}
      />
    </CSidebar>
  );
};

export default AppSidebar;
