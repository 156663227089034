export const version = "1.2.3";
export const project = "PCS API implementation guide";
export const copyright = "2023, AdviceRobo";
export const lastUpdated = "Oct 10, 2023";
export const implementationGuide = `<div class="section" id="advicerobo-pcs-api">
<h1>AdviceRobo PCS API<a class="headerlink" href="#advicerobo-pcs-api" title="Permalink to this headline">¶</a></h1>
<div class="toctree-wrapper compound">
</div>
<div class="section" id="introduction">
<h2>Introduction<a class="headerlink" href="#introduction" title="Permalink to this headline">¶</a></h2>
<p>This document describes how to use the AdviceRobo PCS API. This API provides access to all functionality
required to be able to implement the AdviceRobo PCS.</p>
<div class="section" id="terminology">
<h3>Terminology<a class="headerlink" href="#terminology" title="Permalink to this headline">¶</a></h3>
<dl class="docutils">
<dt>PCS</dt>
<dd>Psychographic Credit Score</dd>
<dt>API</dt>
<dd>Application Programming Interface. If the term is used alone here, it refers to “PCS API”.</dd>
<dt>Lender</dt>
<dd>A customer of AdviceRobo. Generally a bank or other financial institution.</dd>
<dt>Applicant</dt>
<dd>A person applying to the Lender for a loan or similar.</dd>
<dt>Plugin</dt>
<dd>Used here to mean the HTML and Javascript snippet required to display a questionnaire.</dd>
</dl>
</div>
</div>
<div class="section" id="overview">
<h2>Overview<a class="headerlink" href="#overview" title="Permalink to this headline">¶</a></h2>
<p>AdviceRobo provides a questionnaire that can be used as part of the
Lender’s process to evaluate an applicant’s credit worthiness. With
little effort the questionnaire can be integrated into the Lender’s
website. The plugin must be used in conjunction with the PCS API. By
using the API we can avoid placing any personal information about the
applicant in a web page. The API also ensures a secure exchange of the
applicant’s score between AdviceRobo and the Lender.</p>
<div class="section" id="flow">
<h3>Flow<a class="headerlink" href="#flow" title="Permalink to this headline">¶</a></h3>
<p>Before presenting the applicant with the questionnaire, the lender must call
the API to create a token. This token will be used in the
plugin and enables AdviceRobo to identify the lender and the applicant.
The token is meaningless to anyone but AdviceRobo.</p>
<p>The questionnaire can be integrated into the lender web site by adding a
html and javascript snippet to a web page. The snippet runs when the web page is loaded.
When executed it will retrieve and display a questionnaire. The snippet should therefore
be placed at the location on the page where the questionnaire should appear.</p>
<p>After the applicant has completed the questionnaire, the questionnaire will redirect
the applicant applicant to the following step of the lender’s on-boarding
process. At this point, the lender can request the credit score of the applicant with
and additional API call. As with retrieving the token, this happens outside the browser
environment, so the score cannot be intercepted or altered by the applicant or a third party.</p>
<p>In summary, in your on-boarding process the AdviceRobo PCS works as follows:</p>
<ol class="arabic simple">
<li>Request a security token for each applicant using the API (see <a class="reference internal" href="#create-token">Create token</a>).</li>
<li>Render the web page containing the DIV tag, Javascript snippet and
using the security token as the value of an attribute of the DIV tag (see <a class="reference internal" href="#sample-code">Sample code</a>).</li>
<li>When the applicant is finished, retrieve the applicant’s score using the API (see <a class="reference internal" href="#get-applicant-score">Get applicant score</a>).</li>
</ol>
<p>The sequence diagram below illustrates a typical flow, as described above.</p>
<p class="plantuml">
<img src="_images/plantuml-ec20602f2dda0c3ddfe630fd27da3fc8b277d2d2.png" alt="actor Applicant
participant &quot;Lender website&quot; as Lender
participant &quot;PCS widget&quot; as FrontEnd
participant &quot;PCS API&quot; as PCS

Applicant -&gt; Lender : Visits page
Lender -&gt; PCS: Create token
PCS-&gt;Lender: //Token//

Lender-&gt;FrontEnd: Embed questionnaire using token
FrontEnd-&gt;Lender: //Questionnaire widget//
Lender-&gt;Applicant: Show questionnaire

Applicant -&gt; FrontEnd: Applicant fills in questionnaire
FrontEnd -&gt; PCS: Save answers
FrontEnd -&gt; Applicant: Applicant is redirected to success url

...

Lender-&gt;PCS: Get applicant score
PCS-&gt;Lender: //Applicant score//"/>
</p>
</div>
</div>
<div class="section" id="integrating-the-questionnaire">
<h2>Integrating the questionnaire<a class="headerlink" href="#integrating-the-questionnaire" title="Permalink to this headline">¶</a></h2>
<div class="section" id="sample-code">
<h3>Sample code<a class="headerlink" href="#sample-code" title="Permalink to this headline">¶</a></h3>
<p>The following is a code snippet which can be placed on any page within the lender’s website.
The placeholder <code class="docutils literal notranslate"><span class="pre">{{</span> <span class="pre">token</span> <span class="pre">}}</span></code> must be replaced with the value of the token returned by the API.
More details on fetching a token can be found in the <a class="reference internal" href="#create-token">Create token</a> chapter.</p>
<div class="highlight-html notranslate"><div class="highlight"><pre><span class="nt">&lt;div</span> <span class="na">class=</span><span class="s">&quot;advicerobo-questionnaire&quot;</span> <span class="na">data-token=</span><span class="s">&quot;{{ token }}&quot;</span><span class="nt">&gt;&lt;/div&gt;</span>
<span class="nt">&lt;script </span><span class="na">type=</span><span class="s">&quot;text/javascript&quot;</span><span class="nt">&gt;</span>
   <span class="p">(</span><span class="kd">function</span><span class="p">(){</span>
   <span class="kd">var</span> <span class="nx">d</span><span class="o">=</span><span class="nb">document</span><span class="p">,</span><span class="nx">s</span><span class="o">=</span><span class="s1">&#39;script&#39;</span><span class="p">,</span><span class="nx">e</span><span class="o">=</span><span class="nx">d</span><span class="p">.</span><span class="nx">createElement</span><span class="p">(</span><span class="nx">s</span><span class="p">),</span><span class="nx">b</span><span class="o">=</span><span class="nx">d</span><span class="p">.</span><span class="nx">getElementsByTagName</span><span class="p">(</span><span class="nx">s</span><span class="p">)[</span><span class="mi">0</span><span class="p">];</span>
   <span class="nx">e</span><span class="p">.</span><span class="nx">src</span> <span class="o">=</span> <span class="s2">&quot;https://jacq.advicerobo.com/embed.js&quot;</span><span class="p">;</span><span class="nx">b</span><span class="p">.</span><span class="nx">parentNode</span><span class="p">.</span><span class="nx">insertBefore</span><span class="p">(</span><span class="nx">e</span><span class="p">,</span><span class="nx">b</span><span class="p">);</span>
   <span class="p">}());</span>
<span class="nt">&lt;/script&gt;</span>
</pre></div>
</div>
</div>
<div class="section" id="notes">
<h3>Notes<a class="headerlink" href="#notes" title="Permalink to this headline">¶</a></h3>
<ul class="simple">
<li>The snippet uses HTTPS. Messaging between the parent page and the
embedded snippet will be blocked by the browser unless the parent
page is also accessed via HTTPS.</li>
</ul>
</div>
</div>
<div class="section" id="api">
<h2>API<a class="headerlink" href="#api" title="Permalink to this headline">¶</a></h2>
<p>The base URL of the API is:</p>
<div class="highlight-default notranslate"><div class="highlight"><pre><span class="n">https</span><span class="p">:</span><span class="o">//</span><span class="n">pcs</span><span class="o">.</span><span class="n">advicerobo</span><span class="o">.</span><span class="n">com</span>
</pre></div>
</div>
<p>All communication with the API must use TLS. (TLS is the protocol that makes HTTPS secure.)</p>
<div class="section" id="authorization">
<h3>Authorization<a class="headerlink" href="#authorization" title="Permalink to this headline">¶</a></h3>
<p>To be able to request tokens and credit scores from the PCS API, the lender will need to provide an API key.
AdviceRobo issues each customer with a key. This key uniquely identifies the customer and
therefore should never be shared with others or made public.
Contact your AdviceRobo sales representative if you haven’t received your API key yet.</p>
<p>Separate API keys for the testing and production accounts can be issued to the lender on request.</p>
</div>
<div class="section" id="create-token">
<h3>Create token<a class="headerlink" href="#create-token" title="Permalink to this headline">¶</a></h3>
<dl class="post">
<dt>
<code class="descname">POST </code><code class="descname">/api/v1/token</code></dt>
<dd><p>Creates a token for use in the embeddable questionnaire. Note that the returned token is unique for a single
applicant. A new token needs to be created each time the questionnaire is displayed to a new applicant. For a
single applicant (without changes to any of the parameters), you can re-use the same token.</p>
<p>When requesting a token, the <code class="docutils literal notranslate"><span class="pre">key</span></code> and <code class="docutils literal notranslate"><span class="pre">applicant</span></code> parameters in the JSON object are required.
Other (optional) parameters are also available.</p>
<p>A json-encoded token string will be returned as response.</p>
<p><strong>Example request</strong>:</p>
<p>The minimal required requests is explained below. Additional data can be optionally added when required (see Request JSON Object).
Replace ‘myApiKey’ with your API key.</p>
<div class="highlight-http notranslate"><div class="highlight"><pre><span class="nf">POST</span> <span class="nn">/api/v1/token</span> <span class="kr">HTTP</span><span class="o">/</span><span class="m">1.1</span>
<span class="na">Host</span><span class="o">:</span> <span class="l">pcs.advicerobo.com</span>
<span class="na">Content-Type</span><span class="o">:</span> <span class="l">application/json; charset=UTF-8</span>

<span class="p">{</span>
  <span class="nt">&quot;key&quot;</span><span class="p">:</span> <span class="s2">&quot;myApiKey&quot;</span><span class="p">,</span>
  <span class="nt">&quot;applicant&quot;</span><span class="p">:</span> <span class="s2">&quot;applicant_123&quot;</span>
<span class="p">}</span>
</pre></div>
</div>
<p><strong>Example response</strong>:</p>
<div class="highlight-http notranslate"><div class="highlight"><pre><span class="kr">HTTP</span><span class="o">/</span><span class="m">1.1</span> <span class="m">200</span> <span class="ne">OK</span>
<span class="na">Content-Type</span><span class="o">:</span> <span class="l">application/json</span>

<span class="s2">&quot;aderkHiHD8CjvbUiW91cCKzEPtwctNC15sepO8TVvws%3D&quot;</span>
</pre></div>
</div>
<table class="docutils field-list" frame="void" rules="none">
<col class="field-name" />
<col class="field-body" />
<tbody valign="top">
<tr class="field-odd field"><th class="field-name" colspan="2">Request JSON Object:</th></tr>
<tr class="field-odd field"><td>&#160;</td><td class="field-body"><ul class="first simple">
<li><strong>key</strong> (<em>string,required</em>) – The lender’s unique API key. This should be the same in each request.</li>
<li><strong>applicant</strong> (<em>string,required</em>) – A reference the lender uses to uniquely identify a single applicant. If a Lender provides a
non-unique <code class="docutils literal notranslate"><span class="pre">applicant</span></code>, it will be unable to identify the applicant that has filled in the questionnaire.
In order to comply with privacy regulations, the value of <code class="docutils literal notranslate"><span class="pre">applicant</span></code> can not contain personal information
such as the name, date of birth or social security number of the applicant.</li>
<li><strong>url</strong> (<em>string,optional</em>) – The URL where the applicant gets redirected to after completion of a questionnaire.
This needs to be URL encoded.</li>
<li><strong>version</strong> (<em>string,optional</em>) – The questionnaire version. The version string(s) will be provided.</li>
<li><strong>language</strong> (<em>string,optional</em>) – The questionnaire display language. The language string(s) will be provided.</li>
<li><strong>meta</strong> (<em>object,optional</em>) – Additional meta information can be provided about the applicant in the form of key-value
properties, like the age of the applicant.
This is only needed when requested by AdviceRobo. Please never provide personal data, as this
might conflict with the GDPR regulations.</li>
</ul>
</td>
</tr>
<tr class="field-even field"><th class="field-name" colspan="2">Request Headers:</th></tr>
<tr class="field-even field"><td>&#160;</td><td class="field-body"><ul class="first simple">
<li><a class="reference external" href="https://tools.ietf.org/html/rfc7231#section-3.1.1.5">Content-Type</a> – This should always be <code class="docutils literal notranslate"><span class="pre">application/json</span></code>.</li>
</ul>
</td>
</tr>
<tr class="field-odd field"><th class="field-name" colspan="2">Response JSON Object:</th></tr>
<tr class="field-odd field"><td>&#160;</td><td class="field-body"><ul class="first simple">
<li><strong>_</strong> (<em>string</em>) – Json-encoded token string.</li>
</ul>
</td>
</tr>
<tr class="field-even field"><th class="field-name">Status Codes:</th><td class="field-body"><ul class="first last simple">
<li><a class="reference external" href="http://www.w3.org/Protocols/rfc2616/rfc2616-sec10.html#sec10.2.1">200 OK</a> – Requesting the token was successful.</li>
<li><a class="reference external" href="http://www.w3.org/Protocols/rfc2616/rfc2616-sec10.html#sec10.4.1">400 Bad Request</a> – Your request was invalid. A message explaining why will be returned. Check the data you’re providing.</li>
<li><a class="reference external" href="http://www.w3.org/Protocols/rfc2616/rfc2616-sec10.html#sec10.4.4">403 Forbidden</a> – Your API key is invalid or you don’t have an appropriate licence.</li>
</ul>
</td>
</tr>
</tbody>
</table>
<p><strong>A complete example request</strong>:</p>
<p>An example of a request that contains all the available parameters. Replace ‘myApiKey’ with your API key.</p>
<div class="highlight-http notranslate"><div class="highlight"><pre><span class="nf">POST</span> <span class="nn">/api/v1/token</span> <span class="kr">HTTP</span><span class="o">/</span><span class="m">1.1</span>
<span class="na">Host</span><span class="o">:</span> <span class="l">pcs.advicerobo.com</span>
<span class="na">Content-Type</span><span class="o">:</span> <span class="l">application/json; charset=UTF-8</span>

<span class="p">{</span>
  <span class="nt">&quot;key&quot;</span><span class="p">:</span> <span class="s2">&quot;myApiKey&quot;</span><span class="p">,</span>
  <span class="nt">&quot;applicant&quot;</span><span class="p">:</span> <span class="s2">&quot;applicant_123&quot;</span><span class="p">,</span>
  <span class="nt">&quot;url&quot;</span><span class="p">:</span> <span class="s2">&quot;https://www.mysite.com/continue&quot;</span><span class="p">,</span>
  <span class="nt">&quot;version&quot;</span><span class="p">:</span> <span class="s2">&quot;7.0.2&quot;</span><span class="p">,</span>
  <span class="nt">&quot;language&quot;</span><span class="p">:</span> <span class="s2">&quot;en&quot;</span><span class="p">,</span>
  <span class="nt">&quot;meta&quot;</span><span class="p">:</span> <span class="p">{</span>
      <span class="nt">&quot;age&quot;</span><span class="p">:</span> <span class="mi">31</span><span class="p">,</span>
      <span class="nt">&quot;myRiskCategory&quot;</span><span class="p">:</span> <span class="s2">&quot;B&quot;</span>
  <span class="p">}</span>
<span class="p">}</span>
</pre></div>
</div>
</dd></dl>

</div>
<div class="section" id="get-applicant-score">
<h3>Get applicant score<a class="headerlink" href="#get-applicant-score" title="Permalink to this headline">¶</a></h3>
<dl class="post">
<dt>
<code class="descname">POST </code><code class="descname">/api/v1/applicantScore</code></dt>
<dd><p>Retrieves a single applicant’s psychographic credit score, if it’s available. This request can only be performed
after an applicant has completed the questionnaire. Before that, the score won’t be available yet.</p>
<p>The JSON Request object should contain two properties <code class="docutils literal notranslate"><span class="pre">key</span></code> and <code class="docutils literal notranslate"><span class="pre">applicant</span></code>.
You can also pass an optional <code class="docutils literal notranslate"><span class="pre">version</span></code>. If the version is not given, your default version will be used.
This request object is very similar to that was used earlier to request a token.</p>
<p>This request returns a JSON structure containing the applicant’s overall score. The scores per category are included
as additional columns when the ‘sub scores’ premium feature is enabled.</p>
<p><strong>Example request</strong>:</p>
<p>Replace ‘myApiKey’ with your API key.</p>
<div class="highlight-http notranslate"><div class="highlight"><pre><span class="nf">POST</span> <span class="nn">/api/v1/applicantScore</span> <span class="kr">HTTP</span><span class="o">/</span><span class="m">1.1</span>
<span class="na">Host</span><span class="o">:</span> <span class="l">pcs.advicerobo.com</span>
<span class="na">Content-Type</span><span class="o">:</span> <span class="l">application/json; charset=UTF-8</span>

<span class="p">{</span>
  <span class="nt">&quot;key&quot;</span><span class="p">:</span> <span class="s2">&quot;myApiKey&quot;</span><span class="p">,</span>
  <span class="nt">&quot;applicant&quot;</span><span class="p">:</span> <span class="s2">&quot;applicant_123&quot;</span>
<span class="p">}</span>
</pre></div>
</div>
<p><strong>Example response</strong>:</p>
<div class="highlight-http notranslate"><div class="highlight"><pre>  HTTP/1.1 200 OK
  Content-Type: application/json; charset=UTF-8

  {
    &quot;score&quot;: {
      &quot;Results&quot;: {
        &quot;PCS&quot;: {
          &quot;type&quot;: &quot;table&quot;,
          &quot;value&quot;: {
            &quot;ColumnNames&quot;: [
              &quot;pcs&quot;
            ],
            &quot;ColumnTypes&quot;: [
              &quot;Double&quot;
            ],
            &quot;Values&quot;: [
              [
                0.2
              ]
            ]
          }
        }
      }
    }
  }

For versions 8.x, the output has been simplified.
The score object has the following fields:
    prediction: a boolean (1 or 0) prediction
    good: the positive score (1-1000)
    bad: the negative score (1-1000)
    flags: any alerts that could influence your decision.
</pre></div>
</div>
<div class="highlight-http notranslate"><div class="highlight"><pre>  HTTP/1.1 200 OK
  Content-Type: application/json; charset=UTF-8

{
    &quot;score&quot;: {
        &quot;prediction&quot;: 1,
        &quot;good&quot;: 750,
        &quot;bad&quot;: 250,
        &quot;flags&quot;: {
            &quot;isTooFast&quot;: false
        }
    },
    &quot;platform&quot;: &quot;... output like the request below ...&quot;,
    &quot;timing&quot;: &quot;... output like the request below ...&quot;
}
</pre></div>
</div>
<p><strong>Timing information (premium feature)</strong>
If the lender is able to view timing information, additional timing data will be available in the response.
This data will indicate when the applicant has started and finished the questionnaire, and the amount of time spent
(in seconds).</p>
<div class="highlight-http notranslate"><div class="highlight"><pre><span class="kr">HTTP</span><span class="o">/</span><span class="m">1.1</span> <span class="m">200</span> <span class="ne">OK</span>
<span class="na">Content-Type</span><span class="o">:</span> <span class="l">application/json; charset=UTF-8</span>

<span class="p">{</span>
  <span class="nt">&quot;score&quot;</span><span class="p">:</span> <span class="p">{</span>
    <span class="nt">&quot;... output like the request above ...&quot;</span>
  <span class="p">},</span>
  <span class="nt">&quot;timing&quot;</span><span class="p">:</span> <span class="p">{</span>
    <span class="nt">&quot;start&quot;</span><span class="p">:</span> <span class="s2">&quot;2018-12-20T09:17:21+01:00&quot;</span><span class="p">,</span>
    <span class="nt">&quot;finish&quot;</span><span class="p">:</span> <span class="s2">&quot;2018-12-20T09:18:18+01:00&quot;</span><span class="p">,</span>
    <span class="nt">&quot;total&quot;</span><span class="p">:</span> <span class="mi">57</span><span class="p">,</span>
    <span class="nt">&quot;steps&quot;</span><span class="p">:</span> <span class="p">{</span>
      <span class="nt">&quot;1&quot;</span><span class="p">:</span> <span class="mi">5</span><span class="p">,</span>
      <span class="nt">&quot;2&quot;</span><span class="p">:</span> <span class="mi">5</span><span class="p">,</span>
      <span class="nt">&quot;3&quot;</span><span class="p">:</span> <span class="mi">1</span><span class="p">,</span>
      <span class="nt">&quot; ... some steps omitted ...&quot;</span><span class="p">,</span>
      <span class="nt">&quot;25&quot;</span><span class="p">:</span> <span class="mi">2</span><span class="p">,</span>
      <span class="nt">&quot;26&quot;</span><span class="p">:</span> <span class="mi">2</span><span class="p">,</span>
      <span class="nt">&quot;27&quot;</span><span class="p">:</span> <span class="mi">2</span>
  <span class="p">}</span>
<span class="p">}</span>
</pre></div>
</div>
<p><strong>Platform information (premium feature)</strong>
If the lender is able to view platform information, additional platform data will be available in the response.
This data will indicate what device that applicant used when filling out the questionnaire.</p>
<div class="highlight-http notranslate"><div class="highlight"><pre><span class="kr">HTTP</span><span class="o">/</span><span class="m">1.1</span> <span class="m">200</span> <span class="ne">OK</span>
<span class="na">Content-Type</span><span class="o">:</span> <span class="l">application/json; charset=UTF-8</span>

<span class="p">{</span>
  <span class="nt">&quot;score&quot;</span><span class="p">:</span> <span class="p">{</span>
    <span class="nt">&quot;... output like the request above ...&quot;</span>
  <span class="p">},</span>
  <span class="nt">&quot;platform&quot;</span><span class="p">:</span> <span class="p">{</span>
    <span class="nt">&quot;browser&quot;</span><span class="p">:</span> <span class="p">{</span>
      <span class="nt">&quot;name&quot;</span><span class="p">:</span> <span class="s2">&quot;Chrome&quot;</span><span class="p">,</span>
      <span class="nt">&quot;version&quot;</span><span class="p">:</span> <span class="s2">&quot;70.0.3538.110&quot;</span>
    <span class="p">},</span>
    <span class="nt">&quot;operatingSystem&quot;</span><span class="p">:</span> <span class="p">{</span>
      <span class="nt">&quot;name&quot;</span><span class="p">:</span> <span class="s2">&quot;OS X&quot;</span><span class="p">,</span>
      <span class="nt">&quot;version&quot;</span><span class="p">:</span> <span class="s2">&quot;10.14.1&quot;</span>
    <span class="p">},</span>
    <span class="nt">&quot;device&quot;</span><span class="p">:</span> <span class="p">{</span>
      <span class="nt">&quot;manufacturer&quot;</span><span class="p">:</span> <span class="s2">&quot;Apple&quot;</span><span class="p">,</span>
      <span class="nt">&quot;model&quot;</span><span class="p">:</span> <span class="s2">&quot;Macintosh&quot;</span><span class="p">,</span>
      <span class="nt">&quot;isDesktop&quot;</span><span class="p">:</span> <span class="kc">true</span><span class="p">,</span>
      <span class="nt">&quot;isTablet&quot;</span><span class="p">:</span> <span class="kc">false</span><span class="p">,</span>
      <span class="nt">&quot;isMobile&quot;</span><span class="p">:</span> <span class="kc">false</span>
    <span class="p">},</span>
    <span class="nt">&quot;location&quot;</span><span class="p">:</span> <span class="p">{</span>
      <span class="nt">&quot;country&quot;</span><span class="p">:</span> <span class="s2">&quot;Spain&quot;</span><span class="p">,</span>
      <span class="nt">&quot;countryCode&quot;</span><span class="p">:</span> <span class="s2">&quot;ES&quot;</span><span class="p">,</span>
      <span class="nt">&quot;continent&quot;</span><span class="p">:</span> <span class="s2">&quot;Europe&quot;</span><span class="p">,</span>
      <span class="nt">&quot;continentCode&quot;</span><span class="p">:</span> <span class="s2">&quot;EU&quot;</span><span class="p">,</span>
      <span class="nt">&quot;region&quot;</span><span class="p">:</span> <span class="s2">&quot;Canary Islands&quot;</span><span class="p">,</span>
      <span class="nt">&quot;regionCode&quot;</span><span class="p">:</span> <span class="s2">&quot;CN&quot;</span><span class="p">,</span>
      <span class="nt">&quot;city&quot;</span><span class="p">:</span> <span class="s2">&quot;Las Palmas de Gran Canaria&quot;</span><span class="p">,</span>
      <span class="nt">&quot;zipCode&quot;</span><span class="p">:</span> <span class="s2">&quot;35015&quot;</span>
    <span class="p">}</span>
  <span class="p">}</span>
<span class="p">}</span>
</pre></div>
</div>
<table class="docutils field-list" frame="void" rules="none">
<col class="field-name" />
<col class="field-body" />
<tbody valign="top">
<tr class="field-odd field"><th class="field-name" colspan="2">Request JSON Object:</th></tr>
<tr class="field-odd field"><td>&#160;</td><td class="field-body"><ul class="first simple">
<li><strong>key</strong> (<em>string,required</em>) – The lender’s unique API key. This should be the same in each request.</li>
<li><strong>applicant</strong> (<em>string,required</em>) – A reference the lender uses to uniquely identify a single applicant. If a Lender provides a
non-unique <code class="docutils literal notranslate"><span class="pre">applicant</span></code>, it will be unable to identify the applicant that has filled in the
questionnaire.</li>
<li><strong>version</strong> (<em>string,optional</em>) – The questionnaire version. The version string(s) will be provided.</li>
</ul>
</td>
</tr>
<tr class="field-even field"><th class="field-name" colspan="2">Response JSON Object:</th></tr>
<tr class="field-even field"><td>&#160;</td><td class="field-body"><ul class="first simple">
<li><strong>score</strong> (<em>object</em>) – The PCS score</li>
<li><strong>timing</strong> (<em>object</em>) – The applicant timing information (optional)</li>
<li><strong>platform</strong> (<em>object</em>) – The applicant platform information (optional)</li>
</ul>
</td>
</tr>
<tr class="field-odd field"><th class="field-name">Status Codes:</th><td class="field-body"><ul class="first last simple">
<li><a class="reference external" href="http://www.w3.org/Protocols/rfc2616/rfc2616-sec10.html#sec10.2.1">200 OK</a> – Success</li>
<li><a class="reference external" href="http://www.w3.org/Protocols/rfc2616/rfc2616-sec10.html#sec10.4.5">404 Not Found</a> – The score was not (yet) available. Most likely the applicant hasn’t completed the questionnaire yet.</li>
</ul>
</td>
</tr>
</tbody>
</table>
</dd></dl>

</div>
</div>
</div>
<div class="section" id="customising-the-questionnaire-appearance">
<h1>Customising the questionnaire appearance<a class="headerlink" href="#customising-the-questionnaire-appearance" title="Permalink to this headline">¶</a></h1>
<p>The visual appearance and the textual content of the questionnaire can be customised to your likings.
This chapter describes the current possibilities for these customisations.</p>
<div class="section" id="visual-appearance">
<h2>Visual appearance<a class="headerlink" href="#visual-appearance" title="Permalink to this headline">¶</a></h2>
<p>By default, the questionnaire is displayed in the AdviceRobo default theme. The following parameters can be adjusted
to make the questionnaire better suit the lenders company branding:</p>
<ul class="simple">
<li>Colours<ul>
<li>A primary colour, used for buttons, headers, progress bar, etc.</li>
<li>A secondary colour, used for the main background and as complementary colour for the primary.</li>
<li>A primary text colour</li>
</ul>
</li>
</ul>
<p>These colours will be configured by AdviceRobo when setting up your account. If you want to adjust the colours
to better suit your needs, please contact your AdviceRobo representative.</p>
</div>
<div class="section" id="textual-content">
<h2>Textual content<a class="headerlink" href="#textual-content" title="Permalink to this headline">¶</a></h2>
<p>There are two types of textual content that can be adjusted to your needs.
The first type is the introduction and completed texts that are shown to the applicant when he/she views the questionnaire.
The second is type is the actual content of the questionnaire (questions and answers).</p>
<div class="section" id="applicant-texts">
<h3>Applicant texts<a class="headerlink" href="#applicant-texts" title="Permalink to this headline">¶</a></h3>
<p>The applicant texts (introduction and complete texts) can be fully adjusted to your need. In general, the introduction
text should be used to introduce the applicant to the questionnaire and set the expectations for the coming questionnaire.
For example, it can mention how much time we’re estimating it will take to complete, and that there are no good or bad
answers, since the questions are about figuring out who the applicant is, rather than only testing intelligence.</p>
<p>These texts will be configured by AdviceRobo when setting up your account. If you want to adjust the texts at any
point, please contact your AdviceRobo representative.</p>
</div>
<div class="section" id="questionnaire-texts">
<h3>Questionnaire texts<a class="headerlink" href="#questionnaire-texts" title="Permalink to this headline">¶</a></h3>
<p>AdviceRobo spends a lot of time perfecting the questions and possible answers in the questionnaire.
However, it is still possible to adjust the text of the questions and answers if this better suits your target
audience of applicants.</p>
<p>Additionally, new translations of a single questionnaire can be added. A default set of languages is available
for each questionnaire, but more translations (including locales) can be added.</p>
<p>If you’d like to have some adjustments made to the questionnaire texts or answers, or if you want to have a new
translation made available for you, please contact your AdviceRobo representative.</p>
</div>
</div>
</div>
`;