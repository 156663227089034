import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import PropTypes from "prop-types";
import {Link, useNavigate} from "react-router-dom";
import logo from "image/logo_full.jpeg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {Alert} from "reactstrap";
import {logout, setAuthenticated} from "store/authSlice";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup, CCol,
  CContainer, CForm,
  CFormInput,
  CFormText,
  CInputGroup,
  CInputGroupText, CRow
} from "@coreui/react";
import {UserService} from "services/userService";
import {cilLockLocked, cilUser} from "@coreui/icons";
import CIcon from "@coreui/icons-react";
// import {sendEvent} from "helpers/tracking";

const Login = (props) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alert, setAlert] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logout());
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (username && password) {
      setSubmitted(true);
      setShowAlert(false);
      setAlert(null);
      setLoading(true);

      await UserService.login(username, password, onAuthenticateSuccess, onAuthenticateFail);
    }
  };

  const onAuthenticateSuccess = (response) => {
    dispatch(setAuthenticated(response.data));
    setLoading(false);

    let toPath = "/";
    if (props.location && props.location.state && props.location.state.from) {
      toPath = props.location.state.from;
    }
    navigate(toPath);
  };

  const onAuthenticateFail = (errorMessage) => {
    setLoading(false);
    setShowAlert(true);
    setAlert(errorMessage.response.data.message);
  };

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={8}>
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <CForm name="form" onSubmit={handleSubmit}>
                    <h1>Login</h1>
                    <p className="text-medium-emphasis">Sign In to your account</p>

                    <Alert color="danger" isOpen={showAlert} toggle={() => {
                      setShowAlert(false);
                    }}>
                      <strong>Error:</strong> {alert}
                    </Alert>

                    <CInputGroup className="mb-3">
                      <CInputGroupText>
                        <CIcon icon={cilUser}/>
                      </CInputGroupText>
                      <CFormInput
                        placeholder="Username" type="email" autoComplete="username"
                        className="form-control" name="username" value={username}
                        onChange={(e) => setUsername(e.target.value)}
                      />
                      {submitted && !username &&
                        <CFormText>Username is required</CFormText>
                      }
                    </CInputGroup>
                    <CInputGroup className="mb-4">
                      <CInputGroupText>
                        <CIcon icon={cilLockLocked}/>
                      </CInputGroupText>
                      <CFormInput
                        placeholder="Password" type="password" autoComplete="current-password"
                        className="form-control" name="password" value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      {submitted && !password &&
                        <CFormText>Password is required</CFormText>
                      }
                    </CInputGroup>
                    <CRow>
                      <CCol xs={6}>
                        <CButton color="info" type={"submit"} className="px-4" disabled={loading}>
                          {loading ?
                            <FontAwesomeIcon fixedWidth={true} icon={faSpinner} spin={true} className="ml-2 mr-2"/> :
                            <span>Login</span>
                          }
                        </CButton>
                      </CCol>
                      <CCol xs={6} className="text-right">
                        <p><Link to="/forgot-password" color="link" className="px-0">Forgot password?</Link></p>
                        <p>
                          <Link to="/signup" className="mt-3" active="true" tabIndex={-1}>
                            No account yet? Sign up here.
                          </Link>
                        </p>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
              <CCard className="text-white py-5" style={{width: "44%"}}>
                <CCardBody className="text-center justify-content-center d-flex flex-column">
                  <div className="p-4">
                    <img src={logo} alt="AdviceRobo logo" style={{maxWidth: "100%"}}/>
                  </div>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

Login.propTypes = {
  location: PropTypes.shape({state: PropTypes.shape({from: PropTypes.string})}),
};

export default Login;